import React, { useContext } from 'react'
import ContentDialog from './ContentDialog'
import EditLevelForm from '../Form/EditLevelForm';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core'
import { DataContext } from '../../context/DataContext'
import { observer } from "mobx-react-lite"
import { useTranslation } from 'react-i18next';

const EditDialog = (props) => {
    //const { componentState, setLoading, setError } = useComponentState()
    const { t } = useTranslation()

    const dataStore = useContext(DataContext)


    let result = (
        <>
            <ContentDialog
                actions={
                    <IconButton color="primary" onClick={(() => { dataStore.mobXStore.openEditLevel = (false) })} >
                        <CloseIcon />{('close')}
                    </IconButton>}
                title={t('edit_level')}
                open={dataStore.mobXStore.openEditLevel}
                setOpen={dataStore.mobXStore.setOpenEditLevel}>
                {//dataStore.openEditLevel &&
                    <EditLevelForm
                        onSave={dataStore.mobXStore.onSaveEdit}
                        onCancel={() => dataStore.mobXStore.openEditLevel = (false)}
                        initialValues={dataStore.mobXStore.initialValues}
                        sizeArray={dataStore.mobXStore.sizeArray}
                        parentTaskDate={dataStore.mobXStore.parentTaskDate}
                        helperBaseName={dataStore.mobXStore.helperBaseName}
                        taskType={dataStore.mobXStore.taskType}
                    />}
            </ContentDialog>
        </>
    )

    return result
}

export default observer(EditDialog)