import useApi from '../useApi';

const useCustomerApi = () => {
    const CustomerApi = useApi().CustomerApi

    const getCustomers = () => {
        return CustomerApi.find()
            .then(res => {
                return res
            })
    }

    const createCustomer = (values) => {
        let object = {
            address: "dsa",
            postcode: "dsa",
            city: "dsa",
            companyId: "dsa",
            email: "dsa"
        }
        if (typeof values === 'object' && values !== null) {
            object = values
        } else {
            object.name = values
        }
        return CustomerApi.create(object)
            .then(res => {
                return res
            })

    }

    const editCustomer = (values) => {
        return CustomerApi.updateById(values.uuid, values)
    }

    return {
        getCustomers,
        createCustomer,
        editCustomer,
        useApi: CustomerApi
    }
}

export default useCustomerApi