import React from 'react'
import { Grid } from '@material-ui/core';
import { feedbackFormUrl } from '../../../../Constants'
import { useUserContext } from '../../../../context/UserContext';

const FeedbackFormView = (props) => {
    const { token } = useUserContext()

    let result = (
        <Grid style={{
            margin: 0
        }}>
            <iframe
                style={{
                    display: 'block',       /* iframes are inline by default */
                    border: 'none',         /* Reset default border */
                    height: '100vh',        /* Viewport-relative units */
                    width: '100vw'
                }}
                width="100%"
                height="100%"
                src={feedbackFormUrl + "?access_token=" + token}
            ></iframe>
        </Grid>

    )
    return result
}

export default FeedbackFormView