import React from 'react'
import { Paper, InputLabel, Typography } from '@material-ui/core'
import useMoment from '../../hooks/useMoment';

const CustomInfoField = (props) => {
    const { label, dates } = props;
    const MomentApi = useMoment()


    const dateElements = Object.entries(dates).map((date, i) => {
        return <Typography key={i}>{MomentApi.parseDateToDateTimeFormat(date[0]) + " - " + date[1]}</Typography>
    })


    return (<>
        <InputLabel>{label}</InputLabel>
        <Paper
            elevation={3}
            fullWidth={true}
            variant="outlined"
            style={{ padding: "16px" }}
        >
            {dateElements}
        </Paper>
    </>)
}

export default CustomInfoField
