import React, { useState, useEffect } from 'react'
import DefaultAttachmentsTable from '../../../../components/Table/DefaultAttachmentsTable'
import { useTranslation } from 'react-i18next';
import { useComponentState, ApiError, Spinner } from '@collapick/utils';
import ConfirmDialog from '../../../../components/Dialogs/ConfirmDialog'
import ContentDialog from '../../../../components/Dialogs/ContentDialog'
import DefaultAttachmentsForm from '../../../../components/Form/DefaultAttachmentsForm';
import { Typography, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import useTaskApi from '../../../../hooks/Apis/useTaskApi';

const DefaultAttachments = (props) => {
    const { t } = useTranslation()
    const { componentState, setLoading, setError } = useComponentState()
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [initialValues, setInitialValues] = useState(null)
    const [openAddAttachment, setOpenAddAttachment] = useState(false)
    const [openEditAttachment, setOpenEditAttachment] = useState(false)
    const [defaultAttachments, setDefaultAttachments] = useState([])
    const TaskApi = useTaskApi()

    useEffect(() => {
        getDefaultAttachments()
    }, [])

    const getDefaultAttachments = () => {
        setLoading(true)
        return TaskApi.getDefaultAttachments()
            .then(res => {
                setDefaultAttachments(res)
            })
            .catch(setError)
            .finally(() => {
                setLoading(false)
            })
    }

    const addCallback = () => {
        setOpenAddAttachment(true)
    }

    const editCallback = (e, task) => {
        setInitialValues(task)
        setOpenEditAttachment(true)
    }

    const deleteCallback = (e, task) => {
        setInitialValues(task)
        setConfirmOpen(true)
    }


    const onSaveAdd = (values) => {
        setLoading(true)
        return TaskApi.createDefaultAttachments(values)
            .catch(setError)
            .finally(() => {
                setLoading(false)
                setOpenAddAttachment(false)
                getDefaultAttachments()
            })
    }

    const onSaveEdit = (values) => {
        setLoading(true)
        return TaskApi.useApi.updateById(values.uuid, values)
            .catch(setError)
            .finally(() => {
                setLoading(false)
                setOpenEditAttachment(false)
                getDefaultAttachments()
            })
    }

    const deleteDefaultAttachments = (task) => {
        setLoading(true)
        return TaskApi.useApi.deleteById(task.uuid)
            .catch(setError)
            .finally(() => {
                setLoading(false)
                setConfirmOpen(false)
                getDefaultAttachments()
            })
    }


    let result = null
    if (componentState.loading) {
        result = <Spinner enabled={componentState.loading} />
    } else if (componentState.error) {
        result = <ApiError error={componentState.error} />
    } else {
        result = (
            <>
                <DefaultAttachmentsTable
                    defaultAttachments={defaultAttachments}
                    title={t('default_attachments')}
                    editCallback={editCallback}
                    addCallback={addCallback}
                    deleteCallback={deleteCallback} />

                <ContentDialog
                    actions={
                        <IconButton color="primary" onClick={(() => { setOpenAddAttachment(false) })} >
                            <CloseIcon />{t('close')}
                        </IconButton>}
                    title={t('add_default_attachment')}
                    open={openAddAttachment}
                    setOpen={setOpenAddAttachment}>
                    {openAddAttachment &&
                        <DefaultAttachmentsForm
                            onSave={onSaveAdd}
                            onCancel={() => setOpenAddAttachment(false)}
                            title={<Typography variant="h2">{t('add_new_default_attachment')}</Typography>}
                        />}
                </ContentDialog>
                <ContentDialog
                    actions={
                        <IconButton color="primary" onClick={(() => { setOpenEditAttachment(false) })} >
                            <CloseIcon />{t('close')}
                        </IconButton>}
                    title={t('edit_attachment')}
                    open={openEditAttachment}
                    setOpen={setOpenEditAttachment}>
                    {openEditAttachment &&
                        <DefaultAttachmentsForm
                            onSave={onSaveEdit}
                            onCancel={() => setOpenEditAttachment(false)}
                            initialValues={initialValues}
                            title={<Typography variant="h2">{t('edit_default_attachment')}</Typography>}

                        />}
                </ContentDialog>
                <ConfirmDialog
                    open={confirmOpen}
                    setOpen={setConfirmOpen}
                    title={t('remove_default_attachments')}
                    content={initialValues?.name}
                    agreeCallback={() => {
                        setConfirmOpen(false);
                        deleteDefaultAttachments(initialValues);
                        setInitialValues(false)
                    }}
                    disagreeCallback={() => {
                        setConfirmOpen(false);
                        setInitialValues(false)
                    }} />
            </>
        )
    }
    return result
}

export default DefaultAttachments