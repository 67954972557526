import React, { Suspense } from "react"
import { CssBaseline } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"
import { SnackbarProvider } from "notistack"
import { HashRouter } from "react-router-dom"
import { theme } from "../hooks/theme/useAppTheme"
import useAppRoutes from "../hooks/useAppRoutes.js"
import { ErrorBoundary } from "@collapick/utils"
import { AppProvider } from "../context/AppContext"
import { UserProvider } from "../context/UserContext"
import useRoutes from "../hooks/useRoutes"
import { DataContextProvider } from '../context/DataContext'

const useStyles = makeStyles((t) => ({
    info: { /*backgroundColor: theme.palette.primary.main*/ }
}))

/**
 * Wrap with providers
 *
 * NOTICE: CANNOT USE BROWSER ROUTE IN STORYBOOK!
 * @param {*} param0
 */
const Providers = (props) => {
    const classes = useStyles()
    console.log("RENDERING PROVIDERS")
    return (
        <AppProvider {...props}>
            <UserProvider {...props}>
                <ThemeProvider theme={theme}>
                    <DataContextProvider>
                        <HashRouter>
                            <SnackbarProvider
                                maxSnack={3}
                                classes={{
                                    variantInfo: classes.info
                                }}
                            >
                                <>{props.children}</>
                            </SnackbarProvider>
                        </HashRouter>
                    </DataContextProvider>
                </ThemeProvider>
            </UserProvider>
        </AppProvider>
    )
}

const App = (props) => {
    // You may pass token via props for example.
    console.info("Rendering APP", props)
    const { routes } = useRoutes()
    const { RenderRoutes } = useAppRoutes()

    return (
        <Suspense fallback="Loading translations...">
            <ErrorBoundary>
                <Providers {...props}>
                    <CssBaseline />
                    <RenderRoutes routes={routes} />
                </Providers>
            </ErrorBoundary>
        </Suspense>
    )
}

export default App
