import React from "react"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { Chip, Container } from "@material-ui/core"
import MaterialTableIcons from '../MaterialTable/MaterialTabelIcons'
import useMaterialTableOptions from "../MaterialTable/useMaterialTableOptions"
import { theme, COLOR } from "../../hooks/theme/useAppTheme"
import { DataTable } from '../MaterialTable/DataTable';
import useMoment from '../../hooks/useMoment'
import { URGENT_DAYS, SALE, PRODUCTION, SERVICE } from '../../Constants'
import CountUsedDuration from './CountUsedDuration';
import WorkHoursTableDetailPanelCommonLevel from './WorkHoursTableDetailPanelCommonLevel';

const WorkHoursTableDetailPanel = (props) => {
    const { data } = props
    const { t } = useTranslation()
    const MomentApi = useMoment()

    const baseOptions = useMaterialTableOptions()
    const options = {
        ...baseOptions,
        filtering: false,
        sorting: false,
        search: false,
        pageSize: 25,
        columnsButton: false,
        paging: false,
        toolbar: false,
        header: true,
        rowStyle: {
            backgroundColor: COLOR.LIGHT_2,
        },
        headerStyle: {
            backgroundColor: COLOR.LIGHT_2,
            textAlign: "center"
        }
    }

    const defaultColumns = [
        { title: t("name"), field: "name" },
        {
            title: t("dueToDate"), field: "dueToDate", render: (rowData) => {
                return moment(rowData.dueToDate).format("DD.MM.YYYY")
            }
        },
        { title: t('estimatedDuration') + "(h)", field: "estimatedDuration" },
        { title: t('duration') + "(h)", field: "duration", sorting: false },
        {
            title: t('complited'), field: "complited", render: (rowData) => {
                let count = 0
                let totalCount = 0
                rowData.childTasks.forEach(task => {
                    totalCount++
                    count += task.done
                })
                let mainColor = null
                let textColor = null
                if (count == totalCount) {
                    mainColor = theme.palette.success.dark
                    textColor = theme.palette.info.contrastText
                } else {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (count == 0) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }

                return <Chip
                    style={{ backgroundColor: mainColor, color: textColor }}
                    label={count + "/" + totalCount} />

            }
        },
    ]

    let columns = defaultColumns
    let overrideColumns = [...columns];
    overrideColumns.forEach(c => {
        if (c.field == 'name') {
            c.render = (rowData) => {
                let color = null
                let textColor = null
                if (rowData?.helperBaseName == PRODUCTION) {
                    color = theme.palette.primary.light
                    textColor = theme.palette.getContrastText(theme.palette.primary.light)
                } else if (rowData?.helperBaseName == SALE) {
                    color = theme.palette.success.light
                    textColor = theme.palette.getContrastText(theme.palette.success.light)
                } else if (rowData?.helperBaseName == SERVICE) {
                    color = theme.palette.secondary.light
                    textColor = theme.palette.getContrastText(theme.palette.secondary.light)
                } else {
                    color = theme.palette.primary.light
                    textColor = theme.palette.getContrastText(theme.palette.primary.light)
                }
                return <Chip
                    style={{ backgroundColor: color, color: textColor }}
                    label={rowData.name} />
            }
        }
        else if (c.field == 'dueToDate') {
            c.render = (rowData) => {
                let mainColor = theme.palette.info.main
                let textColor = theme.palette.info.contrastText
                let date = ""
                let oneDay = (24 * 60 * 60 * 1000)

                if (!rowData?.dueToDate) {
                    mainColor = COLOR.LIGHT_2
                    textColor = theme.palette.getContrastText(COLOR.LIGHT_2)
                }
                if (rowData?.dueToDate && /*!MomentApi.isDateAfterNow(rowData.dueToDate))*/
                    moment(rowData?.dueToDate).valueOf() < (moment() + (URGENT_DAYS * oneDay))) {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (rowData?.dueToDate && !MomentApi.isDateAfterNow(rowData.dueToDate)) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }
                if (rowData?.done && rowData?.dueToDate) {
                    mainColor = theme.palette.success.dark
                    textColor = theme.palette.info.contrastText
                }

                if (rowData?.dueToDate != null) {
                    date = moment(rowData?.dueToDate).format("DD.MM.YYYY")
                } else {
                    date = t('no_dueToDate_set')
                }
                return <Chip style={{ backgroundColor: mainColor, color: textColor }} label={date} />
            }
        } else if (c.field == 'duration') {
            c.render = (rowData) => {
                return <CountUsedDuration task={rowData} />
            }
        }
    })


    let actions = [

    ]

    let detailPanel = [
        {
            tooltip: t('show_details'),
            render: (rowData) => {
                let result = null
                result = (
                    <WorkHoursTableDetailPanelCommonLevel
                        data={rowData.childTasks}
                    />
                )
                return result
            }
        }
    ]


    let result = (
        <Container maxWidth="xl" style={{ marginTop: 10 }}>
            <DataTable
                options={options}
                columns={overrideColumns}
                data={data}
                icons={MaterialTableIcons}
                actions={actions}
                detailPanel={detailPanel}
            />
        </Container>
    )
    return result
}
export default WorkHoursTableDetailPanel