import React, { useContext, useEffect, useState } from 'react'
import ContentDialog from './ContentDialog'
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core'
import { DataContext } from '../../context/DataContext'
import { useTranslation } from 'react-i18next';
import { observer } from "mobx-react-lite"
import PonnisteIframe from '../ProductionForms/PonnisteIframe';


const ShowAnswersDialog = (props) => {
    const dataStore = useContext(DataContext)
    const { t } = useTranslation()


    let result = (
        <ContentDialog
            actions={
                <IconButton color="primary" onClick={(() => { dataStore.mobXStore.setOpenShowAnswers(false) })} >
                    <CloseIcon />{t('close')}
                </IconButton>}
            title={t('show_answers')}
            open={dataStore.mobXStore.openShowAnswers}
            setOpen={dataStore.mobXStore.setOpenShowAnswers}>
            {
                <>
                    <PonnisteIframe
                        formAnswerContainerId={dataStore.mobXStore.initialValues.formAnswerContainerId}
                    />
                </>}
        </ContentDialog>
    )
    return result
}

export default observer(ShowAnswersDialog)