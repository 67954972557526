import { useTranslation } from 'react-i18next';
import { TaskTypes } from '@collapick/ponniste-types';
import moment from 'moment'
import { SERVICE } from '../Constants';

const useEditLevelSchema = (props) => {
    const { initialValues, sizeArray, parentTaskDate, helperBaseName, taskType } = props
    const { t } = useTranslation();

    let editLevelSchema = {
        id: "editLevelSchema",
        elements: [
            {
                type: "grid",
                direction: 'row',
                spacing: 2,
                elements: [
                    {

                        type: "date",
                        name: 'dueToDate',
                        label: t('due_to_date'),
                        item: {
                            xs: 12,
                        },
                        elementProps: {
                            inputVariant: "outlined"
                        },
                        validators: [
                            {
                                name: "date_validator",
                                config: {
                                    msg: t("date_cannot_be_later_than_bottom_level")
                                }
                            }
                        ],
                    },
                    {

                        type: "number",
                        name: 'estimatedDuration',
                        label: t('estimatedDuration') + (" (h)"),
                        item: {
                            xs: 12
                        },
                    },
                    {
                        type: "text",
                        name: "comment",
                        label: t('comment'),
                        item: {
                            xs: 12
                        }
                    },
                    {
                        type: 'grid'
                    }
                ]
            }

        ]
    }

    if (initialValues.taskType == TaskTypes.task()) {
        editLevelSchema.elements[0].elements.unshift(
            {
                type: "text",
                name: 'name',
                label: t('name'),
                item: {
                    xs: 12
                },
                validators: [
                    {
                        name: 'required',
                        config: {
                            msg: t('name_is_required')
                        }
                    },
                ]
            },
            {

                type: "select",
                name: 'unit',
                label: t('project_size'),
                item: {
                    xs: 12
                },
                options:
                    sizeArray.map(size => {
                        return { value: size, label: size }
                    })

            },
            {
                type: "number",
                name: 'taskObject.size',
                label: t('m2'),
                item: {
                    xs: 12
                }
            },
            {
                type: "number",
                name: 'taskObject.population',
                label: t('population'),
                item: {
                    xs: 12
                }
            },
            {
                type: "text",
                name: 'taskObject.heatingSystem',
                label: t('heating_system'),
                item: {
                    xs: 12
                }
            },
            {
                type: "number",
                name: 'taskObject.anotherSize',
                label: t('another_m2'),
                item: {
                    xs: 12
                }
            },
        )
    }
    if (taskType == TaskTypes.todo()) {
        let object = {
            type: "number",
            name: 'duration',
            label: t('duration') + (" (h)"),
            item: {
                xs: 12
            },
        }
        editLevelSchema.elements[0].elements.splice(2, 0, object)
    }

    const customValidators = {
        "date_validator": {
            defaultMessage: "Not accepted",
            validator: (options, value, allValues) => {

                let isValid = false
                if (!value || parentTaskDate == TaskTypes.task() || helperBaseName == SERVICE ||
                    (value && parentTaskDate && moment(value).startOf("day") <= moment(parentTaskDate).startOf("day"))) {
                    isValid = true
                }
                return isValid
            }
        }
    }

    return {
        editLevelSchema,
        customValidators
    }
}

useEditLevelSchema.defaultProps = {
    parentTaskDate: "2021-02-08 13:32:23"
}

export default useEditLevelSchema;
