import React from 'react'
import { CollapickForm } from '@collapick/form';
import { Typography, Grid, Box, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import useSendEmailSchema from '../../FormSchemas/useSendEmailSchema';

const SendEmailForm = (props) => {
    const { title, onSave, onCancel, users, message, receiver } = props
    const { t } = useTranslation()
    const { emailSchema } = useSendEmailSchema({ users });

    const initialValues = { message: message, receiver: receiver }

    return (
        <>
            <Grid container justify="center" >
                <Box my={2} >
                    <Typography variant="h2">{title}</Typography>
                </Box>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <CollapickForm
                        initialValues={initialValues}
                        formSchema={emailSchema}
                        onFormSubmit={onSave}
                        resetFormAfterSubmit={true}
                        renderSubmitButton={() =>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                fullWidth>
                                {t('submit')}
                            </Button>}
                    /*showInvalidSaveButton={true}
                    invalidSubmitText={t("cancel")}
                    onInvalidSubmit={onCancel}*/
                    />
                </Grid>
            </Grid>
        </>
    )
}

SendEmailForm.defaultProps = {
    title: "",
    onSave: console.log,
    onCancel: console.log
}

export default SendEmailForm