import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const COLOR = {
    PRIMARY: "#041d6c",
    PRIMARY_TONE_1: "#7E19FF",
    PRIMARY_TONE_2: "#8C32FF",
    PRIMARY_TONE_3: "#A965FF",
    PRIMARY_TONE_4: "#C598FF",
    DARK_1: "#23282F",
    DARK_2: "#3F4754",
    DARK_3: "#657185",
    LIGHT_1: "#FAF9FC",
    LIGHT_2: "#EAEBF3",
    WHITE: "#FFFFFF",
    GREEN: "#33B979",
    YELLOW: "#F9D864",
    RED: "#E83333",
    BACKGROUND: "#FAF9FC",
    mobileProgress: "#18ffff",
    scheduleBar: "#18ffff",
    ValidButton: "#81c784",
    InValidButton: "#ffb74d"
}

let theme = createMuiTheme({
    overrides: {
        // Style sheet name ⚛️
        MuiButton: {
            // Name of the rule
            // root: {
            //     fontFamily: FONT.Poppins,
            // },
            contained: {
                // Some CSS

                fontSize: 12,
                //lineHeight: 2,
                borderRadius: 50,
                fontWeight: 700,
                paddingRight: 20,
                paddingLeft: 20
            },
            containedPrimary: {
                background: COLOR.ValidButton + '!important',
            },
            containedSecondary: {
                background: COLOR.InValidButton + '!important',
            },

        },
    },
    typography: {
        /* h1: {
             fontSize: 48,
             fontWeight: 700,
             color: COLOR.DARK_1,
         },
         h2: {
             fontSize: 35,
             fontWeight: 700,
             color: COLOR.DARK_1,
         },
         h3: {
             fontSize: 20,
             fontWeight: 500,
             color: COLOR.DARK_1,
             letterSpacing: "0.002em"
         },
         h4: {
             fontSize: 15,
             fontWeight: 500,
             color: COLOR.DARK_1,
         },
         h5: {
             fontSize: 25,
             fontWeight: 800,
             color: COLOR.DARK_1,
         },
         body1: {
             fontSize: 16,
             lineHeight: "170%"
         }*/
    },
    palette: {
        // type: 'light',
        background: {
            default: COLOR.WHITE
        },
        primary: {
            main: "#1289A7",
            light: "#12CBC4",
            dark: "#0e2050",
            contrastText: "#ffffff"
        },
        secondary: {
            main: "#6F1E51",
            light: "#B53471",
            dark: "#560099",
            contrastText: "#000000"
        },
        success: {
            main: "rgb(0 82 48)",
            light: "#009464",
            dark: "#4caf50",
            contrastText: "#000000"
        },
        error: {
            main: "#f44336",
            light: "#e57373",
            dark: "#d32f2f",
            contrastText: "#fff"
        },
        info: {
            main: "#0652DD",
            light: "#64b5f6",
            dark: "#1976d2",
            contrastText: "#fff"
        }
    },
})

theme = responsiveFontSizes(theme)


const SPACER = {
    height: 88
}

const useAppTheme = () => {
    const setBackgroundColor = color => {
        document.body.style = 'background: ' + color + ";";
    }

    return {
        theme, COLOR, SPACER, setBackgroundColor
    }
}

export { theme, SPACER, COLOR, useAppTheme }