import React, { useState, useEffect } from 'react'
import { Spinner, ApiError, LocalStorageHelper } from '@collapick/utils';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@material-ui/core';
import { useUserContext } from '../../context/UserContext';

const ShowThumbnails = (props) => {
    const { files } = props
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const { t } = useTranslation();
    const { token } = useUserContext()

    const openFile = (file) => {
        let fileName = file.relativeUrl.split("/download/").pop()
        let url = window.BASEURL + `api/storage/${file.container}/download/${fileName}`
        window.open(url + `?accessToken=${token}`)
    }

    const showThumbnail = (file, type) => {
        let fileName = file.relativeUrl.split("/download/").pop()
        let fileType = null
        if (type == "image/jpeg") {
            fileType = "jpeg"
        } else if (type == "image/png") {
            fileType = "png"
        }
        /* let name = file.name
         if (file.name.length > 15) {
             name = file.name.substring(0, 10) + '...';
             let ending = file.name.split(".").pop();
             name = name + ending
         }*/

        if (fileType) {
            return (
                <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Button onClick={() => openFile(file)}>
                        <img
                            src={window.BASEURL + `api/storage/${file.container}/thumbnail/${fileName}?width=200`}
                            style={{ width: "25%", heigth: "25%", maxHeight: "100px", maxWidth: "100px" }} />
                        <p>{file.name}</p>
                    </Button>
                </Grid>
            )
        } else {
            return (
                <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Button onClick={() => openFile(file)}>
                        <img
                            src={window.BASEURL + "/pdf.png"}
                            style={{ width: "25%", heigth: "25%", maxHeight: "50px", maxWidth: "50px" }} />
                        <p>{file.name}</p>
                    </Button>
                </Grid>
            )
        }
    }

    let result = null;
    if (loading) {
        result = <Spinner enabled={loading} />
    } else if (error) {
        result = <ApiError error={error} />
    } else {
        result = files.map((file, i) => {
            return showThumbnail(file, file.type)
        })
    } return (
        <>
            {result}
        </>
    )

}

export default ShowThumbnails