import React, { useContext, useState, useEffect } from "react"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { Container, Chip } from "@material-ui/core"
import MaterialTableIcons from '../MaterialTable/MaterialTabelIcons'
import useMaterialTableOptions from "../MaterialTable/useMaterialTableOptions"
import { theme, COLOR } from "../../hooks/theme/useAppTheme"
import { DataTable } from '../MaterialTable/DataTable';
import useMoment from '../../hooks/useMoment'
import { URGENT_DAYS, SALE, PRODUCTION, SERVICE } from '../../Constants'
import CountUsedDuration from './CountUsedDuration';
import DashboardProjectTableDetailPanel from './DashboardProjectTableDetailPanel'
import EditIcon from '@material-ui/icons/Edit';
import HistoryIcon from '@material-ui/icons/History';
import { DataContext } from '../../context/DataContext'
import useTaskApi from '../../hooks/Apis/useTaskApi';
import { observer } from "mobx-react-lite"
import { ApiError } from '@collapick/utils';

const DashboardProjectTable = (props) => {
    const { taskId, backToProjects } = props
    const { t } = useTranslation()
    const MomentApi = useMoment()
    const dataStore = useContext(DataContext)
    const TaskApi = useTaskApi()
    const [data, setData] = useState([])


    const baseOptions = useMaterialTableOptions()
    const options = {
        ...baseOptions,
        filtering: false,
        sorting: false,
        search: false,
        pageSize: 25,
        columnsButton: false
    }

    const defaultColumns = [
        { title: t("projects"), field: "name" },
        { title: t("size"), field: "unit" },
        {
            title: t("m2"), field: "taskObject.size", filtering: true, render: (rowData) => {
                if (rowData?.taskObject?.size) {
                    return rowData.taskObject.size + "m2"
                }
            }
        },
        { title: t("population"), field: "taskObject.population", filtering: true },
        { title: t("heating_system"), field: "taskObject.heatingSystem", filtering: true },
        {
            title: t("another_size"), field: "taskObject.anotherSize", filtering: true, render: (rowData) => {
                if (rowData?.taskObject?.size) {
                    return rowData.taskObject.size + "m2"
                }
            }
        },
        {
            title: t("dueToDate"), field: "dueToDate", render: rowData => {
                let mainColor = theme.palette.info.main
                let textColor = theme.palette.info.contrastText
                let date = ""
                let oneDay = (24 * 60 * 60 * 1000)

                if (!rowData?.dueToDate) {
                    mainColor = COLOR.LIGHT_2
                    textColor = theme.palette.getContrastText(COLOR.LIGHT_2)
                }
                if (rowData?.dueToDate &&
                    moment(rowData?.dueToDate).valueOf() < (moment() + (URGENT_DAYS * oneDay))) {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (rowData?.dueToDate && !MomentApi.isDateAfterNow(rowData.dueToDate)) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }

                if (rowData?.done && rowData?.dueToDate) {
                    mainColor = theme.palette.success.dark
                    textColor = theme.palette.info.contrastText
                }

                if (rowData?.dueToDate != null) {
                    date = moment(rowData?.dueToDate).format("DD.MM.YYYY")
                } else {
                    date = t('no_dueToDate_set')
                }
                return <Chip style={{ backgroundColor: mainColor, color: textColor }} label={date} />
            }
        },
        { title: t('customer'), field: "user" },
        { title: t('comment'), field: "comment" },
        { title: t('estimatedDuration') + "(h)", field: "estimatedDuration" },
        {
            title: t('duration') + "(h)", field: "duration", render: (rowData) => {
                return <CountUsedDuration task={rowData} />
            }
        },
        {
            title: t('sale'), field: "sale", render: (rowData) => {
                let mainColor = null
                let textColor = null
                if (rowData?.sale?.count == rowData?.sale?.totalCount) {
                    mainColor = theme.palette.success.dark
                    textColor = theme.palette.info.contrastText
                } else {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (rowData?.sale?.count == 0) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }
                if (rowData?.sale) {
                    return <Chip
                        style={{ backgroundColor: mainColor, color: textColor }}
                        label={rowData.sale.count + "/" + rowData.sale.totalCount} />
                }
            }
        },
        {
            title: t('production'), field: "production", render: (rowData) => {
                let mainColor = null
                let textColor = null
                if (rowData?.production?.count == rowData?.production?.totalCount) {
                    mainColor = theme.palette.success.dark
                    textColor = theme.palette.info.contrastText
                } else {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (rowData?.production?.count == 0) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }
                if (rowData?.production) {
                    return <Chip
                        style={{ backgroundColor: mainColor, color: textColor }}
                        label={rowData.production.count + "/" + rowData.production.totalCount} />
                }
            }
        },
        {
            title: t('service'), field: "service", render: (rowData) => {
                let mainColor = null
                let textColor = null
                if (rowData?.service?.count == rowData?.service?.totalCount) {
                    mainColor = theme.palette.success.dark
                    textColor = theme.palette.info.contrastText
                } else {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (rowData?.service?.count == 0) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }
                if (rowData?.service) {
                    return <Chip
                        style={{ backgroundColor: mainColor, color: textColor }}
                        label={rowData.service.count + "/" + rowData.service.totalCount} />
                }
            }
        }
    ]

    let columns = defaultColumns

    let actions = [
        {
            icon: () => {
                return < EditIcon style={{ color: COLOR.GREEN }} />
            },
            tooltip: t('edit_project'),
            onClick: dataStore.mobXStore.editLevel
        },
        {
            icon: () => {
                return < HistoryIcon style={{ color: COLOR.GREEN }} />
            },
            tooltip: t('back_to_projects'),
            onClick: backToProjects,
            isFreeAction: true
        },
    ]

    let detailPanel = [
        {
            tooltip: t('show_details'),
            render: (rowData) => {
                let result = null
                result = (
                    <DashboardProjectTableDetailPanel
                        id={rowData.uuid}
                    />
                )
                return result
            }
        }
    ]

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        dataStore.setLoading(true)
        return TaskApi.getTask(taskId)
            .then(res => {
                let calculatedResult = calcResult(res)
                setData(calculatedResult)
            })
            .catch(dataStore.setError)
            .finally(() => {
                dataStore.setLoading(false)
            })
    }


    const calcResult = (array) => {
        array.forEach(task => {
            task.sale = {
                count: 0,
                totalCount: 0
            }
            task.production = {
                count: 0,
                totalCount: 0
            }
            task.service = {
                count: 0,
                totalCount: 0
            }
            task.childTasks.forEach(childTasks => {
                childTasks.childTasks.forEach(innerChild => {
                    if (innerChild.helperBaseName == SALE) {
                        task.sale.totalCount++
                        task.sale.count += innerChild.done
                    } else if (innerChild.helperBaseName == PRODUCTION) {
                        task.production.totalCount++
                        task.production.count += innerChild.done
                    } else if (innerChild.helperBaseName == SERVICE) {
                        task.service.totalCount++
                        task.service.count += innerChild.done
                    }
                })
            })
        })
        return array
    }

    let result = null
    if (dataStore.componentState.error) {
        result = <ApiError error={dataStore.componentState.error} />
    } else {
        result = (
            <Container maxWidth="xl" style={{ marginTop: 10 }}>
                <DataTable
                    isLoading={dataStore?.componentState?.loading}
                    options={options}
                    title={t("setup_for_new_project")}
                    columns={columns}
                    data={data}
                    icons={MaterialTableIcons}
                    actions={actions}
                    detailPanel={detailPanel}
                    update={dataStore.mobXStore.updateParent}
                />
            </Container>
        )
    }
    return result
}
export default observer(DashboardProjectTable)
