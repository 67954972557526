import React, { useState } from 'react'
import useTaskApi from '../../../../hooks/Apis/useTaskApi'
import { useComponentState, ApiError, Spinner } from '@collapick/utils';
import ProjectsMainViewSelectionHeader from '../../../../components/Header/ProjectsMainViewSelectionHeader';
import MobileProjectsTable from '../../../../components/Table/MobileProjectsTable';
import { useHistory } from 'react-router-dom';
import { URGENTS, PROJECTS, SERVICE, SALE, PRODUCTION, WORKS } from '../../../../Constants';
import UrgentsSelection from '../../../../components/Urgents/UrgentsSelection';
import Axios from 'axios'
import SendEmailForm from '../../../../components/Form/SendEmailForm'
import ContentDialog from '../../../../components/Dialogs/ContentDialog';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, IconButton } from '@material-ui/core'
import useAppUserApi from '../../../../hooks/Apis/useAppUserApi';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../../../context/UserContext';
import useDynamicDataApi from '../../../../hooks/Apis/useDynamicDataApi';
import MobileWorksTable from '../../../../components/Table/MobileWorksTable';

const ProjectsMainView = (props) => {
    const TaskApi = useTaskApi()
    const { componentState, setLoading, setError } = useComponentState()
    const history = useHistory()
    const [view, setView] = useState("projects")
    const [openSendEmail, setOpenSendEmail] = useState(false)
    const [projectInfo, setProjectInfo] = useState(null)
    const AppUserApi = useAppUserApi()
    const [users, setUsers] = useState(null)
    const { t } = useTranslation()
    const { user, setUser } = useUserContext()
    const [message, setMessage] = useState(null)
    const [receiver, setReceiver] = useState(null)
    const DynamicDataApi = useDynamicDataApi()
    const [type, setType] = useState(null)

    const ajax = (params, columns, showComplited) => {
        return TaskApi.getAllTasks(params, columns, showComplited)
            .then(res => {
                return (
                    {
                        totalCount: res.count,
                        page: params.page,
                        data: res
                    }
                )
            })
    }

    const ajaxWorks = (params, columns, showComplited) => {
        return TaskApi.getAllWorks(params, columns, showComplited)
            .then(res => {
                return (
                    {
                        totalCount: res.count,
                        page: params.page,
                        data: res
                    }
                )
            })
    }

    const moveToProject = (e, rowData) => {
        history.push('/mobile/projects/view/' + [rowData.uuid])
    }

    const moveToWork = (e, rowData) => {
        history.push('/mobile/works/todoview/' + [rowData.uuid])
    }

    const sendEmail = (values) => {
        let url = window.BASEURL + "kytkin-api/sendEmail/"
        setLoading(true)
        return Axios.post(url, { values: values, user: user, projectInfo: projectInfo })
            .then(() => {
                if (values.message != message || values.receiver != receiver) {
                    return DynamicDataApi.setMessage(values.message, values.receiver, type)
                }
            })
            .catch(setError)
            .finally(() => {
                setOpenSendEmail(false)
                setLoading(false)
            })
    }

    const sendEmailCallback = (event, rowData) => {
        setLoading(true)
        setProjectInfo(rowData)
        return AppUserApi.getUsersForEmail()
            .then(res => {
                setUsers(res)
                let type = null
                if (rowData.helperBaseName == SALE) {
                    type = SALE
                } else if (rowData.helperBaseName == SERVICE) {
                    type = SERVICE
                } else if (rowData.helperBaseName == PRODUCTION) {
                    type = PRODUCTION
                }
                setType(type)
                return DynamicDataApi.getMessage(type)
            })
            .then(res => {
                setMessage(res?.message)
                setReceiver(res?.receiver)
            })
            .catch(setError)
            .finally(() => {
                setOpenSendEmail(true)
                setLoading(false)
            })
    }

    let result = null
    if (componentState.loading) {
        result = <Spinner enabled={componentState.loading} />
    } else if (componentState.error) {
        result = <ApiError error={componentState.error} />
    } else {
        result = (
            <>
                <ProjectsMainViewSelectionHeader setView={setView} />
                {view == WORKS && <MobileWorksTable ajaxWorks={ajaxWorks} moveToProject={moveToWork} />}
                {view == PROJECTS && <MobileProjectsTable ajax={ajax} moveToProject={moveToProject} />}
                {view == URGENTS && <UrgentsSelection sendEmail={sendEmailCallback} />}
                <ContentDialog
                    actions={
                        <IconButton color="primary" onClick={(() => { setOpenSendEmail(false) })} >
                            <CloseIcon />{t('close')}
                        </IconButton>}
                    title={t('add_customer')}
                    open={openSendEmail}
                    setOpen={setOpenSendEmail}>
                    {openSendEmail && users &&
                        <SendEmailForm
                            users={users}
                            message={message}
                            receiver={receiver}
                            onSave={sendEmail}
                            onCancel={() => setOpenSendEmail(false)}
                            title={<Typography variant="h2">{t('send_email')}</Typography>}
                        />}
                </ContentDialog>
            </>
        )
    }
    return result
}

export default ProjectsMainView