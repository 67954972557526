import React, { useState, useEffect } from 'react'
import useTaskApi from '../../../../hooks/Apis/useTaskApi'
import { useComponentState, ApiError, Spinner } from '@collapick/utils';
import { useHistory, useLocation } from 'react-router-dom';
import MobileProgress from '../../../../components/Progress/MobileProgress';
import ScheduleBar from '../../../../components/Schedule/ScheduleBar'
import LevelNavigation from '../../../../components/LevelNavigation/LevelNavigation'
import FilesAndManuals from '../../../../components/FilesAndManuals/FilesAndManuals'
import { useUserContext } from '../../../../context/UserContext'
import CustomerInfo from '../../../../components/CustomerInfo/CustomerInfo';
import CustomerInfoDialog from '../../../../components/Dialogs/CustomerInfoDialog';
import ContentDialog from '../../../../components/Dialogs/ContentDialog';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import useItemApi from '../../../../hooks/Apis/useItemApi';

const ProjectsTodoView = (props) => {
    const { taskId } = props
    const TaskApi = useTaskApi()
    const { componentState, setLoading, setError } = useComponentState()
    const history = useHistory()
    const [task, setTask] = useState(null)
    const [count, setCount] = useState({})
    const location = useLocation()
    const { token } = useUserContext()
    const [openCustomerInfo, setOpenCustomerInfo] = useState(null)
    const { t } = useTranslation()
    const ItemApi = useItemApi()

    useEffect(() => {
        getTask()
    }, [location])

    const getTask = () => {
        setLoading(true)
        return TaskApi.getTask(taskId)
            .then(res => {
                setTask(res[0])
                getDoneTasks(res[0])
            })
            .catch(setError)
            .finally(() => { setLoading(false) })
    }

    const getDoneTasks = (task) => {
        setLoading(true)
        return TaskApi.getDoneTasks(task)
            .then(res => {
                setCount(res)
            })
            .catch(setError)
            .finally(setLoading(false))
    }

    const openSchedule = () => {
        setLoading(true)
        return TaskApi.getParentTask(task.parentTaskId)
            .then(res => {
                history.push('/mobile/schedule/' + [res[0].parentTaskId])
            })
            .catch(setError)
            .finally(setLoading(true))
    }

    const moveToLevel = (selectedTask) => {
        history.push('/mobile/answerform/' + [selectedTask.uuid])
    }

    const addFile = () => {
        history.push('/mobile/uploadform/' + [task.uuid])
    }

    const openFile = (file) => {
        let fileName = file.relativeUrl.split("/download/").pop()
        let url = window.BASEURL + `api/storage/${file.container}/download/${fileName}`
        window.open(url + `?accessToken=${token}`)
    }

    const setQuantity = (field) => {
        let taskToUpdate = { ...task }
        if (taskToUpdate[field] == null) {
            taskToUpdate[field] = 0
        }
        taskToUpdate[field] = parseInt(taskToUpdate[field]) + 1
        return TaskApi.useApi.updateById(task.uuid, taskToUpdate)
            .then((res) => {
                let object = {
                    name: field,
                    count: 1,
                    taskId: task.uuid
                }
                return ItemApi.useApi.create(object)
            })
            .then(res => {
                return TaskApi.getTask(taskId)
            })
            .then(res => {
                setTask(res[0])
                getDoneTasks(res[0])
            })
            .catch(setError)
    }

    const confirmSafety = (task, uuid) => {
        setLoading(true)
        let description = task?.description
        try {
            description = JSON.parse(description)
        } catch (err) {
            console.log(err)
        }
        if (Array.isArray(description)) {
            description.push(uuid)
        } else {
            description = [uuid]
        }
        return TaskApi.useApi.updateById(task.uuid, { description: JSON.stringify(description) })
            .catch(setError)
            .finally(() => {
                setLoading(false)
                getTask()
            })
    }


    let result = null
    if (componentState.loading) {
        result = <Spinner enabled={componentState.loading} />
    } else if (componentState.error) {
        result = <ApiError error={componentState.error} />
    } else {
        result = (
            <>
                {task && count && <MobileProgress count={count} task={task} />}
                <ScheduleBar openSchedule={openSchedule} />
                {task && <LevelNavigation task={task} moveToLevel={moveToLevel} setQuantity={setQuantity} confirmSafety={confirmSafety}/>}
                {task && <FilesAndManuals task={task} addFile={addFile} openFile={openFile} />}
            </>
        )
    }
    return result
}

ProjectsTodoView.defaultProps = {
    taskId: "e3350940-5b05-11eb-837a-83948b54c92f"
}

export default ProjectsTodoView
