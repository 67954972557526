import useApi from '../useApi';
import { MAIN, VIDEO, MESSAGE, PRODUCTION_FORMS } from '../../Constants';

const useDynamicDataApi = () => {
    const DynamicDataApi = useApi().DynamicDataApi

    const getSizes = () => {
        let query = {
            where: {
                key: MAIN
            }
        }
        return DynamicDataApi.find({ filter: JSON.stringify(query) })
            .then(res => {
                return res[0].arrayValue
            })
    }

    const updateSize = (array) => {
        let query = {
            where: {
                key: MAIN
            }
        }
        return DynamicDataApi.find({ filter: JSON.stringify(query) })
            .then(res => {
                let object = res[0]
                object.arrayValue = array
                return DynamicDataApi.updateById(res[0].uuid, object)
            })
    }

    const getVideoUrls = () => {
        let query = {
            where: {
                key: VIDEO
            }
        }
        return DynamicDataApi.find({ filter: JSON.stringify(query) })
            .then(res => {
                let result = []
                if (res.length > 0) {
                    result = res[0].arrayValue
                }
                return result
            })
    }

    const addVideoUrl = (videoUrl) => {
        let array
        let query = {
            where: {
                key: VIDEO
            }
        }
        return DynamicDataApi.find({ filter: JSON.stringify(query) })
            .then(res => {
                let object = {}
                if (res.length > 0) {
                    array = res[0].arrayValue
                    array.push(videoUrl)
                    object = res[0]
                    object.arrayValue = array
                    return DynamicDataApi.updateById(object.uuid, object)
                } else {
                    let object = {
                        key: VIDEO,
                        arrayValue: [videoUrl]
                    }
                    return DynamicDataApi.create(object)
                }
            })
            .then(res => {
                return res
            })
    }

    const deleteVideoUrl = (videoUrl) => {
        let query = {
            where: {
                key: VIDEO
            }
        }
        return DynamicDataApi.find({ filter: JSON.stringify(query) })
            .then(res => {
                let object = {}
                if (res.length > 0) {
                    let array = res[0].arrayValue
                    let index = array.indexOf(videoUrl)
                    array.splice(index, 1)
                    object = res[0]
                    object.arrayValue = array
                }
                return DynamicDataApi.updateById(object.uuid, object)
            })
            .then(res => {
                return res
            })
    }

    const getMessage = (type) => {
        let query = {
            where: {
                key: MESSAGE
            }
        }
        return DynamicDataApi.find({ filter: JSON.stringify(query) })
            .then(res => {
                let result = null
                if (res?.length > 0) {
                    result = {
                        message: res[0].stringValue,
                    }
                    if (Object.prototype.hasOwnProperty.call(res[0]?.objectValue, type)) {
                        result.receiver = res[0].objectValue[type]
                    } else {
                        result.receiver = null
                    }
                }
                return result
            })
    }

    const setMessage = (message, receiver, type) => {
        let query = {
            where: {
                key: MESSAGE
            }
        }
        return DynamicDataApi.find({ filter: JSON.stringify(query) })
            .then(res => {
                let result = null
                if (res?.length > 0) {
                    let object = res[0]
                    object.stringValue = message
                    if (Object.prototype.hasOwnProperty.call(res[0], type)) {
                        object.objectValue[type] = receiver
                    } else {
                        if (object.objectValue == null) {
                            object.objectValue = {}
                        }
                        Object.assign(object.objectValue, { [type]: receiver });
                    }

                    result = DynamicDataApi.updateById(object.uuid, object)
                } else {
                    let object = {
                        stringValue: message,
                        objectValue: {
                            [type]: receiver
                        },
                        key: MESSAGE
                    }
                    result = DynamicDataApi.create(object)
                }
                return result
            })
    }

    const getProductionFormsSettings = () => {
        let query = {
            where: {
                key: PRODUCTION_FORMS
            }
        }
        return DynamicDataApi.find({ filter: JSON.stringify(query) })
                
    }
    

    return {
        getSizes,
        updateSize,
        getVideoUrls,
        addVideoUrl,
        deleteVideoUrl,
        useApi: DynamicDataApi,
        getMessage,
        setMessage,
        getProductionFormsSettings
    }
}

export default useDynamicDataApi