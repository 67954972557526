import React, { useEffect, useContext } from 'react'
import { useComponentState, ApiError, Spinner } from '@collapick/utils';
import DashboardProjectTable from '../../../../components/Table/DashboardProjectTable'
import useDynamicDataApi from '../../../../hooks/Apis/useDynamicDataApi';
import { useHistory } from 'react-router-dom';
import EditDialog from '../../../../components/Dialogs/EditDialog';
import AddFilesDialog from '../../../../components/Dialogs/AddFilesDialog'
import { DataContext } from '../../../../context/DataContext'

const EditProjectView = (props) => {
    const { taskId } = props
    const { componentState, setLoading, setError } = useComponentState()
    const DynamicDataApi = useDynamicDataApi()
    const history = useHistory()
    const dataStore = useContext(DataContext)

    useEffect(() => {
        getSizes()
    }, [taskId])

    const getSizes = () => {
        setLoading(true)
        return DynamicDataApi.getSizes()
            .then(res => {
                dataStore.mobXStore.sizeArray = res
            })
            .catch(setError)
            .finally(setLoading(false))
    }

    const backToProjects = () => {
        history.push('/dashboard/projects')
    }

    let result = null
    if (componentState.loading) {
        result = <Spinner enabled={componentState.loading} />
    } else if (componentState.error) {
        result = <ApiError error={componentState.error} />
    } else {
        result = (
            <>
                {<DashboardProjectTable
                    backToProjects={backToProjects}
                    taskId={taskId}
                />}
                <EditDialog />
                <AddFilesDialog />
            </>
        )
    }
    return result
}

EditProjectView.defaultProps = {
    taskId: "885efc70-6126-11eb-a1d0-e16b1165250b"
}

export default EditProjectView