import React, { useState, useCallback } from "react"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { Button, Container, Chip } from "@material-ui/core"
import MaterialTableIcons from '../MaterialTable/MaterialTabelIcons'
import useMaterialTableOptions from "../MaterialTable/useMaterialTableOptions"
import { theme, COLOR } from "../../hooks/theme/useAppTheme"
import { DataTable } from '../MaterialTable/DataTable';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { makeStyles, fade } from "@material-ui/core/styles"
import CountProjectPercent from './CountProjectPercent'
import useMoment from '../../hooks/useMoment'
import { URGENT_DAYS } from '../../Constants'
import { LocalStorageHelper } from '@collapick/utils';
import CachedIcon from '@material-ui/icons/Cached';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const generateLocalStorageKey = (title, columns) => {
    let key = title + columns?.length;
    return key
}
const useStyles = makeStyles((t) => (
    {
        button: {
            backgroundColor: theme.palette.success.dark,
            color: theme.palette.success.contrastText,
            '&:hover': {
                backgroundColor: fade(theme.palette.success.dark, 0.5),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
                '&$disabled': {
                    backgroundColor: 'transparent',
                },
            },
            '&$disabled': {
                color: theme.palette.action.disabled,
            }
        },
        playArrow: {
            color: theme.palette.getContrastText(theme.palette.success.dark)
        }
    }))

const MobileWorksTable = (props) => {
    const { ajaxWorks, moveToProject } = props
    const { t } = useTranslation()
    const classes = useStyles()
    const MomentApi = useMoment()
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [showComplited, setShowComplited] = useState(false)

    const baseOptions = useMaterialTableOptions()
    const options = {
        ...baseOptions,
        filtering: false,
        sorting: true,
        search: true,
        pageSize: 25
    }

    const defaultColumns = [
        { title: t("projects"), field: "name", filtering: true },
        { title: t("percents"), field: "percents", sorting: false, cellStyle: { padding: 0 } },
        { title: t("dueToDate"), field: "dueToDate" },
        { title: t('week_number'), field: "week", filtering: true },
    ]

    let title = "mobile-projects-table"
    const columns = LocalStorageHelper.get(generateLocalStorageKey(title, defaultColumns)) || defaultColumns

    const clearCache = () => {
        LocalStorageHelper.remove(generateLocalStorageKey(title, defaultColumns))
        forceUpdate();
    }

    const columnsHide = (column) => {
        let newColumns = [...columns];
        let found = newColumns.find(c => c.field == column.field);
        if (found) {
            found.hidden = column.hidden;
        }
        LocalStorageHelper.remove(generateLocalStorageKey(title, defaultColumns))
        LocalStorageHelper.set(generateLocalStorageKey(title, defaultColumns), newColumns)
    }

    let overrideColumns = [...columns];
    overrideColumns.forEach(c => {
        if (c.field == 'percents') {
            c.render = (rowData) => {
                return <CountProjectPercent task={rowData} />
            }
        } else if (c.field == 'dueToDate') {
            c.render = (rowData) => {
                let mainColor = theme.palette.info.main
                let textColor = theme.palette.info.contrastText
                let date = ""
                let oneDay = (24 * 60 * 60 * 1000)

                if (!rowData?.dueToDate) {
                    mainColor = COLOR.LIGHT_2
                    textColor = theme.palette.getContrastText(COLOR.LIGHT_2)
                }
                if (rowData?.dueToDate && /*!MomentApi.isDateAfterNow(rowData.dueToDate))*/
                    moment(rowData?.dueToDate).valueOf() < (moment() + (URGENT_DAYS * oneDay))) {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (rowData?.dueToDate && !MomentApi.isDateAfterNow(rowData.dueToDate)) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }
                if (rowData?.done && rowData?.dueToDate) {
                    mainColor = theme.palette.success.dark
                    textColor = theme.palette.info.contrastText
                }

                if (rowData?.dueToDate != null) {
                    date = moment(rowData?.dueToDate).format("DD.MM.YYYY")
                } else {
                    date = t('no_dueToDate_set')
                }
                return <Chip style={{ backgroundColor: mainColor, color: textColor }} label={date} />
            }
        } else if (c.field == 'week') {
            c.render = (rowData) => {
                let week = moment(rowData.dueToDate).format('W') + " " + t('vko')
                let oneDay = (24 * 60 * 60 * 1000)
                let mainColor = theme.palette.info.main
                let textColor = theme.palette.info.contrastText

                if (!rowData?.dueToDate) {
                    mainColor = COLOR.LIGHT_2
                    textColor = theme.palette.getContrastText(COLOR.LIGHT_2)
                }
                if (rowData?.dueToDate &&
                    moment(rowData?.dueToDate).valueOf() < (moment() + (URGENT_DAYS * oneDay))) {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (rowData?.dueToDate && !MomentApi.isDateAfterNow(rowData.dueToDate)) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }
                if (rowData?.done && rowData?.dueToDate) {
                    mainColor = theme.palette.success.dark
                    textColor = theme.palette.info.contrastText
                }
                if (rowData?.dueToDate == null) {
                    week = t('no_dueToDate_set')
                }
                return (
                    <Chip style={{ backgroundColor: mainColor, color: textColor }} label={week} />
                )
            }
        }
    })


    let actions = [
        {
            icon: () => {
                return (
                    <Button fullWidth size="large" className={classes.button}>
                        <PlayArrowIcon className={classes.playArrow} />
                    </Button>
                )
            },
            tooltip: t('show_work'),
            isFreeAction: false,
            onClick: moveToProject
        },
        {
            key: "clear",
            icon: () => {
                return <CachedIcon />
            },
            tooltip: t('clear_cache'),
            isFreeAction: true,
            onClick: clearCache
        },
        {
            icon: () => {
                if (showComplited) {
                    return <VisibilityOffIcon />
                } else {
                    return <VisibilityIcon style={{ color: COLOR.GREEN }} />
                }
            },
            tooltip: showComplited ? t('hide_complited') : t('show_complited'),
            isFreeAction: true,
            onClick: () => {
                setShowComplited(!showComplited)
            }
        }


    ]

    const ajaxInit = (params) => {
        return ajaxWorks(params, columns, showComplited)
    }


    return (
        <Container maxWidth="xl" style={{ marginTop: 10 }}>
            <DataTable
                options={options}
                title={t("works")}
                columns={columns}
                data={ajaxInit}
                icons={MaterialTableIcons}
                actions={actions}
                columnsHide={columnsHide}
                update={showComplited}
            />
        </Container>
    )
}
export default MobileWorksTable
