import React, { useState, useEffect } from 'react';
import { Typography, TextField, InputLabel, makeStyles, Grid } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useComponentState, ApiError } from '@collapick/utils';

const CustomCustomerField = (props) => {

    const { input, label, validators, meta, customers } = props;
    const { setSelected } = props;
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [open, setOpen] = useState(false);
    const { setLoading, componentState } = useComponentState()

    const onChange = (event, value, reason) => {
        setSelected(value);
        input.onChange(value);
        document.activeElement.blur();
    };

    const handleChange = event => {
        setInputValue(event.target.value);
    };

    const getOptionLabel = (customer) => {
        let result = "";
        if (customer) {
            result = customer
        }
        return result;
    };

    useEffect(() => {
        if (!inputValue || inputValue === '') {
            setOptions([]);
            return undefined;
        }

        if (inputValue.length > 2) {
            setLoading(true);
            let options = []
            customers.forEach(customer => {
                if (customer.name.toLowerCase().includes(inputValue.toLowerCase())) {
                    options.push(customer.name)
                }
            })
            input.onChange(inputValue)
            setOptions(options)
            setLoading(false)
        } else {
            setOptions([])
        }
    }, [inputValue]);

    const useStyles = makeStyles({
        itemcode: {
            fontWeight: 'bold',
            fontSize: '1.1rem'
        },
        othertext: {
            color: '#696969',
            fontSize: '1.1rem'
        }
    });
    const classes = useStyles();

    let result = null
    if (componentState.error) {
        result = <ApiError error={componentState.error} />
    } else {
        result = (
            <>
                <Grid container direction="row" alignItems="flex-end">
                    <p style={{ color: "red", fontSize: "1rem", margin: 0 }}>*</p>
                    <InputLabel>{label}</InputLabel>
                </Grid>
                <Autocomplete
                    getOptionLabel={item => getOptionLabel(item)}
                    open={open}
                    onClose={() => {
                        setOptions([]);
                        setOpen(false);
                    }}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    options={options}
                    autoComplete
                    includeInputInList
                    freeSolo
                    value={input.value}
                    loading={componentState.loading}
                    onChange={onChange}
                    // disableOpenOnFocus
                    renderInput={params => (
                        <TextField
                            {...params}
                            //     error={validators}
                            variant="outlined"
                            error={meta.error && meta.touched}
                            helperText={meta.error && meta.touched && validators[0].config.msg}
                            fullWidth
                            onChange={handleChange}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {componentState.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                    renderOption={(customer) => {
                        return (
                            <React.Fragment>
                                <Typography
                                    className={classes.othertext}>
                                    {customer}
                                </Typography> &emsp;&emsp;
                            </React.Fragment>
                        )
                    }}
                />
            </>
        )
    } return result
};


CustomCustomerField.defaultProps = {
    setSelected: console.log
};

export default CustomCustomerField;
