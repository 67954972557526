import React, { useState, useEffect } from 'react'
import { Select, MenuItem, Input, Checkbox, ListItemText, Grid, FormControl, Container } from '@material-ui/core'
import { theme } from '../../hooks/theme/useAppTheme'
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((t) => (
    {
        root: {
            padding: "2.5vh",
            alignSelf: "center",
            //    textAlign: "center",
            backgroundColor: theme.palette.primary.dark
        },
        select: {
            paddingLeft: "2.5vh",
            paddingRight: "2.5vh"
        },
        input: {
            borderRadius: 4,
            border: '1px solid #ced4da',
            padding: '10px 26px 10px 12px',
            backgroundColor: theme.palette.primary.contrastText,
        }
    }))

const UrgentsSearchBar = (props) => {
    const { search } = props
    const classes = useStyles()
    const [choosedValues, setChoosedValues] = useState([])
    const [values, setValues] = useState([])

    useEffect(() => {
        setUpValues()
    }, [])

    const setUpValues = () => {
        setValues({
            "Tarve": 1, "Tarjous": 2, "Sopimus": 3,
            "Lattiavaihe": 4, "IV-vaihe": 5, "Kalustevaihe": 6,
            "Perehdytys": 7, "Alkutarkastus": 8, "Vuosihuolto": 9, "Työt": 10
        })
    }

    const handleChange = (event) => {
        setChoosedValues(event.target.value)
        search(event.target.value)
    };


    return (
        <Container className={classes.root}>
            <Grid container >
                <FormControl variant="outlined" fullWidth className={classes.select}>
                    {values && <Select
                        multiple
                        value={choosedValues}
                        onChange={handleChange}
                        variant="outlined"
                        input={<Input className={classes.input} />}
                        fullWidth
                        renderValue={(selected) => selected.join(', ')}
                    >
                        {Object.keys(values).map((item, i) => (
                            <MenuItem key={i} value={item}>
                                <Checkbox checked={choosedValues?.indexOf(item) > -1} />
                                <ListItemText primary={item} />
                            </MenuItem>
                        ))}
                    </Select>}
                </FormControl>
            </Grid>
        </Container>
    )
}

export default UrgentsSearchBar