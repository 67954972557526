import React from 'react'
import { useTranslation } from 'react-i18next';
import CustomCustomerField from '../components/Form/CustomCustomerField';
import { FormElements } from '@collapick/form';

const useNewCustomProjectCreationSchema = (props) => {
    const { sizeArray, customers } = props
    const { t } = useTranslation();

    let customCreationSchema = {
        id: "creationSchema",
        elements: [
            {
                type: "grid",
                direction: 'row',
                spacing: 2,
                elements: [
                    {

                        type: "text",
                        name: 'name',
                        label: t('name'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "customCustomerField",
                        name: "customer",
                        label: t('customer'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('customer_is_required')
                                }
                            },
                        ]
                    },
                    {

                        type: "select",
                        name: 'unit',
                        label: t('project_size'),
                        item: {
                            xs: 12
                        },
                        options:
                            sizeArray.map(size => {
                                return { value: size, label: size }
                            })

                    },
                    {

                        type: "date",
                        name: 'dueToDate',
                        label: t('due_to_date'),
                        item: {
                            xs: 12,
                        },
                        elementProps: {
                            inputVariant: "outlined"
                        },
                    },
                    {
                        type: "text",
                        name: 'tarve.sub1',
                        label: t('tarve_sub1'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'tarve.sub2',
                        label: t('tarve_sub2'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'tarve.sub3',
                        label: t('tarve_sub3'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'tarjous.sub1',
                        label: t('tarjous_sub1'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'tarjous.sub2',
                        label: t('tarjous_sub2'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'tarjous.sub3',
                        label: t('tarjous_sub3'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'sopimus.sub1',
                        label: t('sopimus_sub1'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'sopimus.sub2',
                        label: t('sopimus_sub2'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'sopimus.sub3',
                        label: t('sopimus_sub3'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'lattiavaihe.sub1',
                        label: t('lattiavaihe_sub1'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'lattiavaihe.sub2',
                        label: t('lattiavaihe_sub2'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'lattiavaihe.sub3',
                        label: t('lattiavaihe_sub3'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'iv_vaihe.sub1',
                        label: t('iv_vaihe_sub1'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'iv_vaihe.sub2',
                        label: t('iv_vaihe_sub2'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'iv_vaihe.sub3',
                        label: t('iv_vaihe_sub3'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'kalustevaihe.sub1',
                        label: t('kalustevaihe_sub1'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'kalustevaihe.sub2',
                        label: t('kalustevaihe_sub2'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'kalustevaihe.sub3',
                        label: t('kalustevaihe_sub3'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'perehdytys.sub1',
                        label: t('perehdytys_sub1'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'perehdytys.sub2',
                        label: t('perehdytys_sub2'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'perehdytys.sub3',
                        label: t('perehdytys_sub3'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'alkutarkastus.sub1',
                        label: t('alkutarkastus_sub1'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'alkutarkastus.sub2',
                        label: t('alkutarkastus_sub2'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'alkutarkastus.sub3',
                        label: t('alkutarkastus_sub3'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'vuosihuolto.sub1',
                        label: t('vuosihuolto_sub1'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'vuosihuolto.sub2',
                        label: t('vuosihuolto_sub2'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "text",
                        name: 'vuosihuolto.sub3',
                        label: t('vuosihuolto_sub3'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: 'grid'
                    }
                ]
            }

        ]
    }

    const customElements = Object.assign(
        FormElements,
        {
            "customCustomerField": {
                component: (props) => {
                    return (
                        <>
                            { customers &&
                                <CustomCustomerField customers={customers} {...props} />}
                        </>
                    )
                }
            }
        })

    return {
        customCreationSchema,
        customElements
    }
}

export default useNewCustomProjectCreationSchema;
