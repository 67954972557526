import React, { useContext, useEffect } from "react"
import { AppBar, Toolbar, Tabs, Tab } from '@material-ui/core';
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { DataContext } from '../../context/DataContext'
import useTabs from './useTabs'

const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.info.contrastText,
        marginBottom: "5vh"
    }
}));

const DashboardSubHeader = (props) => {
    const history = useHistory();
    const classes = useStyles()
    let address = useLocation()
    address = address.pathname
    const store = useContext(DataContext)
    const tabs = useTabs()


    const handleChange = (event, newValue) => {
        store.setHeaderValue(newValue)
        tabs.forEach((tab, index) => {
            if (index == newValue) {
                history.push(tab.href)
            }
        })
    };

    useEffect(() => {
        let found = false
        tabs.forEach((tab, index) => {
            if (tab.id == address) {
                if (store.headerValue != index) {
                    store.setHeaderValue(index)

                }
                found = true
            }
        })
        if (found == false) {
            store.setHeaderValue(false)
        }
    }, [])

    let items = tabs.map((tab, index) => {
        return <Tab label={tab.label} key={index} />
    })


    return (
        <>
            <AppBar position="static" className={classes.header}>
                <Toolbar >
                    <Tabs
                        variant="fullWidth"
                        value={store?.headerValue}
                        onChange={handleChange}
                        centered
                        style={{ flexGrow: 1, marginLeft: "auto", marginRight: "auto" }}
                    >
                        {items}
                    </Tabs>
                </Toolbar>
            </AppBar>
        </>
    )
}

DashboardSubHeader.defaultProps = {

}

export default DashboardSubHeader
