import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableCell } from 'material-table';
import React, { useRef, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import MaterialTableIcons from './MaterialTabelIcons';

const useStyles = makeStyles(theme => ({
    withoutPadding: {
        padding: 0
    },
    withPadding: {
        paddingLeft: 5,
        paddingRight: 5
    },
}));


const DataTable = (props) => {
    const { options, columns, components, columnsHide, title, ...rest } = props
    const { t } = useTranslation();
    const classes = useStyles()
    const tableRef = useRef()

    const tableOptions = Object.assign(
        {},
        {
            toolbar: true,
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 100],
            paginationType: 'normal',
            exportButton: true,
            search: false,
            filtering: true,
            actionsColumnIndex: columns.length + 1,
            emptyRowsWhenPaging: false,
            headerStyle: {
                textAlign: "center"
            },

            columnsButton: true,


            //actionsCellStyle: {backgroundColor: green[500]},
            //headerStyle: { backgroundColor: "#eceff1" },
            //cellStyle: { padding: 10 },
            // rowStyle: { padding: 0 }
        },
        options);


    let overriedComponents = {
        Container: props => <Paper {...props} elevation={0} />,
        // Header: props => {
        //     console.log("HEADER", props)
        //     return false
        // }
        //Row: props => <div>hello</div>
        Cell: props => {
            const disablePadding = props.columnDef.disablePadding
            //console.log("CELL", props)
            return (
                <>
                    <MTableCell
                        {...props}
                        classes={{
                            root: disablePadding == true ? classes.withoutPadding : classes.withPadding,
                            paddingNone: classes.withoutPadding
                        }}
                        style={{ textAlign: "center" }}
                        padding={disablePadding ? "none" : "default"}
                    />
                </>
            )
        },
    }

    useMemo(() => {
        if (props.data != null &&
            typeof props.data === 'function' &&
            tableRef?.current != null &&
            tableRef?.current != undefined) {
            tableRef.current.onQueryChange()
        }
        return (() => {

        })
    }, [props.update])

    return (
        <>
            <MaterialTable
                tableRef={tableRef}
                title={title}
                columns={columns}
                onChangeColumnHidden={columnsHide}
                components={components ? components : overriedComponents}
                icons={MaterialTableIcons}
                options={tableOptions}
                localization={{
                    pagination: {
                        labelDisplayedRows: t("displayed_rows"),

                        labelRowsSelect: t("rows"),

                        firstAriaLabel: t("first_page"),
                        firstTooltip: t("first_page"),

                        previousAriaLabel: t("previous_page"),
                        previousTooltip: t("previous_page"),

                        nextAriaLabel: t("next_page"),
                        nextTooltip: t("next_page"),

                        lastAriaLabel: t("last_page"),
                        lastTooltip: t("last_page"),

                    },
                    toolbar: {
                        //nRowsSelected: '{count} row(s) selected' // TODO i18
                        searchTooltip: t("search"),
                        searchPlaceholder: t("search"),
                        exportName: t("export_as_csv"),
                        exportTitle: t("export"),
                        exportAriaLabel: t("export"),
                        showColumnsTitle: t("show_columns"),
                        showColumnsAriaLabel: t("show_columns"),
                        addRemoveColumns: t("addRemoveColumns")
                    },
                    header: {
                        actions: t("actions")
                    },
                    body: {
                        emptyDataSourceMessage: t("no_records"),
                        filterRow: {
                            filterTooltip: t("filter")
                        }
                    }
                }}
                {...rest}
            />

        </>

    )
};

DataTable.defaultProps = {
    options: {},
    columns: [],
}
export { DataTable };

