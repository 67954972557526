import { useTranslation } from 'react-i18next';

export const WORKS = "works"
export const CUSTOM = "custom"
export const DAYS_BEFORE_PARENT_LEVEL = 7
export const DAYS_BEFORE_MAIN_LEVEL = 2
export const PRODUCTION_DAYS_BEFORE_MAIN_LEVEL = 2
export const PRODUCTION_DAYS_BEFORE_LEVEL_1 = 3
export const PRODUCTION_DAYS_BEFORE_LEVEL_2 = 21
export const SALE = "sale"
export const PRODUCTION = "production"
export const SERVICE = "service"
export const PROJECT = "project"
export const URGENTS = "urgents"
export const PROJECTS = "projects"
export const FINISHING = "Lopetus"
export const URGENT_DAYS = 7
export const FORM = "form"
export const TABLE = "table"
export const MAIN = "main"
export const VIDEO = "video"
export const MESSAGE = "message"
export const HIDDEN_USERS = ["Ponniste tuki", "Kytkin"]
export const ponnisteUiUrl = "https://management.joentaiturit.ponniste.com"
export const feedbackFormUrl = "https://management.joentaiturit.ponniste.com/dynamic-features/add/8e77d779-af76-4266-9815-451679b7cc05"
export const feedbackSummaryUrl = "https://management.joentaiturit.ponniste.com/dynamic-features/visual-steps/8e77d779-af76-4266-9815-451679b7cc05"
export const PRODUCTION_FORMS = "production_forms"
export const LEVELS = [
    {
        "Myynti": [
            "Tarve",
            "Tarjous",
            "Sopimus"
        ]
    },
    {
        "Tuotanto": [
            "Lattiavaihe",
            "IV-vaihe",
            "Kalustevaihe"
        ]
    },
    {
        "Huolto": [
            "Perehdytys",
            "Alkutarkastus",
            "Vuosihuolto"
        ]
    }
]

export const useTypes = () => {
    const { t } = useTranslation();

    return {
        SALE: t("sale"),
        PRODUCTION: t("production"),
        SERVICE: t("service")
    }
}