import React from 'react'
import { Redirect } from "react-router-dom";
import { useUserContext } from '../../context/UserContext';
import useRoutes from "../../hooks/useRoutes"

const DeviceChecker = (props) => {
    const { PATH } = useRoutes()
    const { user } = useUserContext()

    let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    let result = null

    // If user has no admin role or admin uses mobile then mobile
    if (isMobile || user?.userBind?.appUser?.roles.some(e => e.name === 'admin') == false) {
        result = <Redirect to={PATH.mobile.root} />
    } else {
        result = <Redirect to={PATH.dashboard.root} />
    }

    return result
}

export default DeviceChecker