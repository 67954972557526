import React, { useState, useEffect } from 'react'
import useTaskApi from '../../../../hooks/Apis/useTaskApi'
import { useComponentState, ApiError, Spinner } from '@collapick/utils';
import { useHistory, useLocation } from 'react-router-dom';
import { Container } from '@material-ui/core';
import UploadForm from '../../../../components/Form/UploadForm';

const ProjectsUploadView = (props) => {
    const { taskId } = props
    const TaskApi = useTaskApi()
    const { componentState, setLoading, setError } = useComponentState()
    const history = useHistory()
    const [task, setTask] = useState(null)
    const location = useLocation()

    useEffect(() => {
        getTask()
    }, [location])

    const getTask = () => {
        setLoading(true)
        return TaskApi.getTask(taskId)
            .then(res => {
                setTask(res[0])
            })
            .catch(setError)
            .finally(() => {
                setLoading(false)

            })
    }

    const onSave = (values) => {
        let object = task
        object.files = values.files
        setLoading(true)
        return TaskApi.updateTask(object)
            .catch(setError)
            .finally(() => {
                setLoading(false)
                if (history.length > 0) {
                    history.goBack()
                } else {
                    history.push('/mobile/projects/todoview/' + [task.uuid])
                }
            })
    }

    const onCancel = (values) => {
        if (history.length > 0) {
            history.goBack()
        } else {
            history.push('/mobile/projects/todoview/' + [task.uuid])
        }
    }

    let result = null
    if (componentState.loading) {
        result = <Spinner enabled={componentState.loading} />
    } else if (componentState.error) {
        result = <ApiError error={componentState.error} />
    } else {
        result = (
            <Container >
                <UploadForm onSave={onSave} onCancel={onCancel} initialValues={task} />
            </Container>
        )
    }
    return result
}

ProjectsUploadView.defaultProps = {
    taskId: "e3350940-5b05-11eb-837a-83948b54c92f"
}

export default ProjectsUploadView
