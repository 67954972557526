import React, { useEffect, useState } from 'react'
import { Grid, Button, Typography, Paper } from '@material-ui/core';
import { theme, COLOR } from '../../hooks/theme/useAppTheme'
import { makeStyles, fade } from "@material-ui/core/styles"
import { useTranslation } from 'react-i18next';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { PRODUCTION, SERVICE, SALE } from '../../Constants'
import ListIcon from '@material-ui/icons/List';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { TaskTypes } from '@collapick/ponniste-types';
import useTaskApi from '../../hooks/Apis/useTaskApi';

const useStyles = makeStyles((t) => (
    {
        root: {
            backgroundColor: theme.palette.primary.dark,
            display: "flex",
            textAlign: "center",
            alignSelf: "center",

            justifyContent: "space-around"
        },
        typography: {
            color: COLOR.scheduleBar
        },
        icon: {
            color: COLOR.scheduleBar,
            fontSize: "5vh"
        },
        row: {
            display: "flex",
            textAlign: "center",
            alignSelf: "center",
            padding: "0.5vh",
            justifyContent: "space-around",
            alignItems: "center"
        },
        bottom: props => ({
            backgroundColor: props.color,
            paddingTop: "2.5vh",
            paddingBottom: "20vh",
            alignSelf: "center"
        }),
        middle: props => ({
            backgroundColor: props.color,
            paddingTop: "2.5vh",
            paddingBottom: "2.5vh",
            alignSelf: "center"
        }),
        button: {
            backgroundColor: theme.palette.success.dark,
            color: theme.palette.info.contrastText,
            '&:hover': {
                backgroundColor: fade(theme.palette.success.dark, 0.5),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
                '&$disabled': {
                    backgroundColor: 'transparent',
                },
            },
            '&$disabled': {
                color: theme.palette.action.disabled,
            }
        },
        fileButton: {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.info.contrastText,
            textTransform: 'none',
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxWidth: "100%",
            '&:hover': {
                backgroundColor: fade(theme.palette.info.main, 0.5),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
                '&$disabled': {
                    backgroundColor: 'transparent',
                },
            },
            '&$disabled': {
                color: theme.palette.action.disabled,
            }
        }
    }))

const FilesAndManuals = (props) => {
    const { task, addFile, openFile } = props
    const [defaultAttactments, setDefaultAttachments] = useState([])
    const TaskApi = useTaskApi()

    useEffect(() => {
        getDefaultAttachments()
    }, [task])

    const getDefaultAttachments = () => {
        if (Array.isArray(task?.taskObject)) {
            let filter = {
                where: {
                    uuid: { inq: task?.taskObject }
                }
            }
            return TaskApi.getDefaultAttachments(filter)
                .then(res => {
                    setDefaultAttachments(res)
                })
                .catch(err => { throw err })
        }

    }

    let color = null
    if (task?.helperBaseName == PRODUCTION) {
        color = theme.palette.primary.main
    } else if (task?.helperBaseName == SALE) {
        color = theme.palette.success.main
    } else if (task?.helperBaseName == SERVICE) {
        color = theme.palette.secondary.main
    } else {
        color = theme.palette.primary.main
    }

    const classes = useStyles({ color })
    const { t } = useTranslation()

    const files = task?.files?.map(file => {
        return (
            <Grid item xs={12} key={file.uuid} className={classes.row}>
                <Grid item xs={2}>
                    <ListIcon className={classes.icon} />
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.fileButton}>
                        <Button
                            className={classes.fileButton}
                            fullWidth
                            onClick={() => openFile(file)}
                        >
                            {file.name}
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        )
    })

    const attachments = defaultAttactments.map(t => {
        return t.files.map(file => {
            return (
                <Grid item xs={12} key={file.uuid} className={classes.row}>
                    <Grid item xs={2}>
                        <ListIcon className={classes.icon} />
                    </Grid>
                    <Grid item xs={6}>
                        <Paper className={classes.fileButton}>
                            <Button
                                className={classes.fileButton}
                                fullWidth
                                onClick={() => openFile(file)}
                            >
                                {file.name}
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
            )
        })
    })

    let result = (
        <>
            <Grid container className={classes.root}>
                <Grid item xs={12} className={classes.row}>
                    <Grid item xs={2} >
                        <FolderOpenIcon className={classes.icon} />
                    </Grid>
                    <Grid item xs={6} >
                        <Typography variant="h6" className={classes.typography}>
                            {t('files')}
                        </Typography>
                    </Grid>
                </Grid>

            </Grid >
            <Grid container className={classes.middle}>
                {files}
                {task.taskType == TaskTypes.commonToDo() && <Grid item xs={12} className={classes.row}>
                    <Grid item xs={2}>
                        <AddCircleOutlineIcon className={classes.icon} />
                    </Grid>
                    <Grid item xs={6}>
                        <Paper className={classes.button}>
                            <Button
                                className={classes.button}
                                fullWidth
                                onClick={addFile}
                            >
                                {t('add_file')}
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>}
            </Grid>
            {attachments?.length > 0 && <Grid container className={classes.root}>
                <Grid item xs={12} className={classes.row}>
                    <Grid item xs={2} >
                        <FolderOpenIcon className={classes.icon} />
                    </Grid>
                    <Grid item xs={6} >
                        <Typography variant="h6" className={classes.typography}>
                            {t('manuals')}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid >}
            <Grid container className={classes.bottom}>
                {attachments}
            </Grid>
        </>

    )

    return result
}

export default FilesAndManuals