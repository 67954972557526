import React, { useState, useContext } from 'react'
import { Grid, Button, Container, Paper } from '@material-ui/core';
import { theme } from '../../hooks/theme/useAppTheme'
import { makeStyles, fade } from "@material-ui/core/styles"
import { useTranslation } from 'react-i18next';
import { PRODUCTION, SERVICE, SALE, CUSTOM } from '../../Constants'
import UrgentsTable from '../Table/UrgentsTable';
import UrgentsSearchBar from './UrgentsSearchBar'
import { useHistory } from 'react-router-dom';
import { TaskTypes } from '@collapick/ponniste-types';
import { DataContext } from '../../context/DataContext'

const useStyles = makeStyles((t) => (
    {
        root: {
            padding: "2.5vh",
            alignSelf: "center",
            textAlign: "center",
            backgroundColor: theme.palette.primary.light
        },
        production: {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
                backgroundColor: fade(theme.palette.info.main, 0.5),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
                '&$disabled': {
                    backgroundColor: 'transparent',
                },
            },
            '&$disabled': {
                color: theme.palette.action.disabled,
            }

        },
        sale: {
            backgroundColor: theme.palette.success.dark,
            color: theme.palette.primary.contrastText,
            '&:hover': {
                backgroundColor: fade(theme.palette.success.dark, 0.5),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
                '&$disabled': {
                    backgroundColor: 'transparent',
                },
            },
            '&$disabled': {
                color: theme.palette.action.disabled,
            }
        },
        service: {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.primary.contrastText,
            '&:hover': {
                backgroundColor: fade(theme.palette.secondary.dark, 0.5),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
                '&$disabled': {
                    backgroundColor: 'transparent',
                },
            },
            '&$disabled': {
                color: theme.palette.action.disabled,
            }
        },
        work: {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
            '&:hover': {
                backgroundColor: fade(theme.palette.primary.dark, 0.5),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
                '&$disabled': {
                    backgroundColor: 'transparent',
                },
            },
            '&$disabled': {
                color: theme.palette.action.disabled,
            }
        }

    }))

const UrgentsSelection = (props) => {
    const { sendEmail } = props
    const { t } = useTranslation()
    const [openSale, setOpenSale] = useState(false)
    const [openProduction, setOpenProduction] = useState(false)
    const [openService, setOpenService] = useState(false)
    const [openWork, setOpenWork] = useState(false)
    const classes = useStyles()
    const [values, setValues] = useState(null)
    const history = useHistory()
    const dataStore = useContext(DataContext)

    const search = (values) => {
        setValues(values)
    }

    const moveToLevel = (event, rowData) => {
        dataStore.mobXStore.fromUrgents = true
        if (rowData.taskType == TaskTypes.todo()) {
            history.push('/mobile/projects/todoview/' + [rowData.parentTaskId] )
        } else {
            history.push('/mobile/projects/todoview/' + [rowData.uuid])
        }
    }

    let result = (
        <>
            <UrgentsSearchBar search={search} />
            <Container className={classes.root}>
                <Grid container spacing={1}>
                    <Grid item xs={12} className={classes.row}>
                        <Paper className={classes.sale}>
                            <Button
                                className={classes.sale}
                                fullWidth
                                onClick={() => setOpenSale(!openSale)}
                            >
                                {t('sale')}
                            </Button>
                        </Paper>
                        {openSale && <UrgentsTable
                            title={t('sale')}
                            type={SALE}
                            values={values}
                            moveToLevel={moveToLevel}
                            sendEmail={sendEmail}
                        />}
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                        <Paper className={classes.production}>
                            <Button
                                className={classes.production}
                                fullWidth
                                onClick={() => setOpenProduction(!openProduction)}
                            >
                                {t('production')}
                            </Button>
                        </Paper>
                        {openProduction && <UrgentsTable
                            title={t('production')}
                            type={PRODUCTION}
                            values={values}
                            moveToLevel={moveToLevel}
                            sendEmail={sendEmail}
                        />}
                    </Grid>
                    <Grid item xs={12} className={classes.row} >
                        <Paper className={classes.service}>
                            <Button
                                className={classes.service}
                                fullWidth
                                onClick={() => setOpenService(!openService)}
                            >
                                {t('service')}
                            </Button>
                        </Paper>
                        {openService && <UrgentsTable
                            title={t('service')}
                            type={SERVICE}
                            values={values}
                            moveToLevel={moveToLevel}
                            sendEmail={sendEmail}
                        />}
                    </Grid>
                    {/*<Grid item xs={12} className={classes.row} >
                        <Paper className={classes.work}>
                            <Button
                                className={classes.work}
                                fullWidth
                                onClick={() => setOpenWork(!openWork)}
                            >
                                {t('works')}
                            </Button>
                        </Paper>
                        {openWork && <UrgentsTable
                            title={t('works')}
                            type={CUSTOM}
                            values={values}
                            moveToLevel={moveToLevel}
                            sendEmail={sendEmail}
                        />}
                        </Grid>*/}
                </Grid>
            </Container>
        </>
    )



    return result
}

UrgentsSelection.defaultProps = {

}

export default UrgentsSelection