import React, { useState, useEffect } from 'react'
import NewProjectCreationForm from '../../../../components/Form/NewProjectCreationForm';
import { useTranslation } from 'react-i18next';
import { useComponentState, ApiError, Spinner } from '@collapick/utils';
import useCustomerApi from '../../../../hooks/Apis/useCustomerApi';
import useDynamicDataApi from '../../../../hooks/Apis/useDynamicDataApi';
import Axios from 'axios'
import { useHistory } from 'react-router-dom';
import { Container } from '@material-ui/core';

const NewProjectView = (props) => {

    const { t } = useTranslation()
    const CustomerApi = useCustomerApi()
    const DynamicDataApi = useDynamicDataApi()
    const { componentState, setLoading, setError } = useComponentState()
    const [customers, setCustomers] = useState([])
    const [sizeArray, setSizeArray] = useState([])
    const history = useHistory()

    useEffect(() => {
        getCustomers()
        getSizes()
    }, [])

    const getCustomers = () => {
        setLoading(true)
        return CustomerApi.getCustomers()
            .then(res => {
                setCustomers(res)
            })
            .catch(setError)
            .finally(setLoading(false))
    }

    const getSizes = () => {
        setLoading(true)
        return DynamicDataApi.getSizes()
            .then(res => {
                setSizeArray(res)
            })
            .catch(setError)
            .finally(setLoading(false))
    }

    const start = (values) => {
        console.log(values)
        let url = window.BASEURL + "kytkin-api/createProject/"
        let uuid = null
        setLoading(true)
        if (customers.some(e => e.name === values.customer)) {
            let customer = customers.find(e => e.name == values.customer)
            values.customerId = customer.uuid
            return Axios.post(url, { basicValues: values })
                .then(res => uuid = res.data)
                .catch(setError)
                .finally(() => {
                    setLoading(false)
                    history.push('/dashboard/projects/editproject/' + [uuid])
                })
        } else {
            return CustomerApi.createCustomer(values.customer)
                .then(res => {
                    values.customerId = res.uuid
                    return Axios.post(url, { basicValues: values })
                })
                .then(res => uuid = res.data)
                .catch(setError)
                .finally(() => {
                    setLoading(false)
                    history.push('/dashboard/projects/editproject/' + [uuid])
                })
        }
    }

    const cancel = () => {
        if (history.length > 0) {
            history.goBack()
        } else {
            history.push('/dashboard/projects')
        }
    }

    let result = null
    if (componentState.loading) {
        result = <Spinner enabled={componentState.loading} />
    } else if (componentState.error) {
        result = <ApiError error={componentState.error} />
    } else {
        result = (
            <Container>
                {customers && sizeArray && <NewProjectCreationForm
                    onSave={start}
                    onCancel={cancel}
                    title={t('create_new_project')}
                    sizeArray={sizeArray}
                    customers={customers}
                />}
            </Container>
        )
    }
    return result
}

export default NewProjectView