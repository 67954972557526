import React from "react"
import { useAppContext } from "../../context/AppContext"
import { Box, Typography, Grid } from "@material-ui/core"

const Footer = (props) => {
   
    const appContext = useAppContext("footer")

    return (
        <>
            <Box m={4}>
                <Grid container alignItems="center" direction="column">
                    <Grid item>
                        <Typography variant="caption" color="textSecondary">
                            {appContext?.version ?? "no version"}{" "}
                        </Typography>
                    </Grid>
                    <Grid item></Grid>
                </Grid>
            </Box>
        </>
    )
}

export default Footer
