import React, { useState, useCallback, useContext } from "react"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { Container, Chip } from "@material-ui/core"
import MaterialTableIcons from '../MaterialTable/MaterialTabelIcons'
import useMaterialTableOptions from "../MaterialTable/useMaterialTableOptions"
import { theme, COLOR } from "../../hooks/theme/useAppTheme"
import { DataTable } from '../MaterialTable/DataTable';
import useMoment from '../../hooks/useMoment'
import { URGENT_DAYS, SALE, PRODUCTION, SERVICE } from '../../Constants'
import CountUsedDuration from './CountUsedDuration';
import EditIcon from '@material-ui/icons/Edit';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { LocalStorageHelper } from '@collapick/utils';
import CachedIcon from '@material-ui/icons/Cached';
import { DataContext } from '../../context/DataContext'
import useTaskApi from '../../hooks/Apis/useTaskApi';
import { observer } from "mobx-react-lite"
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DashboardWorksTableDetailPanelLastLevel from "./DashboardWorksTableDetailPanelLastLevel"
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const generateLocalStorageKey = (title, columns) => {
    let key = title + columns?.length;
    return key
}

const DashboardWorksTable = (props) => {
    const { addWork, deleteWork, addCustomProject } = props
    const { t } = useTranslation()
    const MomentApi = useMoment()
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const dataStore = useContext(DataContext)
    const TaskApi = useTaskApi()
    const [showComplited, setShowComplited] = useState(false)

    const baseOptions = useMaterialTableOptions()
    const options = {
        ...baseOptions,
        filtering: false,
        sorting: true,
        search: true,
        pageSize: 25
    }

    const defaultColumns = [
        { title: t("name"), field: "name", filtering: true },
        { title: t("size"), field: "unit", filtering: true },
        { title: t("dueToDate"), field: "dueToDate" },
        { title: t('week_number'), field: "week", filtering: true },
        { title: t('customer'), field: "user", filtering: true },
        { title: t('comment'), field: "comment" },
        { title: t('estimatedDuration') + "(h)", field: "estimatedDuration" },
        { title: t('duration') + "(h)", field: "duration", sorting: false },
        { title: t('completed'), field: "completed", sorting: false }
    ]

    let title = "dashboard-works-table"
    const columns = LocalStorageHelper.get(generateLocalStorageKey(title, defaultColumns)) || defaultColumns

    const clearCache = () => {
        LocalStorageHelper.remove(generateLocalStorageKey(title, defaultColumns))
        forceUpdate();
    }

    const columnsHide = (column) => {
        let newColumns = [...columns];
        let found = newColumns.find(c => c.field == column.field);
        if (found) {
            found.hidden = column.hidden;
        }
        LocalStorageHelper.remove(generateLocalStorageKey(title, defaultColumns))
        LocalStorageHelper.set(generateLocalStorageKey(title, defaultColumns), newColumns)
    }

    let overrideColumns = [...columns];
    overrideColumns.forEach(c => {
        if (c.field == 'dueToDate') {
            c.render = (rowData) => {
                let mainColor = theme.palette.info.main
                let textColor = theme.palette.info.contrastText
                let date = ""
                let oneDay = (24 * 60 * 60 * 1000)

                if (!rowData?.dueToDate) {
                    mainColor = COLOR.LIGHT_2
                    textColor = theme.palette.getContrastText(COLOR.LIGHT_2)
                }
                if (rowData?.dueToDate && /*!MomentApi.isDateAfterNow(rowData.dueToDate))*/
                    moment(rowData?.dueToDate).valueOf() < (moment() + (URGENT_DAYS * oneDay))) {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (rowData?.dueToDate && !MomentApi.isDateAfterNow(rowData.dueToDate)) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }

                if (rowData?.done && rowData?.dueToDate) {
                    mainColor = theme.palette.success.dark
                    textColor = theme.palette.info.contrastText
                }

                if (rowData?.dueToDate != null) {
                    date = moment(rowData?.dueToDate).format("DD.MM.YYYY")
                } else {
                    date = t('no_dueToDate_set')
                }
                return <Chip style={{ backgroundColor: mainColor, color: textColor }} label={date} />
            }
        } else if (c.field == 'week') {
            c.render = (rowData) => {
                let week = moment(rowData.dueToDate).format('W') + " " + t('vko')
                let oneDay = (24 * 60 * 60 * 1000)
                let mainColor = theme.palette.info.main
                let textColor = theme.palette.info.contrastText

                if (!rowData?.dueToDate) {
                    mainColor = COLOR.LIGHT_2
                    textColor = theme.palette.getContrastText(COLOR.LIGHT_2)
                }
                if (rowData?.dueToDate && /*!MomentApi.isDateAfterNow(rowData.dueToDate))*/
                    moment(rowData?.dueToDate).valueOf() < (moment() + (URGENT_DAYS * oneDay))) {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (rowData?.dueToDate && !MomentApi.isDateAfterNow(rowData.dueToDate)) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }
                if (rowData?.done && rowData?.dueToDate) {
                    mainColor = theme.palette.success.dark
                    textColor = theme.palette.info.contrastText
                }
                if (rowData?.dueToDate == null) {
                    week = t('no_dueToDate_set')
                }
                return (
                    <Chip style={{ backgroundColor: mainColor, color: textColor }} label={week} />
                )
            }
        } else if (c.field == 'duration') {
            c.render = (rowData) => {
                return <CountUsedDuration task={rowData} />
            }
        } else if (c.field == 'completed') {
            c.render = (rowData) => {
                let mainColor = null
                let textColor = null
                if (rowData?.completed?.count == rowData?.completed?.totalCount) {
                    mainColor = theme.palette.success.dark
                    textColor = theme.palette.info.contrastText
                } else {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (rowData?.completed?.count == 0) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }
                if (rowData?.completed) {
                    return <Chip
                        style={{ backgroundColor: mainColor, color: textColor }}
                        label={rowData.completed.count + "/" + rowData.completed.totalCount} />
                }
            }
        } else if (c.field == 'taskObject.size') {
            c.render = (rowData) => {
                if (rowData?.taskObject?.size) {
                    return rowData.taskObject.size + "m2"
                }
            }
        } else if (c.field == 'taskObject.anotherSize') {
            c.render = (rowData) => {
                if (rowData?.taskObject?.anotherSize) {
                    return rowData.taskObject.anotherSize + "m2"
                }
            }
        }
    })

    let actions = [
        {
            icon: () => {
                return < EditIcon style={{ color: COLOR.GREEN }} />
            },
            tooltip: t('edit_project'),
            onClick: dataStore.mobXStore.editLevel
        },
        rowData => {
            return ({
                icon: () => {
                    let result = null
                    if (rowData.files) {
                        result = < DescriptionIcon style={{ color: COLOR.GREEN }} />
                    } else {
                        result = < DescriptionIcon />
                    }
                    return result
                },
                tooltip: t('add_files'),
                onClick: dataStore.mobXStore.addFiles
            })
        },
        {
            icon: () => {
                return < DeleteForeverIcon style={{ color: COLOR.GREEN }} />
            },
            tooltip: t('delete_work'),
            onClick: deleteWork
        },
        {
            icon: () => {
                return < AddBoxIcon style={{ color: COLOR.GREEN }} />
            },
            tooltip: t('add_work'),
            onClick: addWork,
            isFreeAction: true
        },
        {
            key: "clear",
            icon: () => {
                return <CachedIcon />
            },
            tooltip: t('clear_cache'),
            isFreeAction: true,
            onClick: clearCache
        },
        {
            icon: () => {
                if (showComplited) {
                    return <VisibilityOffIcon />
                } else {
                    return <VisibilityIcon style={{ color: COLOR.GREEN }} />
                }
            },
            tooltip: showComplited ? t('hide_complited') : t('show_complited'),
            isFreeAction: true,
            onClick: () => {
                setShowComplited(!showComplited)
                dataStore.mobXStore.updateChild = !dataStore.mobXStore.updateChild
            }
        }
    ]

    let detailPanel = [
        rowData => {
            return ({
                tooltip: t('show_details'),
                render: (rowData) => {
                    let result = null
                    result = (
                        <DashboardWorksTableDetailPanelLastLevel
                            id={rowData.uuid}
                        />
                    )
                    return result
                }
            })
        }
    ]

    const calcResult = (array) => {
        array.forEach(task => {
            task.completed = {
                count: 0,
                totalCount: 0
            }
            task.childTasks.forEach(childTask => {
                task.completed.totalCount++
                task.completed.count += childTask.done
            })
        })
        return array
    }


    const ajax = (params) => {
        return TaskApi.getAllWorks(params, columns, showComplited)
            .then(res => {
                let calculatedResult = calcResult(res)
                return (
                    {
                        totalCount: res.count,
                        page: params.page,
                        data: calculatedResult
                    }
                )
            })
    }

    let result = (
        <Container maxWidth="xl" style={{ marginTop: 10 }}>
            <DataTable
                options={options}
                title={t("works")}
                columns={overrideColumns}
                data={ajax}
                icons={MaterialTableIcons}
                actions={actions}
                detailPanel={detailPanel}
                columnsHide={columnsHide}
                update={dataStore.mobXStore.updateChild}
            />
        </Container>
    )
    return result
}
export default observer(DashboardWorksTable)
