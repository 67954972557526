import React, { useEffect, useState } from 'react'
import { CollapickForm } from '@collapick/form';
import useAnswerFormSchema from '../../FormSchemas/useAnswerFormSchema'
import { Grid, Box, Button, Typography, IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import PonnisteIframe from '../ProductionForms/PonnisteIframe';
import ContentDialog from '../Dialogs/ContentDialog';
import { Close } from '@material-ui/icons';
import useFormAnswerApi from '../../hooks/Apis/useFormAnswerApi';
import { useComponentState, ApiError, Spinner } from '@collapick/utils';
import { TaskTypes } from '@collapick/ponniste-types';
import ReviewForm from './ReviewForm';
import { theme } from '../../hooks/theme/useAppTheme';

const AnswerForm = (props) => {
    const { initialValues, title, onSave, onCancel } = props
    const { t } = useTranslation()
    const { answerSchema, customElements } = useAnswerFormSchema({ initialValues });
    const [isFormAnswer, setIsFormAnswer] = useState(false)
    const [openIntroductionForm, setOpenIntroductionForm] = useState(false)
    const FormAnswerApi = useFormAnswerApi()
    const { componentState, setLoading, setError } = useComponentState()
    const [openReviewForm, setOpenReviewForm] = useState(false)
    const [valuesToSave, setValuesToSave] = useState({})

    useEffect(() => {
        if (initialValues?.formAnswerContainerId) {
            getAnswers()
        }
    }, [])

    const getAnswers = () => {
        setLoading(true)
        return FormAnswerApi.getFormAnswers(initialValues?.formAnswerContainerId)
            .then(res => {
                let done = true
                res?.forEach(a => {
                    if (!a?.value) {
                        done = false
                    }
                })
                if (!done) {
                    setIsFormAnswer(true)
                } else {
                    if (isFormAnswer) {
                        setIsFormAnswer(false)
                    }
                    
                }
            })
            .catch(setError)
            .finally(() => { setLoading(false) })
    }

    useEffect(() => {
        if (!openIntroductionForm && isFormAnswer) {
            getAnswers()
        }
        
    }, [openIntroductionForm])

    const onPrepareToSave = (values) => {
        if (initialValues?.name == "Asennus" && initialValues?.taskType == TaskTypes.todo() && !initialValues?.status) {
            setValuesToSave(values)
            setOpenReviewForm(true)
        } else {
            onSave(values)
        }
    }

    const saveStatus = (status) => {
        let values = { ...valuesToSave }
        values.status = status
        onSave(values)
    }

    return (
        <>
            <Grid container justify="center" >
                <Box my={2} >
                    <Typography variant="h4">{title}</Typography>
                </Box>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <CollapickForm
                        formSchema={answerSchema}
                        onFormSubmit={onPrepareToSave}
                        resetFormAfterSubmit={true}
                        initialValues={initialValues}
                        formElements={customElements}
                        renderSubmitButton={() =>
                            <Button
                                disabled={isFormAnswer ? true : false}
                                variant="contained"
                                type="submit"
                                color="primary"
                                fullWidth>
                                <ThumbUpIcon /><>&nbsp;&nbsp; &nbsp;</>{t('finish')}
                            </Button>}
                        showInvalidSaveButton={true}
                        invalidSubmitText={t("unfinish")}
                        onInvalidSubmit={onCancel}
                    />
                    {componentState.error ? <ApiError error={componentState.error} /> : componentState.loading ? 
                        <Spinner enabled={componentState.loading} /> : 
                        <Button 
                            variant="contained" 
                            fullWidth
                            color="inherit"
                            style={{ display: isFormAnswer ? "initial" : "none", backgroundColor: theme.palette.info.light }}
                            onClick={() => {
                                setOpenIntroductionForm(true)
                            }}
                        >{t('answer_to_instructions')}</Button>}
                </Grid>
                {openIntroductionForm && (
                    <ContentDialog
                        actions={
                            <IconButton color="primary" onClick={(() => { setOpenIntroductionForm(false) })} >
                                <Close />{t('close')}
                            </IconButton>}
                        title={t('answer_to_instroductions')}
                        open={openIntroductionForm}
                        setOpen={setOpenIntroductionForm}>
                        <PonnisteIframe
                            formAnswerContainerId={initialValues.formAnswerContainerId}
                        
                        />
                    </ContentDialog>
                )}
                {openReviewForm && (
                    <ContentDialog
                        actions={
                            <IconButton color="primary" onClick={(() => { setOpenReviewForm(false) })} >
                                <Close />{t('close')}
                            </IconButton>}
                        title={t('review_preparation')}
                        open={openReviewForm}
                        setOpen={setOpenReviewForm}>
                        <ReviewForm
                            saveStatus={saveStatus}
                        />
                    </ContentDialog>
                )}
            </Grid>
        </>
    )
}
export default AnswerForm