import React from 'react'
import useApi from '../useApi';


const useRoleMappingApi = () => {
    const { RoleMappingApi } = useApi()


    const deleteById = (id) => {
        return RoleMappingApi.deleteById(id)
            .then(result => {
                return result
            })
    }

    const find = (filter) => {
        return RoleMappingApi.find({ filter: JSON.stringify(filter) })
            .then(result => {
                return result
            })
    }

    const findAndCount = (filter) => {
        let Count = 0;
        return find(filter).then(result => {
            result.forEach(element => {
                Count++;
            });
            return Count;
        })
    }

    const create = (object) => {
        return RoleMappingApi.create(object)
    }

    const updateById = (id, filter) => {
        return RoleMappingApi.updateById(id, filter)
    }

    const findOne = (filter) => {
        return RoleMappingApi.findOne({ filter: JSON.stringify(filter) }).then(result => {
            return result;
        })
    }

    return {
        findAndCount,
        deleteById,
        find,
        create,
        updateById,
        findOne,
        api: RoleMappingApi
    }

}

export default useRoleMappingApi