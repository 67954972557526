import React from 'react'
import { Grid } from '@material-ui/core';
import { useUserContext } from '../../context/UserContext';
import { ponnisteUiUrl } from '../../Constants'

const PonnisteIframe = (props) => {
    const { formAnswerContainerId } = props
    const { token } = useUserContext()

    if (!formAnswerContainerId) {
        return <></>
    }

    let result = (
        <Grid style={{
            margin: 0
        }}>
            <iframe
                style={{
                    display: 'block', /* iframes are inline by default */
                    border: 'none', /* Reset default border */
                    height: '100vh', /* Viewport-relative units */
                    width: '100%'
                }}
                width="100%"
                height="100%"
                //src={"http://localhost:3001/form/answer/" + formAnswerContainerId + "/edit?access_token=" + token}
                src={ponnisteUiUrl + "/form/answer/" + formAnswerContainerId + "/edit?access_token=" + token}
            ></iframe>
        </Grid>

    )
    return result
}

PonnisteIframe.defaultProps = {
    formAnswerContainerId: ""
}

export default PonnisteIframe