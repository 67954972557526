import React from 'react'
import { CollapickForm } from '@collapick/form';
import { Typography, Grid, Box, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import useAddVideoSchema from '../../FormSchemas/useAddVideoSchema';

const ProjectSizeForm = (props) => {
    const { title, onSave, onCancel } = props
    const { t } = useTranslation()
    const { addVideoSchema } = useAddVideoSchema();

    return (
        <>
            <Grid container justify="center" >
                <Box my={2} >
                    <Typography variant="h2">{title}</Typography>
                </Box>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <CollapickForm
                        formSchema={addVideoSchema}
                        onFormSubmit={onSave}
                        resetFormAfterSubmit={true}
                        renderSubmitButton={() =>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                fullWidth>
                                {t('save')}
                            </Button>}
                        showInvalidSaveButton={true}
                        invalidSubmitText={t("cancel")}
                        onInvalidSubmit={onCancel}
                    />
                </Grid>
            </Grid>
        </>
    )
}

ProjectSizeForm.defaultProps = {
    title: "",
    onSave: console.log,
    onCancel: console.log
}

export default ProjectSizeForm