
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import useDynamicDataApi from '../../../../hooks/Apis/useDynamicDataApi'
import { useComponentState, ApiError, Spinner } from '@collapick/utils';
import ProjectSizesTable from '../../../../components/Table/ProjectSizesTable';
import { Typography, IconButton } from '@material-ui/core'
import ConfirmDialog from '../../../../components/Dialogs/ConfirmDialog'
import ContentDialog from '../../../../components/Dialogs/ContentDialog'
import CloseIcon from '@material-ui/icons/Close';
import ProjectSizeForm from '../../../../components/Form/ProjectSizeForm';

const ProjectSizes = () => {
    const { t } = useTranslation()
    const DynamicDataApi = useDynamicDataApi()
    const { componentState, setLoading, setError } = useComponentState()
    const [sizes, setSizes] = useState([])
    const [orinalSizes, setOriginalSizes] = useState([])
    const [initialValues, setInitialValues] = useState(null)
    const [openEditSize, setOpenEditSize] = useState(false)
    const [openAddSize, setOpenAddSize] = useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const title = t('project_sizes')
    const [index, setIndex] = useState(0)

    useEffect(() => {
        getSizes()
    }, [])

    const getSizes = () => {
        setLoading(true)
        return DynamicDataApi.getSizes()
            .then(res => {
                setOriginalSizes(res)
                let myArray = res.map((str, index) => ({ name: str }));
                setSizes(myArray)
            })
            .catch(setError)
            .finally(setLoading(false))
    }

    const editCallback = (e, rowData) => {
        let array = [...orinalSizes]
        setIndex(array.findIndex(i => i === rowData.name))
        setInitialValues(rowData)
        setOpenEditSize(true)
    }

    const addCallback = () => {
        setOpenAddSize(true)
    }

    const deleteCallback = (e, values) => {
        setInitialValues(values)
        setConfirmOpen(true)
    }

    const deleteSize = (values) => {
        let array = [...orinalSizes]
        let index = array.findIndex(i => i === values.name);
        array.splice(index, 1)
        setLoading(true)
        return DynamicDataApi.updateSize(array)
            .then(res => getSizes())
            .catch(setError)
            .finally(setLoading(false))
    }

    const onSaveAdd = (values) => {
        let array = [...orinalSizes]
        array.push(values.name)
        setLoading(true)
        DynamicDataApi.updateSize(array)
            .then(res => {
                setOpenAddSize(false)
                getSizes()
            })
            .catch(setError)
            .finally(setLoading(false))
    }

    const onSaveEdit = (values) => {
        let array = [...orinalSizes]
        array.splice(index, 1)
        array.push(values.name)
        setLoading(true)
        DynamicDataApi.updateSize(array)
            .then(res => {
                setOpenEditSize(false)
                getSizes()
            })
            .catch(setError)
            .finally(setLoading(false))
    }

    let result = null
    if (componentState.loading) {
        result = <Spinner enabled={componentState.loading} />
    } else if (componentState.error) {
        result = <ApiError error={componentState.error} />
    } else {
        result = (
            <>
                {sizes && <ProjectSizesTable
                    data={sizes}
                    editCallback={editCallback}
                    addCallback={addCallback}
                    deleteCallback={deleteCallback}
                    title={title}
                />}
                <ConfirmDialog
                    open={confirmOpen}
                    setOpen={setConfirmOpen}
                    title={t('remove_customer')}
                    content={initialValues?.name}
                    agreeCallback={() => {
                        setConfirmOpen(false);
                        deleteSize(initialValues);
                        setInitialValues(false)
                    }}
                    disagreeCallback={() => {
                        setConfirmOpen(false);
                        setInitialValues(false)
                    }} />
                <ContentDialog
                    actions={
                        <IconButton color="primary" onClick={(() => { setOpenAddSize(false) })} >
                            <CloseIcon />{t('close')}
                        </IconButton>}
                    title={t('add_size')}
                    open={openAddSize}
                    setOpen={setOpenAddSize}>
                    {openAddSize &&
                        <ProjectSizeForm
                            sizes={sizes}
                            onSave={onSaveAdd}
                            onCancel={() => setOpenAddSize(false)}
                            title={<Typography variant="h2">{t('add_new_size')}</Typography>}

                        />}
                </ContentDialog>
                <ContentDialog
                    actions={
                        <IconButton color="primary" onClick={(() => { setOpenEditSize(false) })} >
                            <CloseIcon />{t('close')}
                        </IconButton>}
                    title={t('edit_size')}
                    open={openEditSize}
                    setOpen={setOpenEditSize}>
                    {openEditSize &&
                        <ProjectSizeForm
                            sizes={sizes}
                            onSave={onSaveEdit}
                            onCancel={() => setOpenEditSize(false)}
                            initialValues={initialValues}
                            title={<Typography variant="h2">{t('edit_size')}</Typography>}

                        />}
                </ContentDialog>
            </>
        )
    }
    return result
}

export default ProjectSizes