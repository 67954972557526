import React from "react"
import Footer from "../components/Footer/Footer"
import Header from "../components/Header/MobileHeader"
import PageNotFound from "../components/PageNotFound/PageNotFound"
import { Redirect, Route, Switch } from "react-router-dom"
import useRoutes from "./useRoutes"
import { useUserContext } from "../context/UserContext"

/**
 * Render route with subroute.
 * @param {*} route
 */
const RouteWithSubroutes = (route) => {
    let provider = null
    if (route.provider) {
        provider = route.provider
    }
    return (
        <Route
            path={route.path}
            exact={route.exact}
            render={(props) => (
                <route.render
                    {...props}
                    provider={provider}
                    routes={route.routes}
                />
            )}
        />
    )
}

/**
 * Check before rendering if user is authenticated.
 * @param {*} props
 */
const PrivateRouteWithSubroutes = (props) => {
    const { isAuthenticated } = useUserContext()
    const { PATH } = useRoutes();
    console.info(
        "PrivateRouteWithSubroutes",
        "isAuthenticated: " + isAuthenticated
    )

    if (isAuthenticated == false) {
        //debugger;
    }

    return isAuthenticated ? (
        <RouteWithSubroutes {...props} />
    ) : (<Redirect to={PATH.login} />)
}

const RenderRoutes = ({ routes }) => {
    return (
        <Switch>
            {routes.map((route, i) => {
                return route.private === true ? (
                    <PrivateRouteWithSubroutes
                        key={route.path + i}
                        {...route}
                    />
                ) : (<RouteWithSubroutes key={route.path + i} {...route} />)
            })}
            <Route
                key="notfound"
                render={(props) => (
                    <>
                        <Header {...props} />
                        <PageNotFound {...props} />
                        <Footer {...props} />
                    </>
                )}
            ></Route>
        </Switch>
    )
}

/**
 * Renders private routes
 * @param {*} props
 */
const RenderPrivateRoutes = ({ routes }) => {
    return (
        <Switch>
            {routes.map((route, i) => {
                return <PrivateRouteWithSubroutes key={route.path} {...route} />
            })}
            <Route
                render={(props) => (
                    <>
                        <Header {...props} />
                        <PageNotFound {...props} />
                        <Footer {...props} />
                    </>
                )}
            ></Route>
        </Switch>
    )
}


const useAppRoutes = () => {

    return {
        RenderRoutes,
        RenderPrivateRoutes
    }
};

export default useAppRoutes;
