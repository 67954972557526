import React, { useState, useEffect } from 'react'
import useTaskApi from '../../../../hooks/Apis/useTaskApi'
import { useComponentState, ApiError, Spinner } from '@collapick/utils';
import { useHistory, useLocation } from 'react-router-dom';
import AnswerForm from '../../../../components/Form/AnswerForm';
import { Container } from '@material-ui/core';
import moment from 'moment'
import { useUserContext } from '../../../../context/UserContext'

const ProjectsAnswerFormView = (props) => {
    const { taskId } = props
    const TaskApi = useTaskApi()
    const { componentState, setLoading, setError } = useComponentState()
    const history = useHistory()
    const [task, setTask] = useState(null)
    const location = useLocation()
    const userContext = useUserContext()

    useEffect(() => {
        getTask()
    }, [location])

    const getTask = () => {
        setLoading(true)
        return TaskApi.getTask(taskId)
            .then(res => {
                let object = res[0]
                object.date = moment()
                setTask(object)
            })
            .catch(setError)
            .finally(() => {
                setLoading(false)

            })
    }

    const onSave = (values) => {
        let object = task
        object.files = values.files
        if (!values.time) {
            values.time = 0
        }
        object.duration += parseInt(values.time)
        object.done = true
        object.taskObject[values.date] = parseInt(values.time) + "h, valmis - " + userContext?.user?.username
        object.endDate = values.date
        if (values?.status) {
            object.status = values.status
        }
        delete object.date
        setLoading(true)
        return TaskApi.updateTask(object)
            .then(res => {
                return TaskApi.checkIfAllNotDone(task.parentTaskId)
            })
            .catch(setError)
            .finally(() => {
                setLoading(false)
                if (history.length > 0) {
                    history.goBack()
                } else {
                    history.push('/mobile/projects/todoview/' + [task.parentTaskId])
                }
            })
    }

    const onCancel = (values) => {
        let object = task
        object.files = values.files
        if (!values.time) {
            values.time = 0
        }
        object.duration += parseInt(values.time)
        object.done = false
        object.taskObject[values.date] = parseInt(values.time) + "h, kesken - " + userContext?.user?.username
        delete object.date
        setLoading(true)
        return TaskApi.updateTask(object)
            .then(res => {
                return TaskApi.checkIfAllDone(task.parentTaskId)
            })
            .catch(setError)
            .finally(() => {
                setLoading(false)
                if (history.length > 0) {
                    history.goBack()
                } else {
                    history.push('/mobile/projects/todoview/' + [task.parentTaskId])
                }
            })
    }

    let result = null
    if (componentState.loading) {
        result = <Spinner enabled={componentState.loading} />
    } else if (componentState.error) {
        result = <ApiError error={componentState.error} />
    } else {
        result = (
            <Container >
                <AnswerForm onSave={onSave} onCancel={onCancel} initialValues={task} title={task?.name} />
            </Container>
        )
    }
    return result
}

ProjectsAnswerFormView.defaultProps = {
    taskId: "e3350940-5b05-11eb-837a-83948b54c92f"
}

export default ProjectsAnswerFormView
