import useApi from '../useApi';
import { TaskTypes } from '@collapick/ponniste-types'
import {
    DAYS_BEFORE_MAIN_LEVEL,
    DAYS_BEFORE_PARENT_LEVEL,
    CUSTOM,
    SERVICE,
    PRODUCTION,
    PRODUCTION_DAYS_BEFORE_MAIN_LEVEL,
    PRODUCTION_DAYS_BEFORE_LEVEL_1,
    PRODUCTION_DAYS_BEFORE_LEVEL_2
} from '../../Constants'
import moment from 'moment';
import 'moment/locale/fi'
import { toJS } from 'mobx';

const useTaskApi = () => {
    const TaskApi = useApi().TaskApi

    const getTask = (taskId) => {
        let query = {
            where: {
                uuid: taskId
            },
            include: [
                {
                    relation: "childTasks",
                    scope: {
                        include: [
                            {
                                relation: "childTasks",
                                scope: {
                                    include: {
                                        relation: "childTasks",
                                        scope: {
                                            include: {
                                                relation: "childTasks"
                                            }
                                        }
                                    }
                                }
                            },
                            {
                                relation: "parentTask",
                                scope: {
                                    include: {
                                        relation: "parentTask",
                                        scope: {
                                            include: {
                                                relation: "parentTask"
                                            }
                                        }
                                    }
                                }
                            }
                        ]
                    },
                },
                {
                    relation: "parentTask",
                    scope: {
                        include: {
                            relation: "parentTask",
                            scope: {
                                include: {
                                    relation: "parentTask"
                                }
                            }
                        }
                    }
                }]
        }
        return TaskApi.find({ filter: JSON.stringify(query) })
            .then(res => {
                return res
            })
    }

    const getWork = (taskId) => {
        let query = {
            where: {
                uuid: taskId
            },
            include:
            {
                relation: "childTasks",
            },
        }
        return TaskApi.find({ filter: JSON.stringify(query) })
            .then(res => {
                return res
            })
    }

    const getAllTasks = (params, columns, showComplited, mode) => {
        let count = 0
        let query = {
            where: {
                and: [
                    { taskType: TaskTypes.task() },
                    {
                        or: []
                    }],
            },
            include: {
                relation: "childTasks",
                scope: {
                    include: {
                        relation: "childTasks",
                        scope: {
                            include: {
                                relation: "childTasks"
                            }
                        }
                    }
                }
            },
            skip: params.pageSize * params.page,
            limit: params.pageSize,
            order: "CREATED DESC"
        }

        if (mode == "services") {
            query.where.and.push({ done: true })
        } else if (mode == "projects" || !showComplited) {
            query.where.and.push({ done: false })
        }

        if (params.filters.length > 0) {
            params.filters.forEach(f => {
                query.where.and[1].or[f.column.field] = { like: "%" + f.value + '%' }
            })
        }
        if (params.orderBy) {
            if (params.orderBy.field !== "week") {
                query.order = params.orderBy.field + " " + params.orderDirection;
            } else {
                query.order = "dueToDate" + " " + params.orderDirection;
            }

        }
        if (params.search && columns) {
            columns.forEach(c => {
                if (c.field.match(/^taskObject.*$/) && c?.filtering) {
                    let columnName = c.field.replace("taskObject.", "")
                    query.where.and[1].or.push({
                        taskObject: {
                            json: {
                                [columnName]: { like: "%" + params.search + "%" }
                            }
                        }
                    })
                }
                else {
                    if (c?.filtering) {
                        if (c.field !== "week") {
                            query.where.and[1].or.push({ [c.field]: { like: "%" + params.search + '%' } })
                        } else {
                            let week = parseInt(params.search)
                            if (!isNaN(week)) {
                                let firstDay = moment().locale('fi').day("maanantai").week(week);
                                let lastDay = moment().locale('fi').day("sunnuntai").week(week);
                                query.where.and[1].or.push({
                                    dueToDate: {
                                        between: [firstDay, lastDay]
                                    }
                                })
                            }
                        }
                    }
                }
            })
        }

        return TaskApi.count({ where: JSON.stringify(query.where) })
            .then(res => {
                count = res.count
                return TaskApi.find({ filter: JSON.stringify(query) })
            })
            .then(res => {
                res.count = count
                return res
            })
    }

    const getAllTasksInWeeks = (params, columns, showComplited) => {
        let count = 0
        let query = {
            where: {
                and: [
                    //{ taskType: TaskTypes.task() },
                    { done: false },
                    { dueToDate: { neq: false } },
                    { taskType: { neq: TaskTypes.task() } },
                    {
                        or: []
                    }],
            },
            include: {
                relation: "parentTask",
                scope: {
                    include: {
                        relation: "parentTask",
                        scope: {
                            include: {
                                relation: "parentTask"
                            }
                        }
                    }
                }
            },
            skip: params.pageSize * params.page,
            limit: params.pageSize,
            order: "dueToDate ASC"
        }

        if (params.filters.length > 0) {
            params.filters.forEach(f => {
                query.where.and[3].or[f.column.field] = { like: "%" + f.value + '%' }
            })
        }
        if (params.orderBy) {
            if (params.orderBy.field !== "week") {
                query.order = params.orderBy.field + " " + params.orderDirection;
            } else {
                query.order = "dueToDate" + " " + params.orderDirection;
            }

        }
        if (params.search && columns) {
            columns.forEach(c => {
                if (c.field.match(/^taskObject.*$/) && c?.filtering) {
                    let columnName = c.field.replace("taskObject.", "")
                    query.where.and[3].or.push({
                        taskObject: {
                            json: {
                                [columnName]: { like: "%" + params.search + "%" }
                            }
                        }
                    })
                }
                else {
                    if (c?.filtering) {
                        if (c.field !== "week") {
                            query.where.and[3].or.push({ [c.field]: { like: "%" + params.search + '%' } })
                        } else {
                            let week = parseInt(params.search)
                            if (!isNaN(week)) {
                                let firstDay = moment().locale('fi').day("maanantai").week(week);
                                let lastDay = moment().locale('fi').day("sunnuntai").week(week);
                                query.where.and[3].or.push({
                                    dueToDate: {
                                        between: [firstDay, lastDay]
                                    }
                                })
                            }
                        }
                    }
                }
            })
        }
        if (!showComplited) {
            query.where.and.push({ dueToDate: { gt: moment() } })
        }
        return TaskApi.count({ where: JSON.stringify(query.where) })
            .then(res => {
                count = res.count
                return TaskApi.find({ filter: JSON.stringify(query) })
            })
            .then(res => {
                res.count = count
                return res
            })
    }


    const getAllChildTasks = (taskId) => {
        let query = {
            where: {
                parentTaskId: taskId
            }
        }
        return TaskApi.find({ filter: JSON.stringify(query) })
            .then(res => {
                return res
            })
    }

    const getDoneTasks = (task) => {
        let query = {
            where: {
                parentTaskId: task.uuid,
            },
            include: {
                relation: "childTasks",
                scope: {
                    include: {
                        relation: "childTasks"
                    }
                }
            }
        }
        return TaskApi.find({ filter: JSON.stringify(query) })
            .then(res => {
                let totalCount = 0
                let doneCount = 0
                if (task.taskType == TaskTypes.task()) {
                    res.forEach(task => {
                        task.childTasks.forEach(childTask => {
                            childTask.childTasks.forEach(innerChild => {
                                totalCount++
                                if (innerChild.done) {
                                    doneCount++
                                }
                            })
                        })
                    })
                } else if (task.taskType == TaskTypes.common()) {
                    res.forEach(task => {
                        task.childTasks.forEach(childTask => {
                            totalCount++
                            if (childTask.done) {
                                doneCount++
                            }
                        })
                    })
                } else if (task.taskType == TaskTypes.progress()) {
                    res.forEach(task => {
                        task.childTasks.forEach(childTask => {
                            totalCount++
                            if (childTask.done) {
                                doneCount++
                            }
                        })
                    })
                } else if (task.taskType == TaskTypes.commonToDo()) {
                    res.forEach(task => {
                        totalCount++
                        if (task.done) {
                            doneCount++
                        }
                    })
                }

                let count = {
                    totalCount: totalCount,
                    doneCount: doneCount
                }

                return count
            })
    }

    const getParentTask = (parentTaskId) => {
        let query = {
            where: {
                uuid: parentTaskId
            }
        }
        return TaskApi.find({ filter: JSON.stringify(query) })
            .then(res => {
                return res
            })
    }

    const getUrgentTasks = (params, type, values) => {
        //let oneDay = (24 * 60 * 60 * 1000)
        let count = 0
        let query = {
            where: {
                and: [
                    {
                        or: [
                            { taskType: TaskTypes.commonToDo() },
                            { taskType: TaskTypes.todo() },
                        ]
                    },
                    { done: false },
                    {
                        dueToDate: {
                            neq: null,
                            //    lt: Date.now() + (URGENT_DAYS * oneDay)
                        }
                    },
                ]

            },
            include: {
                relation: "parentTask",
                scope: {
                    include: {
                        relation: "parentTask",
                        scope: {
                            include: {
                                relation: "parentTask"
                            }
                        }
                    }
                }
            },
            skip: params.pageSize * params.page,
            limit: params.pageSize,
            order: "dueToDate ASC"
        }

        if (params.filters.length > 0) {
            params.filters.forEach(f => {
                if (f.column.field.match(/^taskObject.*$/)) {
                    let columnName = f.column.field.replace("taskObject.", "")
                    query.where = {
                        taskObject: {
                            json: {
                                [columnName]: { like: "%" + f.value + "%" }
                            }
                        }
                    }
                }
                else {
                    query.where[f.column.field] = { like: "%" + f.value + '%' }
                }
            })
        }

        if (params.orderBy) {
            query.order = params.orderBy.field + " " + params.orderDirection;
        }

        if (type) {
            query.where.helperBaseName = type
            if (type == PRODUCTION) {
                query.where.helperBaseName = { inq: [PRODUCTION, CUSTOM] }
                if (values && values.includes("Työt")) {
                    query.where.helperBaseName = CUSTOM
                } else if (values) {
                    query.where.helperBaseName = PRODUCTION
                }
            }
        }

        if (values && values?.length > 0 && !values?.includes("Työt")) {
            delete query.limit
            delete query.skip
        } else {
            if (query.where.name) {
                delete query.where.name
            }
        }
        return TaskApi.count({ where: JSON.stringify(query.where) })
            .then(res => {
                count = res.count
                return TaskApi.find({ filter: JSON.stringify(query) })
            })
            .then(res => {
                res.count = count
                let array = []
                if (values && !values.includes("Työt")) {
                    res.forEach(t => {
                        if (values.includes(t.name) || values.includes(t.parentTask.name)) {
                            array.push(t)
                        }

                    })

                    const paginate = (array, page_size, page_number) => {
                        return array.slice(page_number * page_size, page_number * page_size + page_size);
                    };


                    let pages = paginate(array, params.pageSize, params.page)
                    pages.count = array.length
                    res = pages
                }
                return res
            })
    }

    const getUrgentTasksForProject = (taskId) => {
        //let oneDay = (24 * 60 * 60 * 1000)
        let query = {
            where: {
                and: [
                    { helperBaseName: { neq: CUSTOM } },
                    {
                        or: [
                            { taskType: TaskTypes.commonToDo() },
                            { taskType: TaskTypes.todo() },
                        ]
                    },
                    { done: false },
                    {
                        dueToDate: {
                            neq: null,
                            //    lt: Date.now() + (URGENT_DAYS * oneDay)
                        }
                    },
                ]

            },
            include: {
                relation: "parentTask",
                scope: {
                    include: {
                        relation: "parentTask",
                        scope: {
                            include: {
                                relation: "parentTask"
                            }
                        }
                    }
                }
            },
            order: "dueToDate ASC"
        }
        return TaskApi.find({ filter: JSON.stringify(query) })
            .then(res => {
                let array = []
                res.forEach((task, i) => {
                    if (task.taskType == TaskTypes.commonToDo()) {
                        if (task?.parentTask?.parentTask?.uuid === taskId) {
                            array.push(task)
                        }
                    } else if (task.taskType == TaskTypes.todo()) {
                        if (task?.parentTask?.parentTask?.parentTask?.uuid === taskId) {
                            array.push(task)
                        }
                    }

                })
                return array
            })
    }

    const updateTask = (object, daysToMove) => {
        let production = false
        return TaskApi.updateById(object.uuid, object)
            .then(res => {
                let result = null
                if ((object.taskType == TaskTypes.common() && object.helperBaseName !== PRODUCTION) ||
                    (object.taskType == TaskTypes.commonToDo() && object.helperBaseName === PRODUCTION)) {
                    let query = {
                        where: {
                            parentTaskId: object.uuid
                        },
                        include: {
                            relation: "childTasks",
                            scope: {
                                include: {
                                    relation: "childTasks"
                                }
                            }
                        }
                    }
                    result = TaskApi.find({ filter: JSON.stringify(query) })
                }
                if (object.taskType == TaskTypes.commonToDo() && object.helperBaseName === PRODUCTION) {
                    production = true
                }
                return result
            })
            .then(res => {
                let result = null
                let promises = []
                if (res?.length > 0 && !production) {
                    res.reverse().forEach((t, i) => {
                        if (!daysToMove) {
                            if (!t?.dueToDate) {
                                if (i > 0) {
                                    t.dueToDate = moment(object.dueToDate)
                                        .subtract(DAYS_BEFORE_MAIN_LEVEL + (i * DAYS_BEFORE_PARENT_LEVEL), 'days');
                                    if (t?.childTasks?.length > 0) {
                                        t.childTasks.forEach(cT => {
                                            if (!cT?.dueToDate) {
                                                cT.dueToDate = moment(object.dueToDate)
                                                    .subtract(DAYS_BEFORE_MAIN_LEVEL + (i * DAYS_BEFORE_PARENT_LEVEL), 'days');
                                                promises.push(TaskApi.updateById(cT.uuid, cT))
                                            }

                                        })
                                    }
                                } else {
                                    t.dueToDate = moment(object.dueToDate)
                                        .subtract(DAYS_BEFORE_MAIN_LEVEL, 'days');
                                    if (t?.childTasks?.length > 0) {
                                        t.childTasks.forEach(cT => {
                                            if (!cT?.dueToDate) {
                                                cT.dueToDate = moment(object.dueToDate)
                                                    .subtract(DAYS_BEFORE_MAIN_LEVEL, 'days');
                                                promises.push(TaskApi.updateById(cT.uuid, cT))
                                            }
                                        })
                                    }
                                }
                                promises.push(TaskApi.updateById(t.uuid, t))
                            }
                        } else {
                            t.dueToDate = moment(t.dueToDate).add(daysToMove, 'days');
                            if (t?.childTasks?.length > 0) {
                                t.childTasks.forEach(cT => {
                                    cT.dueToDate = moment(cT.dueToDate).add(daysToMove, 'days');
                                    promises.push(TaskApi.updateById(cT.uuid, cT))
                                })
                            }
                            promises.push(TaskApi.updateById(t.uuid, t))
                        }
                    })
                    result = Promise.all(promises)
                } else if (res?.length > 0 && production) {
                    res.reverse().forEach((t, i) => {
                        if (!daysToMove) {
                            if (!t?.dueToDate) {
                                if (i == 0) {
                                    t.dueToDate = moment(object.dueToDate)
                                        .subtract(PRODUCTION_DAYS_BEFORE_MAIN_LEVEL, 'days');
                                } else if (i == 1) {
                                    t.dueToDate = moment(object.dueToDate)
                                        .subtract(PRODUCTION_DAYS_BEFORE_LEVEL_1, 'days');
                                } else {
                                    t.dueToDate = moment(object.dueToDate)
                                        .subtract(PRODUCTION_DAYS_BEFORE_LEVEL_2, 'days');
                                }
                                promises.push(TaskApi.updateById(t.uuid, t))
                            }
                        } else {
                            t.dueToDate = moment(t.dueToDate).add(daysToMove, 'days');
                            promises.push(TaskApi.updateById(t.uuid, t))
                        }
                    })
                    result = Promise.all(promises)
                }
                return result
            })
    }

    const getTasksUsedDuration = (task) => {
        let query = {
            where: {
                parentTaskId: task.uuid,
            },
            include: {
                relation: "childTasks",
                scope: {
                    include: {
                        relation: "childTasks"
                    }
                }
            }
        }
        return TaskApi.find({ filter: JSON.stringify(query) })
            .then(res => {
                let duration = 0
                if (task.taskType == TaskTypes.task()) {
                    res.forEach(task => {
                        task.childTasks.forEach(childTask => {
                            childTask.childTasks.forEach(innerChild => {
                                duration += innerChild.duration
                            })
                        })
                    })
                } else if (task.taskType == TaskTypes.common()) {
                    res.forEach(task => {
                        task.childTasks.forEach(childTask => {
                            duration += childTask.duration
                        })
                    })
                } else if (task.taskType == TaskTypes.commonToDo()) {
                    res.forEach(task => {
                        duration += task.duration
                    })
                } else if (task.taskType == TaskTypes.todo()) {
                    duration = task.duration
                }
                return duration
            })
    }

    const checkIfAllNotDone = (parentTaskId) => {
        let parentTask = ""
        let query = {
            where: {
                parentTaskId: parentTaskId
            },
            include: {
                relation: "parentTask",
                scope: {
                    include: {
                        relation: "parentTask",
                    }
                }
            },
        }
        return TaskApi.find({ filter: JSON.stringify(query) })
            .then(res => {
                console.log(res)
                let result = null
                let notDone = false
                res.forEach(task => {
                    parentTask = task
                    if (task.done != true) {
                        notDone = true
                    }
                })
                if (notDone == false) {
                    let object = {
                        endDate: moment(),
                        done: true
                    }
                    result = TaskApi.updateById(parentTaskId, object)
                }
                return result
            })
            .then(res => {
                let result = null
                if (res) {
                    let query = {
                        where: {
                            uuid: res.parentTaskId
                        },
                        include: {
                            relation: "childTasks"
                        }
                    }
                    result = TaskApi.find({ filter: JSON.stringify(query) })
                }
                return result
            })
            .then(res => {
                let result = null
                let done = true
                let object = {}
                if (res?.length > 0) {
                    res.forEach(task => {
                        object = task
                        task.childTasks.forEach(cT => {
                            if (cT.done != true) {
                                done = false
                            }
                        })
                    })
                    if (done == true) {
                        object.endDate = moment()
                        object.done = true
                        result = TaskApi.updateById(object.uuid, object)
                    }
                }

                return result
            })
            .then(res => {
                let result = null
                if (res) {
                    let query = {
                        where: {
                            uuid: res.parentTaskId
                        },
                        include: {
                            relation: "childTasks"
                        }
                    }
                    result = TaskApi.find({ filter: JSON.stringify(query) })
                }
                return result
            })
            .then(res => {
                let result = null
                let done = true
                let object = {}
                parentTask = null
                if (!res?.length > 0) {
                    return
                }
                res.forEach(task => {
                    if (task.parentTaskId) {
                        parentTask = task.parentTaskId
                    }
                    object = task
                    task.childTasks.forEach(cT => {
                        if (cT.done != true && (cT.helperBaseName != SERVICE || cT.taskType != TaskTypes.common())) {
                            done = false
                        }
                    })
                })
                if (done == true) {
                    object.endDate = moment()
                    object.done = true
                    result = TaskApi.updateById(object.uuid, object)
                }
                
                return result
            })
            .then(res => {
                if (!parentTask) {
                    return
                }
                let query = {
                    where: {
                        uuid: parentTask
                    },
                    include: {
                        relation: "childTasks"
                    }
                }
                return TaskApi.find({ filter: JSON.stringify(query) })
            })
            .then(res => {
                if (!res) {
                    return
                }
                let result = null
                let done = true
                let object = {}
                res.forEach(task => {
                    if (task.parentTaskId) {
                        parentTask = task.parentTaskId
                    }
                    object = task
                    task.childTasks.forEach(cT => {
                        if (cT.done != true && (cT.helperBaseName != SERVICE || cT.taskType != TaskTypes.common())) {
                            done = false
                        }
                    })
                })
                if (done == true) {
                    object.endDate = moment()
                    object.done = true
                    result = TaskApi.updateById(object.uuid, object)
                }
                return result
            })
    }

    const checkIfAllDone = (parentTaskId) => {
        let parentTask = ""
        let service = false
        let query = {
            where: {
                parentTaskId: parentTaskId
            },
            include: {
                relation: "parentTask",
                scope: {
                    include: {
                        relation: "parentTask",
                    }
                }
            },
        }
        return TaskApi.find({ filter: JSON.stringify(query) })
            .then(res => {
                let result = null
                let done = false
                res.forEach(task => {
                    parentTask = task
                    if (task.done == true) {
                        done = true
                    }
                })
                if (done == true) {
                    let object = {
                        endDate: null,
                        done: false
                    }
                    result = TaskApi.updateById(parentTaskId, object)
                }
                return result
            })
            .then(res => {
                let result = null
                if (res) {
                    let query = {
                        where: {
                            uuid: res.parentTaskId
                        }
                    }
                    result = TaskApi.find({ filter: JSON.stringify(query) })
                }
                return result
            })
            .then(res => {
                let result = null
                let object = {}
                if (res) {
                    res.forEach(task => {
                        object = task
                    })
                    if (object.done == true) {
                        object.endDate = null
                        object.done = false
                        result = TaskApi.updateById(object.uuid, object)
                    }
                    if ((object.helperBaseName == SERVICE && object.taskType == TaskTypes.common())) {
                        service = true
                    }
                }
                return result
            })
            .then(res => {
                let result = null
                if (res) {
                    let query = {
                        where: {
                            uuid: res.parentTaskId
                        }
                    }
                    result = TaskApi.find({ filter: JSON.stringify(query) })
                }
                return result
            })
            .then(res => {
                let result = null
                let object = {}
                if (res) {
                    res.forEach(task => {
                        object = task
                    })
                    if (object.done == true && service == false) {
                        object.endDate = null
                        object.done = false
                        result = TaskApi.updateById(object.uuid, object)
                    }
                }
                return result
            })
    }

    const getLevel = (taskId) => {
        let query = {
            where: {
                uuid: taskId
            },
            include: {
                relation: "parentTask",
                scope: {
                    include: {
                        relation: "parentTask",
                        scope: {
                            include: {
                                relation: "parentTask"
                            }
                        }
                    }
                }
            },
        }
        return TaskApi.find({ filter: JSON.stringify(query) })
            .then(res => {
                let task = []
                if (res.length > 0) {
                    task = res[0]
                }
                let project = {
                    title: "",
                    text: ""
                }
                if (task?.taskType == TaskTypes.task()) {
                    project.title = task.name
                } else if (task?.taskType == TaskTypes.common()) {
                    project.title = task.parentTask.name
                    project.text = task.name
                } else if (task?.taskType == TaskTypes.commonToDo() && task?.helperBaseName != CUSTOM) {
                    project.title = task.parentTask.parentTask.name
                    project.text = task.parentTask.name + " - " + task.name
                } else if (task?.taskType == TaskTypes.commonToDo() && task?.helperBaseName == CUSTOM) {
                    project.title = task.name
                    //     project.text = task.parentTask.name + " - " + task.name
                } else if (task?.taskType == TaskTypes.todo() && task?.helperBaseName != CUSTOM) {
                    project.title = task.parentTask.parentTask.parentTask.name
                    project.text = task.parentTask.parentTask.name + " - "
                        + task.parentTask.name
                } else if (task?.taskType == TaskTypes.todo() && task?.helperBaseName == CUSTOM) {
                    project.title = task.parentTask.name
                    project.text = task.name
                } else if (task?.taskType == TaskTypes.progress()) {
                    project.title = task.parentTask.parentTask.name
                    project.text = task.parentTask.name + " - " + task.name
                }
                return project
            })
    }

    const deleteProject = (project) => {
        let promises = []
        project.childTasks.forEach(c1 => {
            c1.childTasks.forEach(c2 => {
                c2.childTasks.forEach(c3 => {
                    promises.push(TaskApi.deleteById(c3.uuid))
                })
                promises.push(TaskApi.deleteById(c2.uuid))
            })
            promises.push(TaskApi.deleteById(c1.uuid))
        })
        promises.push(TaskApi.deleteById(project.uuid))
        return Promise.all(promises)
    }

    const getWorkHours = (params, values) => {
        let count = 0
        let query = {
            where: {
                and: [
                    { taskType: TaskTypes.task() },
                    {
                        or: []

                    }],
            },
            include: {
                relation: "childTasks",
                scope: {
                    include:
                    {
                        relation: "childTasks",
                        scope: {
                            include: {
                                relation: "childTasks",
                                scope: {
                                    include: [{
                                        relation: "parentTask"
                                    },
                                    {
                                        relation: "childTasks",
                                        scope: { include: {
                                            relation: "parentTask",
                                        }
                                        }
                                    },
                                    ]
                                }
                            }
                        }
                    },
                },
            },
            skip: params.pageSize * params.page,
            limit: params.pageSize,
            order: "CREATED DESC"
        }
        if (values) {
            if (values?.size?.length > 0) {
                query.where.and.push({ unit: { inq: values.size } })
            }
        }

        if (params.filters.length > 0) {
            params.filters.forEach(f => {
                query.where.and[1].or[f.column.field] = { like: "%" + f.value + '%' }
            })
        }
        if (params.orderBy) {
            if (params.orderBy.field !== "week") {
                query.order = params.orderBy.field + " " + params.orderDirection;
            } else {
                query.order = "dueToDate" + " " + params.orderDirection;
            }

        }

        return TaskApi.count({ where: JSON.stringify(query.where) })
            .then(res => {
                count = res.count
                return TaskApi.find({ filter: JSON.stringify(query) })
            })
            .then(res => {
                res.count = count
                return res
            })
    }

    //New feature 5.5.2021
    const getAllWorks = (params, columns, showComplited) => {
        let count = 0
        let query = {
            where: {
                and: [
                    { taskType: TaskTypes.commonToDo() },
                    { helperBaseName: CUSTOM },
                    {
                        or: []

                    }],
            },
            include: {
                relation: "childTasks"
            },
            skip: params.pageSize * params.page,
            limit: params.pageSize,
            order: "CREATED DESC"
        }
        if (!showComplited) {
            query.where.and.push({ done: false })
        }

        if (params.filters.length > 0) {
            params.filters.forEach(f => {
                query.where.and[2].or[f.column.field] = { like: "%" + f.value + '%' }
            })
        }
        if (params.orderBy) {
            if (params.orderBy.field !== "week") {
                query.order = params.orderBy.field + " " + params.orderDirection;
            } else {
                query.order = "dueToDate" + " " + params.orderDirection;
            }

        }
        if (params.search && columns) {
            columns.forEach(c => {
                if (c.field.match(/^taskObject.*$/) && c?.filtering) {
                    let columnName = c.field.replace("taskObject.", "")
                    query.where.and[2].or.push({
                        taskObject: {
                            json: {
                                [columnName]: { like: "%" + params.search + "%" }
                            }
                        }
                    })
                }
                else {
                    if (c?.filtering) {
                        if (c.field !== "week") {
                            query.where.and[2].or.push({ [c.field]: { like: "%" + params.search + '%' } })
                        } else {
                            let week = parseInt(params.search)
                            if (!isNaN(week)) {
                                let firstDay = moment().locale('fi').day("maanantai").week(week);
                                let lastDay = moment().locale('fi').day("sunnuntai").week(week);
                                query.where.and[2].or.push({
                                    dueToDate: {
                                        between: [firstDay, lastDay]
                                    }
                                })
                            }
                        }
                    }
                }
            })
        }

        return TaskApi.count({ where: JSON.stringify(query.where) })
            .then(res => {
                count = res.count
                return TaskApi.find({ filter: JSON.stringify(query) })
            })
            .then(res => {
                res.count = count
                return res
            })
    }

    const deleteWork = (project) => {
        let promises = []
        project.childTasks.forEach(c1 => {
            promises.push(TaskApi.deleteById(c1.uuid))
        })
        promises.push(TaskApi.deleteById(project.uuid))
        return Promise.all(promises)
    }

    const getDefaultAttachments = (filter) => {
        let query = {
            where: {
                taskType: TaskTypes.notification()
            },
            order: "CREATED DESC"
        }
        if (filter) {
            query = filter
        }
        return TaskApi.find({ filter: JSON.stringify(query) })
    }

    const createDefaultAttachments = (values) => {
        let object = {
            name: values.name,
            files: values.files,
            taskType: TaskTypes.notification()
        }
        return TaskApi.create(object)
    }

    const changeWeek = (oldValues, daysToMove) => {
        let newDate = moment(oldValues.dueToDate).add(daysToMove, 'days');
        return TaskApi.updateById(oldValues.uuid, { dueToDate: newDate })
            .then(res => {
                let promises = []
                const checkChildTasks = (task) => {
                    if (task.dueToDate) {
                        let newDate = moment(task.dueToDate).add(daysToMove, 'days');
                        promises.push(TaskApi.updateById(task.uuid, { dueToDate: newDate }))
                    }
                    if (task.childTasks) {
                        task.childTasks.forEach(t => {
                            checkChildTasks(t)
                        })
                    } else {
                        return
                    }
                }
                oldValues.childTasks.forEach(t => {
                    checkChildTasks(t)
                })
                return Promise.all(promises)
            })
    }

    const getParentTaskWithChildUuid = (uuid) => {
        let query = {
            where: {
                uuid: uuid
            },
            include:
            {
                relation: "parentTask"
            }
        }
        return TaskApi.find({ filter: JSON.stringify(query) })
    }

    return {
        getTask,
        getAllTasks,
        getAllChildTasks,
        getDoneTasks,
        getParentTask,
        getUrgentTasks,
        getUrgentTasksForProject,
        updateTask,
        getTasksUsedDuration,
        checkIfAllDone,
        checkIfAllNotDone,
        getLevel,
        deleteProject,
        getWorkHours,
        getAllWorks,
        deleteWork,
        getWork,
        getDefaultAttachments,
        createDefaultAttachments,
        changeWeek,
        getAllTasksInWeeks,
        getParentTaskWithChildUuid,
        useApi: TaskApi
    }
}

export default useTaskApi