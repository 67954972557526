import React from 'react'
import useApi from '../useApi';

const useRoleApi = () => {
    const { RoleApi } = useApi()

    const deleteById = (id) => {
        return RoleApi.deleteById(id).then(result => {
            return result
        })
    }

    const find = (filter) => {
        return RoleApi.find({ filter: JSON.stringify(filter) }).then(result => {
            return result
        })
    }

    const findAndCount = (filter) => {
        let Count = 0;
        return find(filter).then(result => {
            result.forEach(element => {
                Count++;
            });

            return Count;
        })
    }

    const create = (object) => {
        return RoleApi.create(object)
    }

    const findIdByName = (name) => {
        return RoleApi.findOne({ filter: JSON.stringify({ where: { name: name } }) }).then(result => {
            return result
        })
    }

    return {
        findAndCount,
        deleteById,
        find,
        create,
        findIdByName,
        api: RoleApi
    }

}

export default useRoleApi