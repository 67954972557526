import React, { useState } from 'react'
import { Typography, Grid, Button, Paper, IconButton, Badge } from '@material-ui/core';
import { theme } from '../../hooks/theme/useAppTheme'
import { makeStyles, fade } from "@material-ui/core/styles"
import { PRODUCTION, SERVICE, SALE, CUSTOM } from '../../Constants'
import { TaskTypes } from '@collapick/ponniste-types';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import BuildIcon from '@material-ui/icons/Build';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import WorkIcon from '@material-ui/icons/Work';
import useTaskApi from '../../hooks/Apis/useTaskApi';
import { useTranslation } from 'react-i18next';
import UserContext, { useUserContext } from '../../context/UserContext';
import ContentDialog from '../Dialogs/ContentDialog';
import Close from '@material-ui/icons/Close';
import SafetyIntroductionDialog from './SafetyIntroductionDialog';

const useStyles = makeStyles((t) => (
    {
        root: props => ({
            backgroundColor: props.color,
            paddingTop: "2.5vh",
            paddingBottom: "2.5vh",
            alignSelf: "center"
        }),
        button: {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.info.contrastText,
            textTransform: 'none',
            '&:hover': {
                backgroundColor: fade(theme.palette.info.main, 0.5),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
                '&$disabled': {
                    backgroundColor: 'transparent',
                },
            },
            '&$disabled': {
                color: theme.palette.action.disabled,
            }
        },
        safetyButton: {
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.info.contrastText,
            border: "1px solid black",
            "-webkit-animation": "$safetyButton 1s step-end infinite",    
            animation: "$safetyButton 1s step-end infinite"    
        },
        row: {
            display: "flex",
            textAlign: "center",
            alignSelf: "center",
            padding: "0.5vh",
            justifyContent: "space-around"
        },
        typography: {
            color: theme.palette.info.contrastText
        },
        disabledIcon: {

        },
        "@-webkit-keyframes safetyButton": {
            "from, to": {
                borderColor: "transparent",
                color: theme.palette.info.contrastText
            },
            "50%": {
                borderColor: "black",
                color: "black"
            }
        },
        "@keyframes safetyButton": {
            "from, to": {
                borderColor: "transparent",
                color: theme.palette.info.contrastText
            },
            "50%": {
                borderColor: "black",
                color: "black"
            }
        },
    }))

const getGrandParent = (task) => {
    if (task.parentTask) {
        return getGrandParent(task.parentTask)
    } else {
        return task
    }

}

const LevelNavigation = (props) => {
    const { task, moveToLevel, setQuantity, confirmSafety } = props
    const { t } = useTranslation()
    const { user, token } = useUserContext()
    const [openDialog, setOpenDialog] = useState(false)

    let safetyConfirmed = false
    let grandParent = getGrandParent(task)
    let usersConfirmed = grandParent?.description

    try {
        usersConfirmed = JSON.parse(usersConfirmed)
    } catch (err) {
        usersConfirmed = []
    }

    if (usersConfirmed && Array.isArray(usersConfirmed) && user?.uuid && usersConfirmed?.includes(user?.uuid)) {
        safetyConfirmed = true
    }

    /*if (grandParent.helperBaseName == CUSTOM) {
        safetyConfirmed = true
    }*/


    let color = null
    if (task?.helperBaseName == PRODUCTION) {
        color = theme.palette.primary.light
    } else if (task?.helperBaseName == SALE) {
        color = theme.palette.success.light
    } else if (task?.helperBaseName == SERVICE) {
        color = theme.palette.secondary.light
    } else {
        color = theme.palette.primary.light
    }

    const classes = useStyles({ color })

    let totalCount = task.childTasks.length

    let result = null
    if (task.taskType == TaskTypes.commonToDo()) {
        totalCount = 1
        let doneIndex = -1
        result = (
            <>
            <Grid container className={classes.root}>
                {!safetyConfirmed && 
                <Grid item xs={12} key={"safety_introduction"} className={classes.row} style={{ marginBottom: "20px" }}>
                    <Button
                        className={classes.safetyButton}
                        fullWidth
                        onClick={() => {
                            setOpenDialog(true) 
                        }}
                    >{t("confirm_safety_introduction")}</Button>
                </Grid>}
                {task.childTasks.map((cTask, i) => {
                    let doneCount = 0
                    let disabled = true

                    if (cTask.done == true) {
                        doneCount++
                        doneIndex = i
                    }
                    if (i == 0 || i <= doneIndex + 1) {
                        disabled = false
                    }

                    if (!safetyConfirmed) {
                        disabled = true
                    }

                    let color = null
                    if (doneCount == totalCount) {
                        color = theme.palette.success.dark
                    } else if (doneCount < totalCount && doneCount > 0) {
                        color = theme.palette.warning.light
                    } else {
                        color = theme.palette.error.main
                    }
                    return (
                        <>
                            {task.helperBaseName == "production" && i == 0 && 
                        <Grid item xs={12} key={cTask.uuid} className={classes.row} style={{ marginBottom: "20px" }}>
                            <IconButton color="primary" onClick={() => { setQuantity("quantity") }}>
                                <Badge badgeContent={task.quantity} color="error">
                                    <BuildIcon color="action" fontSize='large'/>
                                </Badge>
                            </IconButton><IconButton color="primary" onClick={() => { setQuantity("acceptedQty") }}>
                                <Badge badgeContent={task.acceptedQty} color="error">
                                    <WorkIcon color="action" fontSize='large'/>
                                </Badge>
                            </IconButton><IconButton color="primary" onClick={() => { setQuantity("baseQuantity") }}>
                                <Badge badgeContent={task.baseQuantity} color="error">
                                    <ShoppingCartIcon color="action" fontSize='large'/>
                                </Badge>
                            </IconButton>
                        </Grid>}
                            <Grid item xs={12} key={cTask.uuid} className={classes.row}>
                                <Grid item xs={2}>
                                    <Paper style={{ backgroundColor: color }}>
                                        <Typography variant="h5" className={classes.typography}>
                                            {doneCount + "/" + totalCount}
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={6}>
                                    <Paper className={classes.button}>
                                        <Button
                                            disabled={disabled}
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => moveToLevel(cTask)}
                                            endIcon={disabled && <LockOutlinedIcon className={classes.disabledIcon} />}
                                        >
                                            {cTask.name}
                                        </Button>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </>
                    )
                })}
            </Grid >
            {openDialog && (
                <ContentDialog
                    actions={
                        <IconButton color="primary" onClick={(() => { setOpenDialog(false) })} >
                            <Close />{t('close')}
                        </IconButton>}
                    title={t('confirm_safety_introduction')}
                    open={openDialog}
                    setOpen={setOpenDialog}
                >              
                    <SafetyIntroductionDialog
                        confirmSafety={confirmSafety}
                        task={grandParent}
                        user={user}
                        t={t}
                    />
                </ContentDialog>
            )}
            </>
        )
    } else {
        let doneIndex = -1
        result = (
            <>
                <Grid container className={classes.root}>
                    {!safetyConfirmed && 
                <Grid item xs={12} key={"safety_introduction"} className={classes.row} style={{ marginBottom: "20px" }}>
                    <Button
                        className={classes.safetyButton}
                        fullWidth
                        onClick={() => { 
                            setOpenDialog(true) 
                        }}
                    >{t("confirm_safety_introduction")}</Button>
                </Grid>}
                    {task.childTasks.map((task, i) => {
                        let doneCount = 0
                        let disabled = true
                        task.childTasks.forEach(child => {
                            if (child.done == true) {
                                doneCount++
                                doneIndex = i
                            }
                            if (i == 0 || i <= doneIndex + 1) {
                                disabled = false
                            }
                        })
                        if (task?.helperBaseName == PRODUCTION || task.taskType == TaskTypes.common()) {
                            disabled = false
                        }
                        if (!safetyConfirmed) {
                            disabled = true
                        }
                        totalCount = task?.childTasks?.length

                        let color = null
                        if (doneCount == totalCount) {
                            color = theme.palette.success.dark
                        } else if (doneCount < totalCount && doneCount > 0) {
                            color = theme.palette.warning.light
                        } else {
                            color = theme.palette.error.main
                        }
                        return (
                            <Grid item xs={12} key={task.uuid} className={classes.row}>
                                <Grid item xs={2}>
                                    <Paper style={{ backgroundColor: color }}>
                                        <Typography variant="h5" className={classes.typography}>
                                            {doneCount + "/" + totalCount}
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={6}>
                                    <Paper className={classes.button}>
                                        <Button
                                            disabled={disabled}
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => moveToLevel(task.uuid)}
                                            endIcon={disabled && <LockOutlinedIcon className={classes.disabledIcon} />}
                                        >
                                            {task.name}
                                        </Button>
                                    </Paper>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid >
                {openDialog && (
                    <ContentDialog
                        actions={
                            <IconButton color="primary" onClick={(() => { setOpenDialog(false) })} >
                                <Close />{t('close')}
                            </IconButton>}
                        title={t('confirm_safety_introduction')}
                        open={openDialog}
                        setOpen={setOpenDialog}
                    >                    
                        <SafetyIntroductionDialog
                            confirmSafety={confirmSafety}
                            task={grandParent}
                            user={user}
                            t={t}
                        />
                    </ContentDialog>
                )}
            </>
        )
    }
    return result
}

LevelNavigation.defaultProps = {
    task: {
        helperBaseName: "service"
    }
}

export default LevelNavigation