import React, { useEffect, useState } from 'react'
import { Typography, Container, Box, Grid, Button, IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import { useComponentState, ApiError, Spinner, LocalStorageHelper } from '@collapick/utils';
import ContentDialog from '../../../../components/Dialogs/ContentDialog';
import CloseIcon from '@material-ui/icons/Close';
import useRoleApi from '../../../../hooks/Apis/useRoleApi'
import useRoleMappingApi from '../../../../hooks/Apis/useRoleMappingApi'
import useAppUserApi from '../../../../hooks/Apis/useAppUserApi';
import { DataTable } from '../../../../components/MaterialTable/DataTable'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import EditUserDialog from '../../../../components/Dialogs/EditUserDialog'
import AddIcon from '@material-ui/icons/Add';
import AddUserDialog from '../../../../components/Dialogs/AddUserDialog';
import { HIDDEN_USERS } from '../../../../Constants';
import ConfirmDialog from '../../../../components/Dialogs/ConfirmDialog';


const UsersView = (props) => {
    const { customerId } = props
    const { t } = useTranslation()
    const { componentState, setLoading, setError } = useComponentState()
    const [customer, setCustomer] = useState({})
    const [openEditUser, setOpenEditUser] = useState(false)
    const [initialValues, setInitialValues] = useState({})
    const AppUserApi = useAppUserApi()
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [users, setUsers] = useState([])
    const [openAdd, setOpenAdd] = useState(false)

    useEffect(() => {
        getUsers();
    }, [])


    const getUsers = () => {
        setLoading(true)
        let query = {
            where: {
                and:
                    HIDDEN_USERS.map(user => {
                        return { username: { neq: user } }
                    })
            }
        }
        return AppUserApi.getUsers(query)
            .then(res => {
                setUsers(res)
            })
            .catch(setError)
            .finally(() => {
                setLoading(false)
            })

    }

    const handleEditUserSubmit = () => {
        getUsers()
        setInitialValues({})
        setOpenEditUser(false)
    }

    const editUser = (event, rowData) => {
        if (rowData?.roles.some(r => r.name == "admin")) {
            rowData.role = 1
        } else if (rowData?.roles.some(r => r.name == "user")) {
            rowData.role = 2
        }
        rowData.id += ""
        setInitialValues(rowData)
        setOpenEditUser(true)
    }

    const deleteUserAction = (event, rowData) => {
        setInitialValues(rowData)
        setConfirmOpen(true)
    }

    const deleteUser = (user) => {
        setLoading(true)
        return AppUserApi.deleteUser(user.id)
            .catch(setError)
            .finally(() => {
                getUsers()
                setLoading(false)
            })
    }


    const defaultColumns = [
        { title: t('name'), field: 'username' },
        { title: t('email'), field: 'email' },
        {
            title: t('role'), field: 'role', filtering: false, sorting: false, render: (rowData) => {
                if (rowData?.roles.some(r => r.name == "admin")) {
                    return t('admin')
                } else if (rowData?.roles.some(r => r.name == "user")) {
                    return t('user')
                }
            }
        },
    ]

    const actions = [
        rowData => ({
            icon: () => {
                return <EditIcon />
            },
            tooltip: t('edit_user'),
            isFreeAction: true,
            onClick: editUser
        }),
        rowData => ({
            icon: () => {
                return <DeleteIcon />
            },
            tooltip: t('delete_user'),
            isFreeAction: true,
            onClick: deleteUserAction
        }),
    ]

    const options = {
        filtering: true,
        sorting: true,
        showTitle: false,
        toolbar: false,
        filterCellStyle: {
            textAlign: "center"
        }
    }

    const addNewUser = (event, rowData) => {
        setOpenAdd(true)
    }

    const getNewDataAfterAddingUser = () => {
        getUsers()
        setOpenAdd(false)
    }


    let result = null
    if (componentState.loading) {
        result = <Spinner enabled={componentState.loading} />
    } else if (componentState.error) {
        result = <ApiError error={componentState.error} />
    } else {
        result = (
            <Container maxWidth="xl" >
                <Box my={2} marginTop={10}>
                    <Typography variant="h2">{t("users")}:</Typography>
                    <Typography variant="h2">{customer.name}</Typography>
                    <Box my={4}>
                        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={addNewUser}>
                            {t('add_new_user')}
                        </Button>
                    </Box>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DataTable columns={defaultColumns} data={users} actions={actions} options={options} />
                    </Grid>
                </Grid>
                <ContentDialog
                    actions={
                        <IconButton color="primary" onClick={(() => { setOpenEditUser(false) })} >
                            <CloseIcon />{t('close')}
                        </IconButton>}
                    title={t("edit_user" + "/" + initialValues?.fullname)}
                    open={openEditUser}
                    setOpen={setOpenEditUser}>
                    <EditUserDialog initialValues={initialValues} callback={handleEditUserSubmit} />
                </ContentDialog>

                <ContentDialog
                    actions={
                        <IconButton color="primary" onClick={(() => { setOpenAdd(false) })} >
                            <CloseIcon />{t('close')}
                        </IconButton>}
                    title={t('add_user')}
                    open={openAdd}
                    setOpen={setOpenAdd}>
                    <AddUserDialog getNewDataAfterAddingUser={getNewDataAfterAddingUser} customerId={customerId} />
                </ContentDialog>
                <ConfirmDialog
                    open={confirmOpen}
                    setOpen={setConfirmOpen}
                    title={t('remove_user')}
                    content={initialValues?.username}
                    agreeCallback={() => {
                        setConfirmOpen(false);
                        deleteUser(initialValues);
                        setInitialValues({})
                    }}
                    disagreeCallback={() => {
                        setConfirmOpen(false);
                        setInitialValues({})
                    }} />
            </Container>
        )
    }
    return result
}

UsersView.defaultProps = {
}

export default UsersView