import React from "react"
import { Redirect } from "react-router-dom"
import { useUserContext } from "../../../context/UserContext"
import { useTranslation } from "react-i18next"

import { Login } from "@collapick/utils"
import useRoutes from "../../../hooks/useRoutes"

import Header from "../../../components/Header/Header"
import Footer from "../../../components/Footer/Footer"
import useApi from "../../../hooks/useApi"
import { Container } from "@material-ui/core"

const LoginView = (props) => {
    const { t } = useTranslation()

    const { PATH } = useRoutes();
    const { LoginApi } = useApi()
    const userContext = useUserContext()

    // If user is authenticated, he may be redirected to root path.
    if (userContext.isAuthenticated) {
        return <Redirect to={PATH.root} />
    }

    const messages = {
        usernameLabel: t("username-email"),
        passwordLabel: t("password"),
        loginLabel: t("login"),
        logoutLabel: t("logout")
    }

    const loginSuccessCallback = () => {
        // Login was successful
        console.info("loginSuccessCallback")
    }

    const loginFailedCallback = () => {
        // There was an error logging in.
        console.info("loginFailedCallback")
    }

    const userFilter = {
        include: [
            { relation: "roles" },
            {
                relation: "userBind",
                scope: {
                    include: [{ relation: "customers" }]
                }
            }
        ]
    }
    return (
        <>
            <Header {...props} />
            {/* getUserInfo allows to retrieve more user info during login */}
            {/* useUserLogin stores login data to context */}
            <Container>
                <Login
                    LoginApi={LoginApi}
                    getUserInfo={userFilter}
                    useUserLogin={userContext}
                    messages={messages}
                    loginSuccessCallback={loginSuccessCallback}
                    loginFailedCallback={loginFailedCallback}
                />
            </Container>

            <Footer {...props} />
        </>
    )
}

export default LoginView
