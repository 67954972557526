import { Box, Grid } from '@material-ui/core'
import React from 'react'

const PageNotFound = props => {
    return (
        <>
            <Box m={4}>
                <Grid container alignItems="center" direction="column">
                    <Grid item>
                        404 page not found
                    </Grid>
                </Grid>
            </Box>

        </>
    )
}

export default PageNotFound

