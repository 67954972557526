import React from 'react'
import ProductionFormSettings from '../../../../components/ProductionForms/ProductionFormsSettings'
import { Container } from '@material-ui/core';

const ProductionFormsView = (props) => {

    return (
        <Container>
            <ProductionFormSettings/>
        </Container>
    )
}

ProductionFormsView.defaultProps = {}

export default ProductionFormsView