import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import moment from 'moment'
import 'moment/locale/fi'
import fi from './translations/fi.json';
moment.locale("fi")

console.log(fi)
i18n
    .use(initReactI18next) // passes i18n down to react-i18next

    .init({
        resources: {
            en: { translation: fi },
            fi: { translation: fi }
        },
        lng: "fi",
        saveMissing: true,
        fallbackLng: "fi",
        debug: true,
        interpolation: {
            escapeValue: false
        }
    })



export default i18n