import { useTranslation } from 'react-i18next';
import { useUserContext } from '../context/UserContext'

const useUploadSchema = (props) => {
    const { t } = useTranslation();
    const { token } = useUserContext()

    let uploadSchema = {
        id: "uploadSchema",
        elements: [
            {
                type: "grid",
                direction: 'row',
                spacing: 2,
                elements: [
                    {

                        type: "upload",
                        name: 'files',
                        label: t('files'),
                        item: {
                            xs: 12
                        },
                        config: {
                            url: window.BASEURL + "api/storage/forms/upload-file",
                            token
                        }
                    },
                    {
                        type: 'grid'
                    }
                ]
            }

        ]
    }



    return {
        uploadSchema,
    }

}

export default useUploadSchema;
