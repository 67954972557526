import moment from 'moment'


let dateFormat = "DD.MM.YYYY";
let timeFormat = "DD.MM.YYYY HH:mm"

const useMoment = (token, url = null) => {


    const parseDateToDateFormat = (date) => {
        //console.log(date)
        return moment(date).format(dateFormat)
    }

    const parseDateToDateTimeFormat = (date) => {
        return moment(date).format(timeFormat)
    }

    const parseWeekFromDate = (date) => {
        return moment(date).week();
    }

    const isDateAfterNow = (date) => {
        return isAfter(date, moment());
    }

    const isAfter = (first, second) => {
        return moment(first).isAfter(second);
    }

    const getDuration = (start, end) => {
        if (start != null && end != null) {

            start = moment(start)
            end = moment(end)

            let diff = end.diff(start);
            let f = moment.utc(diff).format("HH:mm:ss.SSS");
            return f
        } else { return null }
    }


    return {
        getDuration,
        parseDateToDateFormat,
        parseDateToDateTimeFormat,
        parseWeekFromDate,
        isDateAfterNow,
        isAfter
    }

}

useMoment.defaultProps = {

}

export default useMoment







