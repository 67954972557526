import React from 'react'
import useApi from '../useApi';


const useAppUserApi = () => {
    const { UserApi } = useApi()

    const getUsers = (filter) => {
        return UserApi.find({ filter: JSON.stringify(filter) })
            .then(result => {
                return result;
            })
    }

    const getOneUser = (filter) => {
        return UserApi.findOne({ filter: JSON.stringify(filter) }).then(result => {
            return result;
        })
    }

    const findById = (id) => {
        return UserApi.findById(id).then(result => {
            return result
        })
    }

    const deleteUser = (id) => {
        return UserApi.deleteById(id + "")
            .then(result => {
                return result;
            })
    }

    const updateById = (id, filter) => {
        return UserApi.updateById(id, filter)
            .then(result => {
                return result;
            })

    }

    const create = (object) => {
        return UserApi.create(object).then(result => {
            return result
        })
    }

    const getUsersForEmail = () => {
        let array = ["collapick", "Ponniste Tuki", "Kytkin"]
        let query = {
            where: {
                and:
                    array?.map(value => {
                        return { username: { neq: value } }
                    })
            }
        }
        return UserApi.find({ filter: JSON.stringify(query) })
            .then(res => {
                return res
            })
    }

    return {
        getUsers,
        findById,
        deleteUser,
        updateById,
        create,
        getOneUser,
        getUsersForEmail,
        api: UserApi
    }

}

export default useAppUserApi
