import React, { createContext, useState } from 'react';
import { useComponentState } from '@collapick/utils';
import { useLocalStore } from 'mobx-react-lite'
import useTaskApi from '../hooks/Apis/useTaskApi';
import { TaskTypes } from '@collapick/ponniste-types'
import { CUSTOM } from '../Constants';
import moment from 'moment'
import useItemApi from '../hooks/Apis/useItemApi';

const DataContext = createContext();


const DataContextProvider = (props) => {
    const TaskApi = useTaskApi()
    const { componentState, setLoading, setError } = useComponentState()
    const [headerValue, setHeaderValue] = useState(0)
    const [tasks, setTasks] = useState([])
    const ItemApi = useItemApi()

    const mobXStore = useLocalStore(() => (
        {
            editLevel (e, rowData) {
                if (rowData?.taskType == TaskTypes.task() ||
                    (rowData?.taskType == TaskTypes.commonToDo() && rowData?.helperBaseName == CUSTOM)) {
                    this.parentTaskDate = TaskTypes.task()
                } else {
                    this.parentTaskDate = rowData?.parentTask?.dueToDate
                }
                this.helperBaseName = rowData?.helperBaseName
                this.taskType = rowData?.taskType
                this.initialValues = rowData
                this.openEditLevel = (true)
            },
            changeWeek (rowData) {
                if (rowData?.dueToDate) {
                    this.initialValues = rowData
                    this.openChangeWeek = true
                }
            },
            addFiles (e, rowData) {
                this.initialValues = rowData
                this.openAddFiles = true
            },
            onSaveEdit (values) {
                setLoading(true)
                let daysToMove = null
                if (this?.initialValues?.dueToDate) {
                    daysToMove = moment(values?.dueToDate).days() - moment(this.initialValues.dueToDate).days()
                }
                return TaskApi.updateTask(values, daysToMove)
                    .then(res => {
                        this.openEditLevel = (false)
                        if (values.taskType == TaskTypes.commonToDo()) {
                            this.updateChild = !this.updateChild
                        } else if (values.taskType == TaskTypes.todo()) {
                            if (values.duration != this.initialValues.duration) {
                                this.updateParent = !this.updateParent
                                this.updateChild = !this.updateChild
                            }
                            this.updateLastLevel = !this.updateLastLevel
                        } else if (values.taskType == TaskTypes.task()) {
                            this.updateParent = !this.updateParent
                        } else {
                            this.update = !this.update
                        }

                    })
                    .catch(setError)
                    .finally(setLoading(false))
            },
            onSaveChangeWeek (values) {
                setLoading(true)
                let daysToMove = moment(values.dueToDate).diff(moment(this.initialValues.dueToDate), 'days')
                return TaskApi.changeWeek(this.initialValues, daysToMove)
                    .then(res => {
                        this.openChangeWeek = false
                        if (values.taskType == TaskTypes.commonToDo()) {
                            this.updateChild = !this.updateChild
                        } else if (values.taskType == TaskTypes.todo()) {
                            this.updateLastLevel = !this.updateLastLevel
                        } else if (values.taskType == TaskTypes.task()) {
                            this.updateParent = !this.updateParent
                        } else {
                            this.update = !this.update
                        }
                    })
                    .catch(setError)
                    .finally(setLoading(false))
            },
            onSaveAddFiles (values) {
                setLoading(true)
                return TaskApi.updateTask(values)
                    .then(res => {
                        this.openAddFiles = (false)
                        if (values.taskType == TaskTypes.commonToDo()) {
                            this.updateChild = !this.updateChild
                        } else if (values.taskType == TaskTypes.todo()) {
                            this.updateLastLevel = !this.updateLastLevel
                        } else if (values.taskType == TaskTypes.task()) {
                            this.updateParent = !this.updateParent
                        } else {
                            this.update = !this.update
                        }

                    })
                    .catch(setError)
                    .finally(setLoading(false))
            },
            openIntroductionForm (uuid) {
                this.initialValues = { formAnswerContainerId: uuid }
                this.setOpenShowAnswers(true)
            },
            setOpenEditLevel (boolean) {
                this.openEditLevel = boolean
            },
            setOpenAddFiles (boolean) {
                this.openAddFiles = boolean
            },
            setOpenChangeWeek (boolean) {
                this.openChangeWeek = boolean
            },
            setOpenShowAnswers (boolean) {
                this.openShowAnswers = boolean
            },
            initialValues: {},
            parentTaskDate: "",
            openEditLevel: false,
            openChangeWeek: false,
            openAddFiles: false,
            update: false,
            updateChild: false,
            updateParent: false,
            updateLastLevel: false,
            openShowAnswers: false,
            fromUrgents: false,
            quantity: null,
            baseQuantity: null,
            acceptedQty: null,
            stars: null,
            date: "months"
        }
    ))

    let values = {
        componentState,
        setError,
        setLoading,
        headerValue,
        setHeaderValue,
        tasks,
        setTasks,
        mobXStore: mobXStore,
    }

    return <DataContext.Provider value={values}>{props.children}</DataContext.Provider>
};

export { DataContext, DataContextProvider }
