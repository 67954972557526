import React, { useState } from 'react'
import { Select, MenuItem, OutlinedInput, Checkbox, ListItemText, Grid, InputLabel } from '@material-ui/core'
import { useTypes, LEVELS } from '../../Constants'
import { useTranslation } from 'react-i18next';

const WorkHoursSearchBar = (props) => {
    const { sizes, searchChange } = props;
    const { t } = useTranslation()
    const [size, setSize] = useState([])
    const [type, setType] = useState([])
    const [level, setLevel] = useState([])
    const types = Object.values(useTypes())
    let levels = []

    LEVELS.forEach(level => {
        let keyName = Object.keys(level)[0]
        if (type.length == 0 || type.includes(keyName)) {
            Object.values(level).forEach(stage => {
                stage.forEach(value => {
                    levels.push(value)
                })
            })
        }
    })

    const handleChange = (event) => {
        if (event.target.name == 'size') {
            setSize(event.target.value)
        } else if (event.target.name == 'type') {
            setType(event.target.value)
        } else if (event.target.name == 'level') {
            setLevel(event.target.value)
        }
        searchChange(event)
    };

    let result = (
        <Grid container direction="row" spacing={2} style={{ paddingLeft: "100px", paddingRight: "100px" }}>
            <Grid item xs={4}>
                <InputLabel>{t('project_size')}</InputLabel>
                <Select
                    multiple
                    value={size}
                    name={"size"}
                    onChange={handleChange}
                    input={<OutlinedInput
                    />}
                    fullWidth

                    variant="outlined"
                    renderValue={(selected) => selected.join(', ')}
                >
                    {sizes.map((item, i) => (
                        <MenuItem key={i} value={item}>
                            <Checkbox checked={size?.indexOf(item) > -1} />
                            <ListItemText primary={item} />
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={4}>
                <InputLabel>{t('type')}</InputLabel>
                <Select
                    multiple
                    value={type}
                    name={"type"}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    fullWidth
                    variant="standard"
                    renderValue={(selected) => selected.join(', ')}
                >
                    {types.map((item, i) => (
                        <MenuItem key={i} value={item}>
                            <Checkbox checked={type?.indexOf(item) > -1} />
                            <ListItemText primary={item} />
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={4}>
                <InputLabel>{t('level')}</InputLabel>
                <Select
                    multiple
                    value={level}
                    name={"level"}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    fullWidth
                    variant="standard"
                    renderValue={(selected) => selected.join(', ')}
                >
                    {levels.map((item, i) => (
                        <MenuItem key={i} value={item}>
                            <Checkbox checked={level?.indexOf(item) > -1} />
                            <ListItemText primary={item} />
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
        </Grid>)

    return result
}

WorkHoursSearchBar.defaultProps = {

}

export default WorkHoursSearchBar