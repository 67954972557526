import React, { useEffect, useState } from "react"
import useFileModelApi from "../../hooks/Apis/useFileModelApi"
import { Grid, Button } from "@material-ui/core"
import turvaohje from './turvaohje.jpeg'

const SafetyIntroductionDialog = (props) => {
    const { confirmSafety, task, user, t } = props
    
    return (
        <>
            <Grid>
                <img src={turvaohje} width="100%" height="100%"/>
                <br/>
                <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={() => { confirmSafety(task, user.uuid) }}
                >{t('confirm')}</Button>
            </Grid>
        </>
    )
}
SafetyIntroductionDialog.defaultProps = {}
export default SafetyIntroductionDialog