import React, { useEffect, useState, useRef } from 'react'
import { Grid } from '@material-ui/core';
import { Typography, IconButton } from '@material-ui/core'
import ConfirmDialog from '../../../../components/Dialogs/ConfirmDialog'
import ContentDialog from '../../../../components/Dialogs/ContentDialog'
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import InfoVideo from '../../../../components/InfoVideo/InfoVideo';
import AddVideoForm from '../../../../components/Form/AddVideoForm'
import { useTranslation } from 'react-i18next';

const InfoVideoView = (props) => {
    const infoVideoRef = useRef(null);
    const { t } = useTranslation()
    const history = useHistory()
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [openAddVideo, setOpenAddVideo] = useState(false)
    const [videoUrl, setVideoUrl] = useState("")
    const [urlToDelete, setUrlToDelete] = useState("")

    const onSaveAdd = (values) => {
        infoVideoRef.current.addVideo(values.url);
        setOpenAddVideo(false)
    }

    const deleteVideoCallback = (url) => {
        setUrlToDelete(url)
        setConfirmOpen(true)
    }


    let result = (
        <Grid container alignItems="center" justify="center">
            <InfoVideo
                setOpenAddVideo={setOpenAddVideo}
                videoUrl={videoUrl}
                ref={infoVideoRef}
                deleteVideoCallback={deleteVideoCallback} />
            <ConfirmDialog
                open={confirmOpen}
                setOpen={setConfirmOpen}
                title={t('remove_url')}
                content={urlToDelete}
                agreeCallback={() => {
                    setConfirmOpen(false);
                    infoVideoRef.current.deleteVideo(urlToDelete);
                    setUrlToDelete(false)
                }}
                disagreeCallback={() => {
                    setConfirmOpen(false);
                    setUrlToDelete(false)
                }} />
            <ContentDialog
                actions={
                    <IconButton color="primary" onClick={(() => { setOpenAddVideo(false) })} >
                        <CloseIcon />{t('close')}
                    </IconButton>}
                title={t('add_video')}
                open={openAddVideo}
                setOpen={setOpenAddVideo}>
                {openAddVideo &&
                    <AddVideoForm
                        onSave={onSaveAdd}
                        onCancel={() => setOpenAddVideo(false)}
                        title={<Typography variant="h2">{t('add_new_video')}</Typography>}
                    />}
            </ContentDialog>
        </Grid>
    )

    return result
}

InfoVideoView.defaultProps = {

}

export default InfoVideoView