import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useComponentState, ApiError, Spinner } from '@collapick/utils';
import { Chip } from "@material-ui/core"
import { theme } from "../../hooks/theme/useAppTheme"
import useTaskApi from '../../hooks/Apis/useTaskApi';

const CountProjectPercent = (props) => {
    const { task } = props
    const { componentState, setLoading, setError } = useComponentState()
    const { t } = useTranslation()
    const TaskApi = useTaskApi()
    const [count, setCount] = useState(null)

    useEffect(() => {
        getDoneTasks()
    }, [task])

    const getDoneTasks = () => {
        setLoading(true)
        return TaskApi.getDoneTasks(task)
            .then(res => {
                let persents = ((res.doneCount / res.totalCount) * 100).toFixed(0)
                let backgroundColor = null
                let color = null
                if (persents < 100) {
                    backgroundColor = theme.palette.warning.light
                    color = theme.palette.warning.contrastText
                } else {
                    backgroundColor = theme.palette.success.dark
                    color = theme.palette.info.contrastText
                }
                setCount({
                    backgroundColor: backgroundColor,
                    persents: persents,
                    color: color
                })
            })
            .catch(setError)
            .finally(setLoading(false))
    }

    let result = null
    if (componentState.loading) {
        result = <Spinner enabled={componentState.loading} />
    } else if (componentState.error) {
        result = <ApiError error={componentState.error} />
    } else {
        result = (
            <>
                {count && <Chip style={{ backgroundColor: count.backgroundColor, color: count.color }} label={count.persents + t("%")} />}
            </>
        )
    }
    return result
}

CountProjectPercent.defaultProps = {
    taskId: null
}
export default CountProjectPercent
