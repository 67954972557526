import React, { createContext, useContext, useState, useMemo, useEffect } from "react"
import { Spinner, useUserLogin, ApiFactory, Login } from "@collapick/utils"
import { TypeTool } from "@collapick/ponniste-types"

const UserContext = createContext()

const DEBUG = false
const debugConsole = (arg1, arg2) => {
    if (DEBUG) {
        if (typeof arg2 === "undefined") {
            console.info(arg1)
        } else {
            console.info(arg1, arg2)
        }
    }
}

/**
 *
 * @param {*} token
 * @param {*} setIsAuthenticated
 * @param {*} setIsValidating
 * @param {*} LoginApi
 * @param {*} clearUserData
 */
const isTokenValid = (token, isValidating, setIsAuthenticated, setIsValidating, clearUserData, user, setUser, setRoles) => {
    const { LoginApi } = ApiFactory(token, null, true, false)
    debugConsole("Validating token...", { token })
    if (!isValidating) {
        setIsValidating(true)
        if (token != null && token.length > 0) {
            let authResult = false
            debugConsole("Authenticating token...")
            return LoginApi.isTokenValid(token)
                .then((res) => {
                    authResult = res
                    if (!authResult) {
                        clearUserData()
                    }
                    setIsAuthenticated(authResult)
                    if (!user && authResult) {
                        console.log("Token to getUserByToken: ", token)
                        return LoginApi.getUserByToken(token)
                    }
                })
                .then((userToken) => {
                    debugConsole("UserToken result", userToken)
                    if (userToken?.user != null) {
                        setUser(userToken.user)
                        setRoles(userToken.user.roles)
                    }
                })
                .catch((err) => {
                    console.error(err)
                })
                .finally(() => {
                    setIsValidating(false)
                })
        } else {
            debugConsole("Context Token was empty or null; not authenticated.")
            clearUserData()
            setIsAuthenticated(false)
            setIsValidating(false)
        }
    }
}


/**
 * Retrieve data before mounting component.
 * @param {*} func
 * @param {*} props
 */
const useComponentWillMount = (func, props) => {
    useMemo(func, props)
}


/**
 * Provider for AppUser and loginInfo
 * Token can be passed via props.token
 * @param {*} props
 */
const UserProvider = (props) => {
    const { token, setToken, user, setUser, roles, setRoles, clearUserData } = useUserLogin(window.BASE_URL)
    const [isInitialized, setIsInitialized] = useState(false)
    

    useEffect(() => {
        debugConsole("Running UserProvider isInitialized check", {
            propsToken: props.token,
            hasStringValue: TypeTool.hasStringValue(props.token),
            isNotSameToken: props.token !== token
        })
        // If passing from a prop to React App wrapper.
        if (!isInitialized && TypeTool.hasStringValue(props.token) && props.token !== token) {
            debugConsole("Settings props token", { isInitialized, differentToken: props.token && props.token !== token })
            setIsInitialized(true)
            setToken(props.token)
        }
    })
    

    
    

    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isValidating, setIsValidating] = useState(false)

    debugConsole("UserProvider initial", {
        token,
        user,
        roles,
        isAuthenticated,
        isValidating
    })
    // Hack for loading data from server before rendering element
    // https://stackoverflow.com/a/56818036
    useComponentWillMount(() => {
        debugConsole("Using storage token...")
        const propToken = props.token
        const hookToken = token

        const tokenToCheck = TypeTool.hasStringValue(hookToken) && hookToken !== propToken ? hookToken : propToken
        debugConsole({
            propToken,
            hookToken,
            tokenToCheck
        })
        isTokenValid(
            tokenToCheck,
            isValidating,
            setIsAuthenticated,
            setIsValidating,
            //LoginApi,
            clearUserData,
            user,
            setUser,
            setRoles
        )
    }, [token, props.token])

    let values = {
        token,
        setToken,
        user,
        setUser,
        roles,
        setRoles,
        clearUserData,
        isAuthenticated,
        isValidating,
        setIsAuthenticated
    }
    

    return (
        <UserContext.Provider value={values}>
            {isValidating ? <Spinner enabled={isValidating} /> : props.children}
        </UserContext.Provider>
    )
}

/**
 * ContextWrapper that warns if used outside Provider
 */
const useUserContext = () => {
    const context = useContext(UserContext)
    if (context === undefined) {
        throw new Error("useUserContext must be used within a UserProvider")
    } else {
        return context
    }
}

export { UserProvider, useUserContext, UserContext }

export default UserContext
