import React, { useContext } from "react"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { Container, Chip } from "@material-ui/core"
import MaterialTableIcons from '../MaterialTable/MaterialTabelIcons'
import useMaterialTableOptions from "../MaterialTable/useMaterialTableOptions"
import { theme, COLOR } from "../../hooks/theme/useAppTheme"
import { DataTable } from '../MaterialTable/DataTable';
import useMoment from '../../hooks/useMoment'
import { URGENT_DAYS, SALE, PRODUCTION, SERVICE } from '../../Constants'
import CountUsedDuration from './CountUsedDuration';
import EditIcon from '@material-ui/icons/Edit';
import DescriptionIcon from '@material-ui/icons/Description';
import useTaskApi from '../../hooks/Apis/useTaskApi';
import { DataContext } from '../../context/DataContext'
import { observer } from "mobx-react-lite"
import EventNoteIcon from '@material-ui/icons/EventNote';

const DashboardProjectsTableDetailPanelLastLevel = (props) => {
    const { id } = props
    const { t } = useTranslation()
    const MomentApi = useMoment()
    const TaskApi = useTaskApi()
    const dataStore = useContext(DataContext)

    const tasks = () => {
        return TaskApi.getTask(id)
            .then(res => {
                return {
                    totalCount: res[0].childTasks.count,
                    page: 1,
                    data: res[0].childTasks
                }
            })
    }


    const baseOptions = useMaterialTableOptions()
    const options = {
        ...baseOptions,
        filtering: false,
        sorting: false,
        search: false,
        pageSize: 25,
        paging: false,
        toolbar: false,
        header: false,
        rowStyle: {
            backgroundColor: COLOR.LIGHT_1,
        },
        headerStyle: {
            backgroundColor: COLOR.LIGHT_1,
            textAlign: "center",
        }

    }

    const defaultColumns = [
        {
            title: t("level"), field: "name", render: (rowData) => {
                let color = null
                let textColor = null
                if (rowData?.helperBaseName == PRODUCTION) {
                    color = theme.palette.primary.light
                    textColor = theme.palette.getContrastText(theme.palette.primary.light)
                } else if (rowData?.helperBaseName == SALE) {
                    color = theme.palette.success.light
                    textColor = theme.palette.getContrastText(theme.palette.success.light)
                } else if (rowData?.helperBaseName == SERVICE) {
                    color = theme.palette.secondary.light
                    textColor = theme.palette.getContrastText(theme.palette.secondary.light)
                } else {
                    color = theme.palette.primary.light
                    textColor = theme.palette.getContrastText(theme.palette.primary.light)
                }
                return <Chip
                    style={{ backgroundColor: color, color: textColor }}
                    label={rowData.name} />
            }
        }, { title: t('week_number'), field: "week", filtering: true, render: (rowData) => {
            let week = moment(rowData.dueToDate).format('W') + " " + t('vko')
            let oneDay = (24 * 60 * 60 * 1000)
            let mainColor = theme.palette.info.main
            let textColor = theme.palette.info.contrastText

            if (!rowData?.dueToDate) {
                mainColor = COLOR.LIGHT_2
                textColor = theme.palette.getContrastText(COLOR.LIGHT_2)
            }
            if (rowData?.dueToDate && /*!MomentApi.isDateAfterNow(rowData.dueToDate))*/
                moment(rowData?.dueToDate).valueOf() < (moment() + (URGENT_DAYS * oneDay))) {
                mainColor = theme.palette.warning.main
                textColor = theme.palette.warning.contrastText
            }
            if (rowData?.dueToDate && !MomentApi.isDateAfterNow(rowData.dueToDate)) {
                mainColor = theme.palette.error.main
                textColor = theme.palette.error.contrastText
            }
            if (rowData?.done && rowData?.dueToDate) {
                mainColor = theme.palette.success.dark
                textColor = theme.palette.info.contrastText
            }
            if (rowData?.dueToDate == null) {
                week = t('no_dueToDate_set')
            }
            return (
                <Chip 
                    style={{ backgroundColor: mainColor, color: textColor, cursor: 'pointer' }} 
                    label={week} 
                    onClick={() => { dataStore.mobXStore.changeWeek(rowData) }}
                />
            )
        } },
        { title: t('comment'), field: "comment" },
        { title: t('estimatedDuration') + "(h)", field: "estimatedDuration" },
        {
            title: t('duration') + "(h)", field: "duration", render: (rowData) => {
                return <CountUsedDuration task={rowData} />
            }
        },
        {
            title: t('complited'), field: "complited", render: (rowData) => {
                let count = 0
                let totalCount = 1

                if (rowData.done) {
                    count = 1
                }


                let mainColor = null
                let textColor = null
                if (count == totalCount) {
                    mainColor = theme.palette.success.dark
                    textColor = theme.palette.info.contrastText
                } else {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (count == 0) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }

                return <Chip
                    style={{ backgroundColor: mainColor, color: textColor }}
                    label={count + "/" + totalCount} />

            }
        },
    ]

    let columns = defaultColumns

    let actions = [
        rowData => {
            return ({
                icon: () => {
                    let result = null
                    if (rowData?.dueToDate) {
                        result = < EditIcon style={{ color: COLOR.GREEN }} />
                    } else {
                        result = < EditIcon style={{ color: COLOR.RED }} />
                    }
                    return result
                },
                tooltip: t('edit_level'),
                onClick: dataStore.mobXStore.editLevel

            })
        },
        rowData => {
            return ({
                icon: () => {
                    let result = null
                    if (rowData.files) {
                        result = < DescriptionIcon style={{ color: COLOR.GREEN }} />
                    } else {
                        result = < DescriptionIcon />
                    }
                    return result
                },
                tooltip: t('add_files'),
                onClick: dataStore.mobXStore.addFiles
            })
        },
        rowData => {
            if (rowData?.formAnswerContainerId) {
                return ({
                    icon: () => {
                        return <EventNoteIcon />
                    },
                    tooltip: t('show_answers'),
                    onClick: () => { dataStore.mobXStore.openIntroductionForm(rowData.formAnswerContainerId) }
                })
            }
            
        },
    ]

    let detailPanel = [
        {
            disabled: true,
            render: (rowData) => {
                let result = null
                result = (
                    <>
                    </>
                )
                return result
            }
        }
    ]

    let result = (
        <Container maxWidth="xl" style={{ paddingLeft: 0, paddingRight: 0 }}>
            <DataTable
                isLoading={dataStore?.componentState?.loading}
                options={options}
                title={t("projects")}
                columns={columns}
                data={tasks}
                icons={MaterialTableIcons}
                actions={actions}
                detailPanel={detailPanel}
                update={dataStore.mobXStore.updateLastLevel}
            />
        </Container>
    )
    return result
}
export default observer(DashboardProjectsTableDetailPanelLastLevel)
