import React, { useState, useEffect } from 'react'
import useDynamicDataApi from '../../../../hooks/Apis/useDynamicDataApi';
import { useComponentState, ApiError, Spinner } from '@collapick/utils';
import WorkHoursSearchBar from '../../../../components/SearchBars/WorkHoursSearchBar';
import WorkHoursTable from '../../../../components/Table/WorkHoursTable';
import WorkHoursCalculation from '../../../../components/WorkHours/WorkHoursCalculation';

const WorkHours = (props) => {
    const { componentState, setLoading, setError } = useComponentState()
    const DynamicDataApi = useDynamicDataApi()
    const [sizes, setSizes] = useState([])
    const [values, setValues] = useState()

    useEffect(() => {
        getSizes()
    }, [])

    const getSizes = () => {
        setLoading(true)
        return DynamicDataApi.getSizes()
            .then(res => {
                setSizes(res)
            })
            .catch(setError)
            .finally(setLoading(false))
    }

    const searchChange = (e) => {
        const targetValue = e.target.value;
        setValues({
            ...values,
            [e.target.name]: targetValue
        });
    }

    let result = null
    if (componentState.loading) {
        result = <Spinner enabled={componentState.loading} />
    } else if (componentState.error) {
        result = <ApiError error={componentState.error} />
    } else {
        result = (
            <>
                <WorkHoursSearchBar
                    sizes={sizes}
                    searchChange={searchChange}
                />
                <WorkHoursCalculation
                    values={values}
                />
                <WorkHoursTable
                    values={values}
                />
            </>
        )
    }
    return result
}

export default WorkHours