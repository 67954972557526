import React from 'react'
import { useTranslation } from "react-i18next"
import { Button, Container, Grid, Paper, Typography } from "@material-ui/core"
import { theme } from "../../hooks/theme/useAppTheme"
import { makeStyles, fade } from "@material-ui/core/styles"
import { URGENTS, PROJECTS, WORKS } from '../../Constants';
import ApartmentIcon from '@material-ui/icons/Apartment';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import RepeatOneIcon from '@material-ui/icons/RepeatOne';

const useStyles = makeStyles((t) => (
    {
        root: {
            backgroundColor: "#E9E9F0",
            padding: "2.5vh",
            paddingTop: "5vh",
            paddingBottom: "5vh",
            textAlign: "center"

        },
        paper: {
            height: "100px",
            width: "100px",
            borderRadius: "50% 50% 50% 50%",
            backgroundColor: "white"
        },
        icon: {
            fontSize: "75px"
        },
        button: {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.info.contrastText,
            '&:hover': {
                backgroundColor: fade(theme.palette.info.main, 0.5),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
                '&$disabled': {
                    backgroundColor: 'transparent',
                },
            },
            '&$disabled': {
                color: theme.palette.action.disabled,
            }
        }
    }))

const ProjectsMainViewSelectionHeader = (props) => {
    const { setView } = props
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <Container maxWidth="xl" className={classes.root}>
            <Grid container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={2}>
                <Grid item xs={4}>
                    <Button className={classes.paper} onClick={() => setView(WORKS)}>
                        <RepeatOneIcon
                            className={classes.icon}
                            //color="primary"
                            style={{ color: theme.palette.primary.dark }}
                        />
                    </Button>
                    <Typography variant="h6">{t('works')}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Button className={classes.paper} onClick={() => setView(PROJECTS)}>
                        <ApartmentIcon
                            className={classes.icon}
                            //color="primary"
                            style={{ color: theme.palette.primary.dark }}
                        />
                    </Button>
                    <Typography variant="h6">{t('projects')}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Button className={classes.paper} onClick={() => setView(URGENTS)}>
                        <ErrorOutlineIcon
                            className={classes.icon}
                            color="error"
                        />
                    </Button>
                    <Typography variant="h6">{t('urgents')}</Typography>
                </Grid>
            </Grid>
        </Container>
    )
}

export default ProjectsMainViewSelectionHeader

/*
  <Grid item xs={6}>
                    <Paper className={classes.button}>
                        <Button
                            className={classes.button}
                            fullWidth
                            onClick={() => setView(PROJECTS)}
                        >{t('projects')}</Button>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.button}>
                        <Button
                            className={classes.button}
                            fullWidth
                            onClick={() => setView(URGENTS)}
                        >{t('urgents')}</Button>
                    </Paper>
                </Grid>
                */