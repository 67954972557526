import React, { useContext, useEffect, useState } from 'react'
import ContentDialog from './ContentDialog'
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core'
import { DataContext } from '../../context/DataContext'
import { useTranslation } from 'react-i18next';
import { observer } from "mobx-react-lite"
import UploadForm from '../Form/UploadForm';
import useTaskApi from '../../hooks/Apis/useTaskApi';
import DefaultAttachmentsSelection from './DefaultAttachmentsSelection';

const AddFilesDialog = (props) => {
    const dataStore = useContext(DataContext)
    const { t } = useTranslation()
    const [defaultAttachments, setDefaultAttachments] = useState([])
    const TaskApi = useTaskApi()
    const [checked, setChecked] = useState([])

    useEffect(() => {
        getDefaultAttachments()
    }, [])

    const getDefaultAttachments = () => {
        return TaskApi.getDefaultAttachments()
            .then(res => {
                setDefaultAttachments(res)
            })
            .catch(err => { throw err })
    }

    const save = (values) => {
        values.taskObject = checked
        dataStore.mobXStore.onSaveAddFiles(values)
        setChecked([])
    }

    let result = (
        <ContentDialog
            actions={
                <IconButton color="primary" onClick={(() => { dataStore.mobXStore.openAddFiles = (false) })} >
                    <CloseIcon />{t('close')}
                </IconButton>}
            title={t('add_files')}
            open={dataStore.mobXStore.openAddFiles}
            setOpen={dataStore.mobXStore.setOpenAddFiles}>
            {
                <>
                    <DefaultAttachmentsSelection
                        defaultAttachments={defaultAttachments}
                        checked={checked}
                        setChecked={setChecked}
                    />
                    <UploadForm
                        onSave={save}
                        onCancel={() => dataStore.mobXStore.openAddFiles = (false)}
                        initialValues={dataStore.mobXStore.initialValues}
                    />
                </>}
        </ContentDialog>
    )
    return result
}

export default observer(AddFilesDialog)