import useApi from '../useApi';

const useFormApi = () => {
    const FormApi = useApi().FormApi

    const getForms = () => {
        return FormApi.find()
    }

    return {
        getForms
    }

}

export default useFormApi