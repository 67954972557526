import { useTranslation } from 'react-i18next';
import { TaskTypes } from '@collapick/ponniste-types';
import moment from 'moment'
import { SERVICE } from '../Constants';

const useEditWeekSchema = (props) => {
    const { t } = useTranslation();

    let editWeekSchema = {
        id: "editWeekSchema",
        elements: [
            {
                type: "grid",
                direction: 'row',
                spacing: 2,
                elements: [
                    {

                        type: "date",
                        name: 'dueToDate',
                        label: t('due_to_date'),
                        item: {
                            xs: 12,
                        },
                        elementProps: {
                            inputVariant: "outlined"
                        },
                    },
                ]
            }

        ]
    }


    

    return {
        editWeekSchema
    }
}

useEditWeekSchema.defaultProps = {
}

export default useEditWeekSchema;
