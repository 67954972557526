import { useTranslation } from 'react-i18next';

const useSendEmailSchema = (props) => {
    const { users } = props
    const { t } = useTranslation();

    let emailSchema = {
        id: "emailSchema",
        elements: [
            {
                type: "grid",
                direction: 'row',
                spacing: 2,
                elements: [
                    {

                        type: "select",
                        name: 'receiver',
                        label: t('receiver'),
                        item: {
                            xs: 12
                        },
                        options:
                            users.map(user => {
                                return { value: user.email, label: user.username }
                            }),
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('receiver_is_required')
                                }
                            },
                        ]
                    },
                    {

                        type: "text",
                        name: 'message',
                        label: t('message'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('message_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: 'grid'
                    }
                ]
            }

        ]
    }

    return {
        emailSchema,
    }

}

export default useSendEmailSchema;
