import React from "react"
import { useTranslation } from "react-i18next"
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from "react-router-dom";
import { Grid, makeStyles } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        //marginBottom: "8px"
    },
}))

const Header = (props) => {
    const classes = useStyles()
    const { t } = useTranslation();
    const history = useHistory();

    const goBack = () => {
        history.goBack()
    }

    return (
        <AppBar className={classes.bar} position="static">
            <Toolbar>
                <Grid justify="flex-start" container wrap={"nowrap"} alignItems="center" spacing={2}>
                    {history.location.pathname.includes('main') ?
                        <Typography variant="h6" >
                            {t('Yrityksen nimi')}
                        </Typography>
                        :
                        <IconButton onClick={goBack}><ArrowBackIcon /></IconButton>}

                </Grid>
                <Grid container justify="flex-end" >
                    <IconButton edge="start" color="inherit" aria-label="menu">
                        <MenuIcon />
                    </IconButton>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

Header.defaultProps = {}
export default Header
