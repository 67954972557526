import React from 'react'
import { Typography, Container, Grid, Paper, Box, Button, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import { CollapickForm } from '@collapick/form';
import { useComponentState, ApiError, Spinner } from '@collapick/utils';
import useAppUserApi from '../../hooks/Apis/useAppUserApi';
import useRoleMappingApi from '../../hooks/Apis/useRoleMappingApi';
import useAddUserSchema from '../../FormSchemas/useAddUserSchema'
import useRoleApi from '../../hooks/Apis/useRoleApi';

const AddUserDialog = (props) => {
    const { t } = useTranslation();
    const { setLoading, setError, componentState } = useComponentState()
    const { getNewDataAfterAddingUser, customersRoleId, customerId } = props
    const { addSchema, customValidators } = useAddUserSchema();
    const RoleMappingApi = useRoleMappingApi()
    const AppUserApi = useAppUserApi()
    const RoleApi = useRoleApi()

    const onSave = (values) => {
        setLoading(true)
        let error = false
        let object = {
            loginName: values.username,
            fullname: values.username,
            username: values.username,
            email: values.email,
            password: values.password,
        }
        return AppUserApi.create(object)
            .then(result => {
                let object = {
                    principalType: "USER",
                    principalId: result.id,
                    roleId: values.role
                }
                return RoleMappingApi.create(object)
            })
            .catch((err) => {
                error = true
                setError(err)
            })
            .finally(() => {
                setLoading(false)
                if (!error) {
                    getNewDataAfterAddingUser()
                }
            })
    }

    return (
        <>
            {componentState.error && (<Grid item xs={12}><ApiError error={componentState.error} /></Grid>)}
            {componentState.loading ?
                <Spinner />
                :
                <Container >
                    <Box my={2}>
                        <Typography variant="h3">{t("add_new_user")}</Typography>
                    </Box>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <CollapickForm
                                formSchema={addSchema}
                                onFormSubmit={onSave}
                                resetFormAfterSubmit={true}
                                customValidators={customValidators}
                                renderSubmitButton={() =>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        fullWidth>
                                        {t('submit')}
                                    </Button>}
                            />
                        </Grid>
                    </Grid>
                </Container>
            }
        </>
    )
}

AddUserDialog.defaultProps = {
    getNewDataAfterAddingUser: () => { },
    customerId: null,
}

export default AddUserDialog

