import React, { useState, useCallback, useContext, useEffect } from "react"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { Container, Chip, Typography, Grid, IconButton, Badge, Select } from "@material-ui/core"
import MaterialTableIcons from '../MaterialTable/MaterialTabelIcons'
import useMaterialTableOptions from "../MaterialTable/useMaterialTableOptions"
import { theme, COLOR } from "../../hooks/theme/useAppTheme"
import { DataTable } from '../MaterialTable/DataTable';
import useMoment from '../../hooks/useMoment'
import { URGENT_DAYS, SALE, PRODUCTION, SERVICE } from '../../Constants'
import CountUsedDuration from './CountUsedDuration';
import DashboardProjectTableDetailPanel from './DashboardProjectTableDetailPanel'
import EditIcon from '@material-ui/icons/Edit';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { useComponentState, LocalStorageHelper, ApiError, Spinner } from '@collapick/utils';
import CachedIcon from '@material-ui/icons/Cached';
import { DataContext } from '../../context/DataContext'
import useTaskApi from '../../hooks/Apis/useTaskApi';
import { observer } from "mobx-react-lite"
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PostAddIcon from '@material-ui/icons/PostAdd';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import GetAppIcon from '@material-ui/icons/GetApp';
import Axios from 'axios';
import BuildIcon from '@material-ui/icons/Build';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import WorkIcon from '@material-ui/icons/Work';
import useItemApi from '../../hooks/Apis/useItemApi';
import StarIcon from '@material-ui/icons/Star';

const generateLocalStorageKey = (title, columns) => {
    let key = title + columns?.length;
    return key
}

const DashboardProjectsTable = (props) => {
    const { addProject, deleteProject, addCustomProject, mode, changeWeek } = props
    const { t } = useTranslation()
    const MomentApi = useMoment()
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const dataStore = useContext(DataContext)
    const TaskApi = useTaskApi()
    const [showComplited, setShowComplited] = useState(false)
    const { componentState, setLoading, setError } = useComponentState()
    const ItemApi = useItemApi()

    useEffect(() => {
        getLackOfProjects()
    }, [])

    const getLackOfProjects = () => {
        const date = moment().subtract(1, dataStore.mobXStore.date)
        let query = {
            where: {
                created: { between: [date, moment()] }
            }
        }
        return ItemApi.useApi.find({ filter: JSON.stringify(query) })
            .then(res => {
                let quantity = 0
                let baseQuantity = 0
                let acceptedQty = 0
                res?.forEach(i => {
                    if (i.name == "quantity") {
                        quantity += 1
                    } else if (i.name == "baseQuantity") {
                        baseQuantity += 1 
                    } else if (i.name == "acceptedQty") {
                        acceptedQty += 1
                    }
                })
                dataStore.mobXStore.quantity = quantity
                dataStore.mobXStore.baseQuantity = baseQuantity
                dataStore.mobXStore.acceptedQty = acceptedQty
                let query = {
                    where: {
                        status: { neq: null },
                        updated: { between: [date, moment()] }
                    }
                }
                return TaskApi.useApi.find({ filter: JSON.stringify(query) })
            })
            .then((res) => {
                let stars = 0
                res?.forEach(t => {
                    stars += parseInt(t.status)
                })
                if (stars) {
                    dataStore.mobXStore.stars = parseFloat(stars / res?.length).toFixed(1)
                }
            })
            .catch(setError)
            .finally(() => {
                renderTitle()
            })
    }

    const baseOptions = useMaterialTableOptions()
    const options = {
        ...baseOptions,
        filtering: false,
        sorting: true,
        search: true,
        pageSize: 25
    }

    const defaultColumns = [
        { title: t("projects"), field: "name", filtering: true },
        { title: t("size"), field: "unit", filtering: true },
        { title: t("m2"), field: "taskObject.size", filtering: true },
        { title: t("population"), field: "taskObject.population", filtering: true },
        { title: t("heating_system"), field: "taskObject.heatingSystem", filtering: true },
        { title: t("another_size"), field: "taskObject.anotherSize", filtering: true },
        { title: t("dueToDate"), field: "dueToDate" },
        { title: t('week_number'), field: "week", filtering: true },
        { title: t('customer'), field: "user", filtering: true },
        { title: t('comment'), field: "comment" },
        { title: t('estimatedDuration') + "(h)", field: "estimatedDuration" },
        { title: t('duration') + "(h)", field: "duration", sorting: false },
        { title: t('sale'), field: "sale", sorting: false },
        { title: t('production'), field: "production", sorting: false },
        { title: t('service'), field: "service", sorting: false }
    ]

    let title = "dashboard-projects-table"
    const columns = LocalStorageHelper.get(generateLocalStorageKey(title, defaultColumns)) || defaultColumns

    const clearCache = () => {
        LocalStorageHelper.remove(generateLocalStorageKey(title, defaultColumns))
        forceUpdate();
    }

    const columnsHide = (column) => {
        let newColumns = [...columns];
        let found = newColumns.find(c => c.field == column.field);
        if (found) {
            found.hidden = column.hidden;
        }
        LocalStorageHelper.remove(generateLocalStorageKey(title, defaultColumns))
        LocalStorageHelper.set(generateLocalStorageKey(title, defaultColumns), newColumns)
    }

    let overrideColumns = [...columns];
    overrideColumns.forEach(c => {
        if (c.field == 'dueToDate') {
            c.render = (rowData) => {
                let mainColor = theme.palette.info.main
                let textColor = theme.palette.info.contrastText
                let date = ""
                let oneDay = (24 * 60 * 60 * 1000)

                if (!rowData?.dueToDate) {
                    mainColor = COLOR.LIGHT_2
                    textColor = theme.palette.getContrastText(COLOR.LIGHT_2)
                }
                if (rowData?.dueToDate && /*!MomentApi.isDateAfterNow(rowData.dueToDate))*/
                    moment(rowData?.dueToDate).valueOf() < (moment() + (URGENT_DAYS * oneDay))) {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (rowData?.dueToDate && !MomentApi.isDateAfterNow(rowData.dueToDate)) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }

                if (rowData?.done && rowData?.dueToDate) {
                    mainColor = theme.palette.success.dark
                    textColor = theme.palette.info.contrastText
                }

                if (rowData?.dueToDate != null) {
                    date = moment(rowData?.dueToDate).format("DD.MM.YYYY")
                } else {
                    date = t('no_dueToDate_set')
                }
                return <Chip style={{ backgroundColor: mainColor, color: textColor }} label={date} 
                    onClick={() => { dataStore.mobXStore.changeWeek(rowData) }}/>
            }
        } else if (c.field == 'week') {
            c.render = (rowData) => {
                let week = moment(rowData.dueToDate).format('W') + " " + t('vko')
                let oneDay = (24 * 60 * 60 * 1000)
                let mainColor = theme.palette.info.main
                let textColor = theme.palette.info.contrastText

                if (!rowData?.dueToDate) {
                    mainColor = COLOR.LIGHT_2
                    textColor = theme.palette.getContrastText(COLOR.LIGHT_2)
                }
                if (rowData?.dueToDate && /*!MomentApi.isDateAfterNow(rowData.dueToDate))*/
                    moment(rowData?.dueToDate).valueOf() < (moment() + (URGENT_DAYS * oneDay))) {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (rowData?.dueToDate && !MomentApi.isDateAfterNow(rowData.dueToDate)) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }
                if (rowData?.done && rowData?.dueToDate) {
                    mainColor = theme.palette.success.dark
                    textColor = theme.palette.info.contrastText
                }
                if (rowData?.dueToDate == null) {
                    week = t('no_dueToDate_set')
                }
                return (
                    <Chip 
                        style={{ backgroundColor: mainColor, color: textColor, cursor: 'pointer' }} 
                        label={week} 
                        onClick={() => { dataStore.mobXStore.changeWeek(rowData) }}
                    />
                )
            }
        } else if (c.field == 'duration') {
            c.render = (rowData) => {
                return <CountUsedDuration task={rowData} />
            }
        } else if (c.field == 'sale') {
            c.render = (rowData) => {
                let mainColor = null
                let textColor = null
                if (rowData?.sale?.count == rowData?.sale?.totalCount) {
                    mainColor = theme.palette.success.dark
                    textColor = theme.palette.info.contrastText
                } else {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (rowData?.sale?.count == 0) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }
                if (rowData?.sale) {
                    return <Chip
                        style={{ backgroundColor: mainColor, color: textColor }}
                        label={rowData.sale.count + "/" + rowData.sale.totalCount} />
                }
            }
        } else if (c.field == 'production') {
            c.render = (rowData) => {
                let mainColor = null
                let textColor = null
                if (rowData?.production?.count == rowData?.production?.totalCount) {
                    mainColor = theme.palette.success.dark
                    textColor = theme.palette.info.contrastText
                } else {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (rowData?.production?.count == 0) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }
                if (rowData?.production) {
                    return <Chip
                        style={{ backgroundColor: mainColor, color: textColor }}
                        label={rowData.production.count + "/" + rowData.production.totalCount} />
                }
            }
        } else if (c.field == 'service') {
            c.render = (rowData) => {
                let mainColor = null
                let textColor = null
                if (rowData?.service?.count == rowData?.service?.totalCount) {
                    mainColor = theme.palette.success.dark
                    textColor = theme.palette.info.contrastText
                } else {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (rowData?.service?.count == 0) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }
                if (rowData?.service) {
                    return <Chip
                        style={{ backgroundColor: mainColor, color: textColor }}
                        label={rowData.service.count + "/" + rowData.service.totalCount} />
                }
            }
        } else if (c.field == 'taskObject.size') {
            c.render = (rowData) => {
                if (rowData?.taskObject?.size) {
                    return rowData.taskObject.size + "m2"
                }
            }
        } else if (c.field == 'taskObject.anotherSize') {
            c.render = (rowData) => {
                if (rowData?.taskObject?.anotherSize) {
                    return rowData.taskObject.anotherSize + "m2"
                }
            }
        }
    })

    let actions = [
        {
            icon: () => {
                return < EditIcon style={{ color: COLOR.GREEN }} />
            },
            tooltip: t('edit_project'),
            onClick: dataStore.mobXStore.editLevel
        },
        {
            icon: () => {
                return < DeleteForeverIcon style={{ color: COLOR.GREEN }} />
            },
            tooltip: t('delete_project'),
            onClick: deleteProject
        },
        {
            icon: () => {
                return < AddBoxIcon style={{ color: COLOR.GREEN }} />
            },
            tooltip: t('add_project'),
            onClick: addProject,
            isFreeAction: true
        },
        {
            icon: () => {
                return < PostAddIcon style={{ color: COLOR.GREEN }} />
            },
            tooltip: t('add_custom_project'),
            onClick: addCustomProject,
            isFreeAction: true
        },
        {
            key: "clear",
            icon: () => {
                return <CachedIcon />
            },
            tooltip: t('clear_cache'),
            isFreeAction: true,
            onClick: clearCache
        },
        {
            icon: () => {
                return < GetAppIcon style={{ color: COLOR.GREEN }} />
            },
            tooltip: t('download_files'),
            onClick: (e, rowData) => {
                setLoading(true)
                const projectName = rowData.name.replace(/([^a-z0-9wåäöÅÄÖ.]+)/gi, '-')
                const url = window.BASEURL + "kytkin-api/zip-project/" + rowData.uuid
                return Axios({
                    url: url, //your url
                    method: 'GET',
                    responseType: 'blob', // important
                }).then(res => {
                    const href = URL.createObjectURL(res.data);
                    // create "a" HTLM element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', projectName + '.zip'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                }).catch(setError
                ).finally(() => {
                    setLoading(false)
                })
            }
        },
    ]

    let detailPanel = [
        rowData => {
            return ({
                tooltip: t('show_details'),
                render: (rowData) => {
                    let result = null
                    result = (
                        <DashboardProjectTableDetailPanel
                            id={rowData.uuid}
                        />
                    )
                    return result
                }
            })
        }
    ]

    const calcResult = (array) => {
        array.forEach(task => {
            task.sale = {
                count: 0,
                totalCount: 0
            }
            task.production = {
                count: 0,
                totalCount: 0
            }
            task.service = {
                count: 0,
                totalCount: 0
            }
            task.childTasks.forEach(childTasks => {
                childTasks.childTasks.forEach(innerChild => {
                    if (innerChild.helperBaseName == SALE) {
                        task.sale.totalCount++
                        task.sale.count += innerChild.done
                    } else if (innerChild.helperBaseName == PRODUCTION) {
                        task.production.totalCount++
                        task.production.count += innerChild.done
                    } else if (innerChild.helperBaseName == SERVICE) {
                        task.service.totalCount++
                        task.service.count += innerChild.done
                    }
                })
            })
        })
        return array
    }

    const ajax = (params) => {
        return TaskApi.getAllTasks(params, columns, showComplited, mode)
            .then(res => {
                let calculatedResult = calcResult(res)
                return (
                    {
                        totalCount: res.count,
                        page: params.page,
                        data: calculatedResult
                    }
                )
            })
    }

    const renderTitle = () => {
        return (
            <Grid container direction="row" alignItems="center">
                <Typography variant="h6" style={{ marginRight: "20px" }}>{t('projects')}</Typography>
                <IconButton color="primary" disabled={true}>
                    <Badge badgeContent={dataStore.mobXStore.quantity} color="error">
                        <BuildIcon color="action" fontSize="large"/>
                    </Badge>
                </IconButton>
                <IconButton color="primary" disabled={true}>
                    <Badge badgeContent={dataStore.mobXStore.baseQuantity} color="error">
                        <WorkIcon color="action" fontSize="large"/>
                    </Badge>
                </IconButton>
                <IconButton color="primary" disabled={true}>
                    <Badge badgeContent={dataStore.mobXStore.acceptedQty} color="error">
                        <ShoppingCartIcon color="action" fontSize="large"/>
                    </Badge>
                </IconButton>
                <IconButton color="primary" disabled={true}>
                    <Badge badgeContent={dataStore.mobXStore.stars} color="error">
                        <StarIcon color="action" fontSize="large"/>
                    </Badge>
                </IconButton>
                <Select
                    variant="standard"
                    name={'stringValue'}
                    style={{ width: "100px", marginLeft: "15px" }}
                    //value={s.stringValue}
                    //onChange={(e) => { onChangeLevel(e, i) }}
                    margin="dense"
                    defaultValue={"months"}
                    onChange={(e) => {
                        dataStore.mobXStore.date = e.target.value
                        getLackOfProjects()
                    }}
                >
                    <option value="weeks">{t('week')}</option> 
                    <option value="months">{t('month')}</option> 
                    <option value="years">{t('year')}</option> 
                </Select>
            </Grid>
        )
    }

    let result = null
    if (componentState.error) {
        result = <ApiError error={componentState.error} />
    } else {
        result = (
            <Container maxWidth="xl" style={{ marginTop: 10 }}>
                <DataTable
                    isLoading={componentState.loading}
                    options={options}
                    title={renderTitle()}
                    columns={overrideColumns}
                    data={ajax}
                    icons={MaterialTableIcons}
                    actions={actions}
                    detailPanel={detailPanel}
                    columnsHide={columnsHide}
                    update={dataStore.mobXStore.updateParent}
                />
            </Container>
        )
    }
    return result
}
export default observer(DashboardProjectsTable)
