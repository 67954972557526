import React, { useEffect, useContext, useState } from 'react'
import DashboardProjectsTable from '../../../../components/Table/DashboardProjectsTable';
import { useComponentState, ApiError, Spinner } from '@collapick/utils';
import useDynamicDataApi from '../../../../hooks/Apis/useDynamicDataApi';
import { useHistory } from 'react-router-dom';
import { DataContext } from '../../../../context/DataContext'
import EditDialog from '../../../../components/Dialogs/EditDialog';
import AddFilesDialog from '../../../../components/Dialogs/AddFilesDialog'
import ConfirmDialog from '../../../../components/Dialogs/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import useTaskApi from '../../../../hooks/Apis/useTaskApi';
import ContentDialog from '../../../../components/Dialogs/ContentDialog';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PickWeekDialog from '../../../../components/Dialogs/PickWeekDialog';
import DashboardTasksInWeeksTable from '../../../../components/Table/DashboardTasksInWeeksTable';

const TasksInWeeks = (props) => {
    const { componentState, setLoading, setError } = useComponentState()
    const DynamicDataApi = useDynamicDataApi()
    const history = useHistory()
    const dataStore = useContext(DataContext)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [projectToDelete, setProjectToDelete] = useState("")
    const { t } = useTranslation()
    const TaskApi = useTaskApi()



    let result = (
        <>
            {<DashboardTasksInWeeksTable
            />}
        </>
    )
    return result
}

export default TasksInWeeks