import React, { useState, useCallback, useContext } from "react"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { Container, Chip } from "@material-ui/core"
import MaterialTableIcons from '../MaterialTable/MaterialTabelIcons'
import useMaterialTableOptions from "../MaterialTable/useMaterialTableOptions"
import { theme, COLOR } from "../../hooks/theme/useAppTheme"
import { DataTable } from '../MaterialTable/DataTable';
import useMoment from '../../hooks/useMoment'
import { URGENT_DAYS, SALE, PRODUCTION, SERVICE } from '../../Constants'
import CountUsedDuration from './CountUsedDuration';
import DashboardProjectTableDetailPanel from './DashboardProjectTableDetailPanel'
import EditIcon from '@material-ui/icons/Edit';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { LocalStorageHelper } from '@collapick/utils';
import CachedIcon from '@material-ui/icons/Cached';
import { DataContext } from '../../context/DataContext'
import useTaskApi from '../../hooks/Apis/useTaskApi';
import { observer } from "mobx-react-lite"
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PostAddIcon from '@material-ui/icons/PostAdd';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { TaskTypes } from '@collapick/ponniste-types';

const generateLocalStorageKey = (title, columns) => {
    let key = title + columns?.length;
    return key
}

const DashboardTasksInWeeksTable = (props) => {
    const { addProject, deleteProject, addCustomProject, mode, changeWeek } = props
    const { t } = useTranslation()
    const MomentApi = useMoment()
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const dataStore = useContext(DataContext)
    const TaskApi = useTaskApi()
    const [showComplited, setShowComplited] = useState(false)

    const baseOptions = useMaterialTableOptions()
    const options = {
        ...baseOptions,
        filtering: false,
        sorting: true,
        search: true,
        pageSize: 25
    }

    const defaultColumns = [
        { title: t("projects"), field: "project", filtering: true },
        { title: t("level"), field: "name", filtering: true },
        { title: t("dueToDate"), field: "dueToDate" },
        { title: t('week_number'), field: "week", filtering: true },
        { title: t('customer'), field: "user", filtering: true },
        { title: t('comment'), field: "comment" },
        { title: t('estimatedDuration') + "(h)", field: "estimatedDuration" },
        { title: t('duration') + "(h)", field: "duration", sorting: false },
    ]

    let title = "dashboard-tasks-in-weeks-table"
    const columns = LocalStorageHelper.get(generateLocalStorageKey(title, defaultColumns)) || defaultColumns

    const clearCache = () => {
        LocalStorageHelper.remove(generateLocalStorageKey(title, defaultColumns))
        forceUpdate();
    }

    const columnsHide = (column) => {
        let newColumns = [...columns];
        let found = newColumns.find(c => c.field == column.field);
        if (found) {
            found.hidden = column.hidden;
        }
        LocalStorageHelper.remove(generateLocalStorageKey(title, defaultColumns))
        LocalStorageHelper.set(generateLocalStorageKey(title, defaultColumns), newColumns)
    }

    let overrideColumns = [...columns];
    overrideColumns.forEach(c => {
        if (c.field == 'dueToDate') {
            c.render = (rowData) => {
                let mainColor = theme.palette.info.main
                let textColor = theme.palette.info.contrastText
                let date = ""
                let oneDay = (24 * 60 * 60 * 1000)

                if (!rowData?.dueToDate) {
                    mainColor = COLOR.LIGHT_2
                    textColor = theme.palette.getContrastText(COLOR.LIGHT_2)
                }
                if (rowData?.dueToDate && /*!MomentApi.isDateAfterNow(rowData.dueToDate))*/
                    moment(rowData?.dueToDate).valueOf() < (moment() + (URGENT_DAYS * oneDay))) {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (rowData?.dueToDate && !MomentApi.isDateAfterNow(rowData.dueToDate)) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }

                if (rowData?.done && rowData?.dueToDate) {
                    mainColor = theme.palette.success.dark
                    textColor = theme.palette.info.contrastText
                }

                if (rowData?.dueToDate != null) {
                    date = moment(rowData?.dueToDate).format("DD.MM.YYYY")
                } else {
                    date = t('no_dueToDate_set')
                }
                return <Chip style={{ backgroundColor: mainColor, color: textColor }} label={date} 
                />
            }
        } else if (c.field == 'week') {
            c.render = (rowData) => {
                let week = moment(rowData.dueToDate).format('W') + " " + t('vko')
                let oneDay = (24 * 60 * 60 * 1000)
                let mainColor = theme.palette.info.main
                let textColor = theme.palette.info.contrastText

                if (!rowData?.dueToDate) {
                    mainColor = COLOR.LIGHT_2
                    textColor = theme.palette.getContrastText(COLOR.LIGHT_2)
                }
                if (rowData?.dueToDate && /*!MomentApi.isDateAfterNow(rowData.dueToDate))*/
                    moment(rowData?.dueToDate).valueOf() < (moment() + (URGENT_DAYS * oneDay))) {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (rowData?.dueToDate && !MomentApi.isDateAfterNow(rowData.dueToDate)) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }
                if (rowData?.done && rowData?.dueToDate) {
                    mainColor = theme.palette.success.dark
                    textColor = theme.palette.info.contrastText
                }
                if (rowData?.dueToDate == null) {
                    week = t('no_dueToDate_set')
                }
                return (
                    <Chip 
                        style={{ backgroundColor: mainColor, color: textColor }} 
                        label={week} 
                    />
                )
            }
        } else if (c.field == 'duration') {
            c.render = (rowData) => {
                return <CountUsedDuration task={rowData} />
            }
        } 
    })

    let actions = [
        {
            key: "clear",
            icon: () => {
                return <CachedIcon />
            },
            tooltip: t('clear_cache'),
            isFreeAction: true,
            onClick: clearCache
        },
        {
            icon: () => {
                if (showComplited) {
                    return <VisibilityOffIcon />
                } else {
                    return <VisibilityIcon style={{ color: COLOR.GREEN }} />
                }
            },
            tooltip: showComplited ? t('hide_tasks_from_past') : t('show_tasks_from_past'),
            isFreeAction: true,
            onClick: () => {
                setShowComplited(!showComplited)
                dataStore.mobXStore.updateParent = !dataStore.mobXStore.updateParent
            }
        }
    ]

    const getProjectName = (task) => {
        if (task?.parentTask) {
            return getProjectName(task.parentTask)
        } else {
            return task.name
        }
    }

    const getProjectCustomer = (task) => {
        if (task?.parentTask) {
            return getProjectCustomer(task.parentTask)
        } else {
            return task.user
        }
    }

    const getProjectLevel = (task) => {
        if (task?.taskType == TaskTypes.commonToDo() && task?.parentTask) {
            task.name = task.parentTask.name + " - " + task.name
        } else if (task?.taskType == TaskTypes.todo() && task?.parentTask && task?.parentTask?.parentTask) {
            task.name = task.parentTask.parentTask.name + " - " + task.parentTask.name + " - " + task.name
        }
        return task.name
    }

    const calcResult = (array) => {
        array.forEach(task => {
            task.name = getProjectLevel(task)
            task.project = getProjectName(task)
            task.user = getProjectCustomer(task)
        })
        return array
    }


    const ajax = (params) => {
        return TaskApi.getAllTasksInWeeks(params, columns, showComplited)
            .then(res => {
                let calculatedResult = calcResult(res)
                return (
                    {
                        totalCount: res.count,
                        page: params.page,
                        data: calculatedResult
                    }
                )
            })
    }

    let result = (
        <Container maxWidth="xl" style={{ marginTop: 10 }}>
            <DataTable
                options={options}
                title={t("tasks_in_weeks")}
                columns={overrideColumns}
                data={ajax}
                icons={MaterialTableIcons}
                actions={actions}
                columnsHide={columnsHide}
                update={dataStore.mobXStore.updateParent}
            />
        </Container>
    )
    return result
}
export default observer(DashboardTasksInWeeksTable)
