import React, { useContext } from "react"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { Chip, Container } from "@material-ui/core"
import MaterialTableIcons from '../MaterialTable/MaterialTabelIcons'
import useMaterialTableOptions from "../MaterialTable/useMaterialTableOptions"
import { theme, COLOR } from "../../hooks/theme/useAppTheme"
import { DataTable } from '../MaterialTable/DataTable';
import WorkHoursTableDetailPanel from './WorkHoursTableDetailPanel'
import useMoment from '../../hooks/useMoment'
import { URGENT_DAYS, SALE, PRODUCTION, SERVICE } from '../../Constants'
import useTaskApi from '../../hooks/Apis/useTaskApi'
import CountUsedDuration from './CountUsedDuration';
import { DataContext } from "../../context/DataContext"

const WorkHoursTable = (props) => {
    const { values } = props
    const { t } = useTranslation()
    const MomentApi = useMoment()
    const TaskApi = useTaskApi()
    const store = useContext(DataContext)

    const baseOptions = useMaterialTableOptions()
    const options = {
        ...baseOptions,
        filtering: false,
        sorting: false,
        search: false,
        pageSize: 25,
        paging: true,
        columnsButton: false,
        toolbar: false
    }

    const defaultColumns = [
        { title: t("name"), field: "name" },
        {
            title: t("dueToDate"), field: "dueToDate", render: (rowData) => {
                return moment(rowData.dueToDate).format("DD.MM.YYYY")
            }
        },
        { title: t("customer"), field: "user" },
        { title: t("size"), field: "unit" },
        { title: t('estimatedDuration') + "(h)", field: "estimatedDuration" },
        { title: t('duration') + "(h)", field: "duration", sorting: false },
        { title: t('sale'), field: "sale", sorting: false },
        { title: t('production'), field: "production", sorting: false },
        { title: t('service'), field: "service", sorting: false }
    ]

    let columns = defaultColumns
    let overrideColumns = [...columns];
    overrideColumns.forEach(c => {
        if (c.field == 'dueToDate') {
            c.render = (rowData) => {
                let mainColor = theme.palette.info.main
                let textColor = theme.palette.info.contrastText
                let date = ""
                let oneDay = (24 * 60 * 60 * 1000)

                if (!rowData?.dueToDate) {
                    mainColor = COLOR.LIGHT_2
                    textColor = theme.palette.getContrastText(COLOR.LIGHT_2)
                }
                if (rowData?.dueToDate && /*!MomentApi.isDateAfterNow(rowData.dueToDate))*/
                    moment(rowData?.dueToDate).valueOf() < (moment() + (URGENT_DAYS * oneDay))) {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (rowData?.dueToDate && !MomentApi.isDateAfterNow(rowData.dueToDate)) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }
                if (rowData?.done && rowData?.dueToDate) {
                    mainColor = theme.palette.success.dark
                    textColor = theme.palette.info.contrastText
                }

                if (rowData?.dueToDate != null) {
                    date = moment(rowData?.dueToDate).format("DD.MM.YYYY")
                } else {
                    date = t('no_dueToDate_set')
                }
                return <Chip style={{ backgroundColor: mainColor, color: textColor }} label={date} />
            }
        } else if (c.field == 'duration') {
            c.render = (rowData) => {
                return <CountUsedDuration task={rowData} />
            }
        } else if (c.field == 'sale') {
            c.render = (rowData) => {
                let mainColor = null
                let textColor = null
                if (rowData?.sale?.count == rowData?.sale?.totalCount) {
                    mainColor = theme.palette.success.dark
                    textColor = theme.palette.info.contrastText
                } else {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (rowData?.sale?.count == 0) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }
                if (rowData?.sale) {
                    return <Chip
                        style={{ backgroundColor: mainColor, color: textColor }}
                        label={rowData.sale.count + "/" + rowData.sale.totalCount} />
                }
            }
        } else if (c.field == 'production') {
            c.render = (rowData) => {
                let mainColor = null
                let textColor = null
                if (rowData?.production?.count == rowData?.production?.totalCount) {
                    mainColor = theme.palette.success.dark
                    textColor = theme.palette.info.contrastText
                } else {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (rowData?.production?.count == 0) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }
                if (rowData?.production) {
                    return <Chip
                        style={{ backgroundColor: mainColor, color: textColor }}
                        label={rowData.production.count + "/" + rowData.production.totalCount} />
                }
            }
        } else if (c.field == 'service') {
            c.render = (rowData) => {
                let mainColor = null
                let textColor = null
                if (rowData?.service?.count == rowData?.service?.totalCount) {
                    mainColor = theme.palette.success.dark
                    textColor = theme.palette.info.contrastText
                } else {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (rowData?.service?.count == 0) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }
                if (rowData?.service) {
                    return <Chip
                        style={{ backgroundColor: mainColor, color: textColor }}
                        label={rowData.service.count + "/" + rowData.service.totalCount} />
                }
            }
        }
    })


    let actions = [
        /*   {
               icon: () => {
                   return (
                       <EditIcon className={classes.editIcon} />
                   )
               },
               tooltip: t('edit_schedule'),
               isFreeAction: false,
               onClick: editSchedule
           }*/
    ]

    let detailPanel = [
        {
            tooltip: t('show_details'),
            render: (rowData) => {
                let result = null
                result = (
                    <WorkHoursTableDetailPanel
                        data={rowData.childTasks}
                    />
                )
                return result
            }
        }
    ]

    const calcResult = (array) => {
        array.forEach(task => {
            task.sale = {
                count: 0,
                totalCount: 0
            }
            task.production = {
                count: 0,
                totalCount: 0
            }
            task.service = {
                count: 0,
                totalCount: 0
            }
            task.childTasks.forEach(childTasks => {
                childTasks.childTasks.forEach(innerChild => {
                    if (innerChild.helperBaseName == SALE) {
                        task.sale.totalCount++
                        task.sale.count += innerChild.done
                    } else if (innerChild.helperBaseName == PRODUCTION) {
                        task.production.totalCount++
                        task.production.count += innerChild.done
                    } else if (innerChild.helperBaseName == SERVICE) {
                        task.service.totalCount++
                        task.service.count += innerChild.done
                    }
                })
            })
        })
        return array
    }

    const ajax = (params) => {
        return TaskApi.getWorkHours(params, values)
            .then(res => {
                store.setTasks(res)
                let calculatedResult = calcResult(res)
                return (
                    {
                        totalCount: calculatedResult.count,
                        page: params.page,
                        data: calculatedResult
                    }
                )
            })
    }


    let result = (
        <Container maxWidth="xl" style={{ marginTop: 10 }}>
            <DataTable
                options={options}
                columns={overrideColumns}
                data={ajax}
                icons={MaterialTableIcons}
                actions={actions}
                detailPanel={detailPanel}
                update={values}
            />
        </Container>
    )
    return result
}
export default WorkHoursTable