import React, { useEffect, useState } from 'react'
import { Typography, Container, Grid, Card, CardActions, CardContent, Button, CircularProgress, Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import { useComponentState, ApiError, Spinner } from '@collapick/utils';
import useCustomerApi from '../../hooks/Apis/useCustomerApi';
import { makeStyles } from '@material-ui/core/styles';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';

const useStyles = makeStyles({
    root: {
        textAlign: "center"
    },
    media: {
        height: 150,
        objectFit: 'none'
    }
});

const CustomerInfoDialog = (props) => {
    const { task } = props
    const { t } = useTranslation();
    const { setLoading, setError, componentState } = useComponentState()
    const CustomerApi = useCustomerApi()
    const [customer, setCustomer] = useState(null)
    const classes = useStyles()

    useEffect(() => {
        getCustomerData()
    }, [task])

    const getCustomerData = () => {
        setLoading(true)
        let filter = {
            where: {
                uuid: task?.userId
            }
        }
        return CustomerApi.useApi.find({ filter: JSON.stringify(filter) })
            .then(res => {
                if (res?.length > 0) {
                    setCustomer(res[0])
                }
            })
            .catch(setError)
            .finally(() => {
                setLoading(false)
            })
    }



    return (
        <>
            {componentState.error && (<Grid item xs={12}><ApiError error={componentState.error} /></Grid>)}
            {componentState.loading ?
                <Spinner />
                :
                <Container >
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            {customer && <Card raised fullWidth className={classes.root}>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {customer.name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {customer.address}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {customer.postcode + " " + customer.city}
                                    </Typography>
                                </CardContent>
                                <CardActions className={classes.actions}>
                                    <Grid container spacing="1">
                                        <Grid item xs={12}>
                                            <Tooltip title={t('call_to_customer')} >
                                                <Button
                                                    disabled={!customer?.description}
                                                    fullWidth
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={() => { window.open('tel://' + customer.description) }}
                                                >
                                                    <PhoneInTalkIcon />
                                                    {customer?.description}
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>}
                        </Grid>
                    </Grid>
                </Container>
            }
        </>
    )
}

CustomerInfoDialog.defaultProps = {

}

export default CustomerInfoDialog

