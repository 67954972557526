import React from 'react'
import { useTranslation } from 'react-i18next';
import CustomCustomerField from '../components/Form/CustomCustomerField';
import { FormElements } from '@collapick/form';
import CustomAddPartsToProductionField from '../components/Form/CustomAddPartsToProductionField';

const useNewProjectCreationSchema = (props) => {
    const { sizeArray, customers } = props
    const { t } = useTranslation();

    let creationSchema = {
        id: "creationSchema",
        elements: [
            {
                type: "grid",
                direction: 'row',
                spacing: 2,
                elements: [
                    {

                        type: "text",
                        name: 'name',
                        label: t('name'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: "customCustomerField",
                        name: "customer",
                        label: t('customer'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('customer_is_required')
                                }
                            },
                        ]
                    },
                    {

                        type: "select",
                        name: 'unit',
                        label: t('project_size'),
                        item: {
                            xs: 12
                        },
                        options:
                            sizeArray.map(size => {
                                return { value: size, label: size }
                            })

                    },
                    {

                        type: "date",
                        name: 'dueToDate',
                        label: t('due_to_date'),
                        item: {
                            xs: 12,
                        },
                        elementProps: {
                            inputVariant: "outlined"
                        },
                    },
                    {
                        type: "customAddPartsToProductionField",
                        name: "parts",
                        label: t('parts'),
                        item: {
                            xs: 12
                        },
                    },
                    {
                        type: 'grid'
                    }
                ]
            }

        ]
    }

    const customElements = Object.assign(
        FormElements,
        {
            "customCustomerField": {
                component: (props) => {
                    return (
                        <>
                            { customers &&
                                <CustomCustomerField customers={customers} {...props} />}
                        </>
                    )
                }
            }
        },
        {
            "customAddPartsToProductionField": {
                component: (props) => {
                    return (
                        <>
                            <CustomAddPartsToProductionField {...props} />
                        </>
                    )
                }
            }
        },
    )

    return {
        creationSchema,
        customElements
    }
}

export default useNewProjectCreationSchema;
