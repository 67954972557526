import React from 'react'
import { CollapickForm } from '@collapick/form';
import { Grid, Box, Button, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import useNewCustomProjectCreationSchema from '../../FormSchemas/useNewCustomProjectCreationSchema';

const NewCustomProjectCreationForm = (props) => {
    const { title, onSave, onCancel, sizeArray, customers } = props
    const { t } = useTranslation()
    const { customCreationSchema, customElements } = useNewCustomProjectCreationSchema({ sizeArray, customers });

    return (
        <>
            <Grid container justify="center" >
                <Box my={2} >
                    <Typography variant="h2">{title}</Typography>
                </Box>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <CollapickForm
                        formSchema={customCreationSchema}
                        formElements={customElements}
                        onFormSubmit={onSave}
                        resetFormAfterSubmit={true}
                        renderSubmitButton={() =>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                fullWidth>
                                {t('finish')}
                            </Button>}
                        showInvalidSaveButton={true}
                        invalidSubmitText={t("unfinish")}
                        onInvalidSubmit={onCancel}
                    />
                </Grid>
            </Grid>
        </>
    )
}
export default NewCustomProjectCreationForm