import { useTranslation } from 'react-i18next';

const useAddVideoSchema = (props) => {
    const { t } = useTranslation();

    let addVideoSchema = {
        id: "storagesSchema",
        elements: [
            {
                type: "grid",
                direction: 'row',
                spacing: 2,
                elements: [
                    {

                        type: "text",
                        name: 'url',
                        label: t('url'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('url_is_required')
                                }
                            },
                        ]
                    },
                    {
                        type: 'grid'
                    }
                ]
            }

        ]
    }

    return {
        addVideoSchema,
    }

}

export default useAddVideoSchema;
