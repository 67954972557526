import React, { useState, useEffect } from 'react'
import { Typography, CircularProgress, Box, Grid, Paper, Container } from '@material-ui/core';
import { theme, COLOR } from '../../hooks/theme/useAppTheme'
import { makeStyles } from "@material-ui/core/styles"
import { useTranslation } from 'react-i18next';
import moment from "moment"
import { PRODUCTION, SERVICE, SALE } from '../../Constants'

const CircularProgressWithLabel = (props) => {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress
                variant="determinate"
                style={{ color: theme.palette.primary.dark }}
                size={150}
                thickness={5}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="determinate"
                size={150}
                style={{ color: COLOR.mobileProgress, position: 'absolute' }}
                thickness={5}
                {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="h5" style={{ color: COLOR.WHITE }}>{props.parts}</Typography>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles((t) => (
    {
        root: props => ({
            backgroundColor: props.color,
            paddingBottom: "5vh",
            paddingTop: "5vh"
        }),
        typography: {
            color: COLOR.WHITE
        },
        circular: {
            textAlign: "center",
            alignSelf: "center"
        },
        row: {
            justifyContent: "flex-start",
            display: "flex",
            paddingBottom: "1.5vh",
            paddingTop: "1.5vh"
        }
    }))

const MobileProgress = (props) => {
    const { count, task } = props
    const [progress, setProgress] = useState(0)
    const [parts, setParts] = useState("")

    let color = null
    if (task?.helperBaseName == PRODUCTION) {
        color = theme.palette.primary.main
    } else if (task?.helperBaseName == SALE) {
        color = theme.palette.success.main
    } else if (task?.helperBaseName == SERVICE) {
        color = theme.palette.secondary.main
    } else {
        color = theme.palette.primary.main
    }

    const classes = useStyles({ color })
    const { t } = useTranslation()

    useEffect(() => {
        setValues()
    }, [count])

    const setValues = () => {
        let percents = ((count.doneCount / count.totalCount) * 100).toFixed(0)
        setProgress(percents)
        let string = count.doneCount + "/" + count.totalCount
        setParts(string)
    }

    let result = (
        <Container className={classes.root} maxWidth="false">
            <Grid container spacing={2} direction="row" >
                <Grid item xs={6} justify="center" className={classes.circular}>
                    {progress && parts && <CircularProgressWithLabel value={progress} parts={parts} />}
                </Grid>
                <Grid item xs={6} justify="center" style={{ alignSelf: "center" }}>
                    <Grid container direction="row" spacing={2} >
                        <Grid item xs={12}>
                            {task?.comment && <Typography variant="subtitle1" className={classes.typography}>
                                {'"' + task.comment + '"'}
                            </Typography>}
                            {task?.dueToDate && <Typography variant="subtitle1" className={classes.typography}>
                                {t('completed_at') + ": " + moment(task?.dueToDate).format("DD.MM.YYYY")}
                            </Typography>}
                        </Grid>

                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                        <Paper
                            style={{ backgroundColor: COLOR.mobileProgress, minWidth: "5vh", marginRight: "2vh" }}
                            elevation={3} />
                        <Typography variant="h5" className={classes.typography}>{t('finished')}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                        <Paper
                            style={{ backgroundColor: theme.palette.primary.dark, minWidth: "5vh", marginRight: "2vh" }}
                            elevation={3} />
                        <Typography variant="h5" className={classes.typography}>{t('unfinished')}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )

    return result

}

MobileProgress.defaultProps = {
    task: {
        description: "jotain hienoa tekstiä tähän joopa jooo",
        dueToDate: "2021-01-13 12:48:13.000000"
    },
    count: {
        doneCount: 1,
        totalCount: 3
    }
}

export default MobileProgress