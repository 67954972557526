import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import useRoutes from "../../hooks/useRoutes";
import { useHistory } from "react-router-dom";
import { Grid, makeStyles, MenuItem, Menu } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { theme } from '../../hooks/theme/useAppTheme'
//import JoentaituritLogo from './Picture/JoentaituritLogo.jpg'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        //marginBottom: "8px"
    },
    bar: {
        backgroundColor: theme.palette.primary.dark
    },
    logo: {

    }
}))

const DashboardHeader = (props) => {
    const classes = useStyles()
    const { t } = useTranslation();
    const routes = useRoutes();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const { PATH } = useRoutes()

    const onLogout = () => {
        history.push(routes.PATH.logout)
    }


    const goBack = () => {
        history.goBack()
    }


    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const menuItems = () => {
        return (
            <div>
                <MenuItem
                    onClick={() => {
                        history.push(PATH.dashboard.projects)
                        handleCloseMenu()
                    }}>{t("projects")}</MenuItem>
                <MenuItem
                    onClick={() => {
                        history.push(PATH.dashboard.customers)
                        handleCloseMenu()
                    }}>{t("customers")}</MenuItem>
                <MenuItem
                    onClick={() => {
                        history.push(PATH.dashboard.projectsizes)
                        handleCloseMenu()
                    }}>{t("project_sizes")}</MenuItem>
                <MenuItem
                    onClick={() => {
                        history.push(PATH.dashboard.defaultattachments)
                        handleCloseMenu()
                    }}>{t("default_attachments")}</MenuItem>
                <MenuItem
                    onClick={() => {
                        history.push(PATH.dashboard.productionforms)
                        handleCloseMenu()
                    }}>{t("production_forms")}</MenuItem>
                <MenuItem
                    onClick={() => {
                        history.push(PATH.dashboard.infovideos)
                        handleCloseMenu()
                    }}>{t("info_videos")}</MenuItem>
                <MenuItem
                    onClick={() => {
                        history.push(PATH.dashboard.usersview)
                        handleCloseMenu()
                    }}>{t("users")}</MenuItem>
                <MenuItem
                    onClick={() => {
                        history.push(PATH.dashboard.feedbackform)
                        handleCloseMenu()
                    }}>{t("feedback_form")}</MenuItem>
                <MenuItem
                    onClick={() => {
                        history.push(PATH.dashboard.feedbacksummary)
                        handleCloseMenu()
                    }}>{t("feedback_summary")}</MenuItem>
                <MenuItem
                    onClick={() => {
                        onLogout()
                        handleCloseMenu()
                    }}>{t("logout")}</MenuItem>

            </div>
        )
    }

    return (
        <AppBar className={classes.bar} position="static">
            <Toolbar>
                <Grid justify="flex-start" container wrap={"nowrap"} alignItems="center" spacing={2}>
                    {history.location.pathname == ('/dashboard/projects') ?
                        <Typography variant="h6" >
                            {t('Joentaiturit')}
                        </Typography>
                        :
                        <IconButton onClick={goBack}>
                            <ArrowBackIcon style={{ color: theme.palette.primary.contrastText }}
                            /></IconButton>}

                </Grid>
                <Grid container justify="flex-end" >
                    <Button onClick={handleClickMenu}>
                        <MenuIcon style={{ color: theme.palette.primary.contrastText }} />
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        transformOrigin={{ vertical: "top", horizontal: "center" }}

                    >
                        {menuItems()}
                    </Menu>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

DashboardHeader.defaultProps = {}
export default DashboardHeader
