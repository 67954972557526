import React, { useState, useContext, useEffect } from 'react'
import { DataContext } from '../../context/DataContext'
import { observer } from "mobx-react-lite"
import { Checkbox, Grid, InputLabel, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';


const DefaultAttachmentsSelection = (props) => {
    const { defaultAttachments, checked, setChecked } = props
    const dataStore = useContext(DataContext)
    const initialValues = dataStore.mobXStore.initialValues
    const { t } = useTranslation()

    useEffect(() => {
        let checked = []
        defaultAttachments.forEach(t => {
            if (Array.isArray(initialValues.taskObject) && initialValues.taskObject.includes(t.uuid)) {
                checked.push(t.uuid)
            }
        })
        setChecked(checked)
    }, [initialValues])

    const handleChange = (e) => {
        let newChecked = [...checked]
        if (checked.includes(e.target.value)) {
            let index = checked.indexOf(e.target.value)
            newChecked.splice(index, 1)
        } else {
            newChecked.push(e.target.value)
        }
        setChecked(newChecked)
    }

    const checkBoxes = defaultAttachments.map((t, i) => {
        return (
            <Grid item xs={3} key={t.uuid} style={{ textAlign: "center" }}>
                <InputLabel>{t.name}</InputLabel>
                <Checkbox
                    checked={checked.includes(t.uuid)}
                    label={t.name}
                    value={t.uuid}
                    onChange={handleChange}
                    name={t.name}
                    type="checkbox" />
            </Grid>
        )
    })

    return (
        <>
            <InputLabel style={{ marginBottom: "10px" }}>
                {t('default_attachments')}
            </InputLabel>
            <Grid container direction="row" >
                {checkBoxes}
            </Grid>
        </>
    )
}

export default observer(DefaultAttachmentsSelection)