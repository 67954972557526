import React, { useEffect, useContext, useState } from 'react'
import { useComponentState, ApiError, Spinner } from '@collapick/utils';
import useDynamicDataApi from '../../../../hooks/Apis/useDynamicDataApi';
import { useHistory } from 'react-router-dom';
import { DataContext } from '../../../../context/DataContext'
import EditDialog from '../../../../components/Dialogs/EditDialog';
import AddFilesDialog from '../../../../components/Dialogs/AddFilesDialog'
import ConfirmDialog from '../../../../components/Dialogs/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import useTaskApi from '../../../../hooks/Apis/useTaskApi';
import DashboardWorksTable from '../../../../components/Table/DashboardWorksTable';

const WorksView = (props) => {
    const { componentState, setLoading, setError } = useComponentState()
    const DynamicDataApi = useDynamicDataApi()
    const history = useHistory()
    const dataStore = useContext(DataContext)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [projectToDelete, setProjectToDelete] = useState("")
    const { t } = useTranslation()
    const TaskApi = useTaskApi()

    useEffect(() => {
        getSizes()
    }, [])

    const getSizes = () => {
        setLoading(true)
        return DynamicDataApi.getSizes()
            .then(res => {
                dataStore.mobXStore.sizeArray = res
            })
            .catch(setError)
            .finally(setLoading(false))
    }

    const addWork = () => {
        history.push('/dashboard/newwork')
    }

    const deleteWork = (e, rowData) => {
        setProjectToDelete(rowData)
        setConfirmOpen(true)
    }

    const deleteWholeProject = () => {
        setLoading(true)
        return TaskApi.deleteWork(projectToDelete)
            .catch(setError)
            .finally(() => {
                setLoading(false)
            })
    }

    let result = null
    if (componentState.loading) {
        result = <Spinner enabled={componentState.loading} />
    } else if (componentState.error) {
        result = <ApiError error={componentState.error} />
    } else {
        result = (
            <>
                {<DashboardWorksTable
                    addWork={addWork}
                    deleteWork={deleteWork}
                />}
                <EditDialog />
                <AddFilesDialog />
                <ConfirmDialog open={confirmOpen}
                    setOpen={setConfirmOpen}
                    title={t('delete_work')}
                    content={projectToDelete?.name}
                    agreeCallback={() => {
                        deleteWholeProject()
                        setConfirmOpen(false);
                    }}
                    disagreeCallback={() => {
                        setConfirmOpen(false);
                    }} />
            </>
        )
    }
    return result
}

export default WorksView