import React, { useState, useEffect, useContext } from 'react'
import { DataContext } from '../../context/DataContext'
import { Paper, Grid, InputLabel } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import { useTypes } from '../../Constants'

const WorkHoursCalculation = (props) => {
    const { values } = props
    const store = useContext(DataContext)
    const { t } = useTranslation()
    const [average, setAverage] = useState(0)
    const types = useTypes()

    useEffect(() => {
        calcAverage()
    }, [values, store?.tasks])

    let choosedTypes = (values?.type)
    let keys = []

    Object.keys(types).forEach(key => {
        if (choosedTypes?.includes(types[key])) {
            keys.push(key)
        }
    })

    const calcAverage = () => {
        let duration = 0
        store?.tasks.forEach(pT => {
            pT.childTasks.forEach(cT1 => {
                cT1.childTasks.forEach(cT2 => {
                    cT2.childTasks.forEach(cT3 => {
                        if (values?.type?.length > 0) {
                            if (keys.includes(cT3.helperBaseName.toUpperCase())) {
                                if (values?.level?.length > 0) {
                                    if (values?.level.includes(cT3.parentTask.name)) {
                                        if (cT3.duration == null) {
                                            cT3.duration = 0
                                        }
                                        duration += parseInt(cT3.duration)
                                    }
                                }
                                else {
                                    if (cT3.duration == null) {
                                        cT3.duration = 0
                                    }
                                    duration += parseInt(cT3.duration)
                                }
                            }
                        } else if (values?.level?.length > 0) {
                            if (values?.level.includes(cT3.parentTask.name)) {
                                if (cT3.duration == null) {
                                    cT3.duration = 0
                                }
                                duration += parseInt(cT3.duration)
                            }
                        } else {
                            if (cT3.duration == null) {
                                cT3.duration = 0
                            }
                            duration += parseInt(cT3.duration)
                        }
                    })
                })
            })
        })
        let avg = duration / store.tasks.length
        setAverage(avg.toFixed(0))
    }

    return (
        <Grid container style={{ paddingLeft: "50px", paddingRight: "50px", marginTop: "20px" }}>
            <Grid item xs={12}>
                <InputLabel>{t('result')}</InputLabel>
                <Paper
                    elevation={3}
                    fullWidth
                    variant="outlined"
                    style={{ padding: "16px", height: "60px" }}
                >
                    {t('targets') + ": " + store?.tasks.length + " " + t('kpl') +
                        ",  " + t('average') + " " + average + " h"}
                </Paper>
            </Grid>
        </Grid >
    )
}

export default WorkHoursCalculation