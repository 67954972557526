import React from 'react'
import { Container, Grid, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import { DataTable } from '../MaterialTable/DataTable';
import EditIcon from '@material-ui/icons/Edit';
import { COLOR } from '../../hooks/theme/useAppTheme'
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

const CustomersTable = (props) => {
    const { title, data, editCallback, addCallback, deleteCallback } = props
    const { t } = useTranslation()

    const defaultColumns = [
        { title: t('name'), field: 'name' },
        { title: t('phone'), field: 'description' },
        { title: t('address'), field: 'address' },
        { title: t('postcode'), field: 'postcode' },
        { title: t('city'), field: 'city' },
        { title: t('company_id'), field: 'companyId' },
        { title: t('email'), field: 'email' },
    ]

    const options = {
        pageSize: 25,
        pageSizeOptions: [10, 25, 50, 100],
        paginationType: 'normal',
        removable: false,
        search: true,
        sorting: false,
        actionsColumnIndex: defaultColumns.length + 1,
        showTitle: true,
        exportButton: false,
        filtering: false,
        paging: true,
        columnsButton: false
    }

    const actions = [
        {
            icon: () => {
                return < EditIcon style={{ color: COLOR.GREEN }} />
            },
            tooltip: t('edit_customer'),
            onClick: editCallback
        },
        {
            icon: () => {
                return < DeleteIcon style={{ color: COLOR.GREEN }} />
            },
            tooltip: t('delete_customer'),
            onClick: deleteCallback
        },
        {
            icon: () => {
                return (
                    <Button variant="contained">
                        < AddIcon style={{ color: COLOR.GREEN }} />
                        {t('add_new_customer')}
                    </Button>
                )
            },
            tooltip: t('add_new_customer'),
            isFreeAction: true,
            onClick: addCallback
        }
    ]

    let result = (
        <Container maxWidth="xl" style={{ marginTop: 10 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DataTable
                        columns={defaultColumns}
                        data={data}
                        actions={actions}
                        options={options}
                        title={title} />
                </Grid>
            </Grid>
        </Container>
    )

    return result
}

export default CustomersTable