import React from 'react'
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

const useEditUserSchema = (props) => {
    const { t } = useTranslation();

    const longerThan = (value) => {
        var correct = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/
        if (value.match(correct)) {
            return true
        }
        return false
    }

    const customValidators = {
        "email_validator": {
            defaultMessage: "Not accepted",
            validator: (options, value, allValues) => {
                let isValid = false
                if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(value)) {
                    isValid = true
                }
                return isValid
            }
        },
        "my_validator": {
            defaultMessage: "Not accepted",
            validator: (options, value, allValues) => {
                let isValid = false
                if (longerThan(value)) {
                    isValid = true
                }
                return isValid
            }
        }
    }


    let editUserSchema = {
        id: "edit",
        elements: [
            {
                type: "grid",
                elementProps: {
                    spacing: 4
                },
                elements: [
                    {
                        type: 'grid',
                        direction: 'column',
                        spacing: 2,
                        elements: [
                            {
                                type: 'text',
                                name: 'username',
                                label: t('full_name'),
                                item: {
                                    xs: 12
                                },
                                validators: [
                                    {
                                        name: 'required',
                                        config: {
                                            msg: t('name_is_required')
                                        }
                                    }
                                ]
                            },
                            {
                                type: 'text',
                                name: 'email',
                                label: t('email'),
                                item: {
                                    xs: 12
                                },
                                validators: [
                                    {
                                        name: "email_validator",
                                        config: {
                                            msg: t("email_is_not_valid")
                                        }
                                    },
                                    {
                                        name: 'required',
                                        config: {
                                            msg: t('email_is_required')
                                        }
                                    },

                                ]
                            },
                            {
                                type: 'text',
                                name: 'password',
                                label: t('password'),
                                item: {
                                    xs: 12
                                },
                                validators: [
                                    {
                                        name: 'required',
                                        config: {
                                            msg: t('password_is_required')
                                        }
                                    },
                                    {
                                        name: "my_validator",
                                        config: {
                                            msg: t("password_needs_to_be_longer_than_6_marks_and_includes_small_and_big_letters_and_other_marks")
                                        }
                                    }
                                ]
                            },
                            {
                                type: 'radio',
                                name: 'role',
                                label: t('choose_role_for_user'),
                                options: [
                                    {
                                        value: 1,
                                        label: t('admin'),
                                    },
                                    {
                                        value: 2,
                                        label: t('user'),
                                    },
                                ],
                                item: {
                                    xs: 12
                                },
                                validators: [
                                    {
                                        name: 'required',
                                        config: {
                                            msg: t('your_answer_is_required')
                                        }
                                    }
                                ]
                            },
                            {
                                type: 'grid',
                                direction: 'column',
                                spacing: 2,
                            }
                        ]
                    },
                ]
            }
        ]
    }

    return {
        editUserSchema,
        customValidators
    }

}

export default useEditUserSchema;
