import React, { useContext, useState } from "react";

//Fixme: What options. dirty way to code. Function dosent explain are this car or table options
const useMaterialTableOptions = (moreOptions = {}) => {

    const defaults = {
        pageSize: 10,
        pageSizeOptions: [5, 10, 25, 50, 100],
        paginationType: 'normal',
        exportButton: false,
        columnsButton: true,
        search: false,
        emptyRowsWhenPaging: false,
        sorting: true,
        filtering: false,
        actionsColumnIndex: 99,
        thirdSortClick: false,
        rowStyle: { fontFamily: ["Roboto", "Helvetica", "Arial", 'sans-serif'] }
    }

    return Object.assign(defaults, moreOptions)
}

export default useMaterialTableOptions