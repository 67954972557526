
import { useTranslation } from 'react-i18next';
import useRoutes from "../../hooks/useRoutes";

const useTabs = () => {
    const { t } = useTranslation();
    const { PATH } = useRoutes()

    return (
        [
            {
                id: "/dashboard/projects",
                label: t("projects"),
                href: PATH.dashboard.projects,
            },
            {
                id: "/dashboard/services",
                label: t("in_service"),
                href: PATH.dashboard.services,
            },
            {
                id: "/dashboard/works",
                label: t("works"),
                href: PATH.dashboard.works,
            },
            {
                id: "/dashboard/workhours",
                label: t("work_hours"),
                href: PATH.dashboard.workhours,
            },
            {
                id: "/dashboard/tasksinweeks",
                label: t("tasks_in_weeks"),
                href: PATH.dashboard.tasksinweeks,
            },
        ]
    )
}

export default useTabs