import React, { useState, useEffect, useMemo } from 'react'
import useDynamicDataApi from '../../hooks/Apis/useDynamicDataApi';
import { useComponentState, ApiError, Spinner } from '@collapick/utils';
import { useTranslation } from 'react-i18next';
import { TextField, Grid, Button, Select, Typography } from '@material-ui/core';
import useFormApi from '../../hooks/Apis/useFormApi';
import { PRODUCTION_FORMS } from '../../Constants';


const ProductionFormSettings = (props) => {
    const { t } = useTranslation()
    const DynamicDataApi = useDynamicDataApi()
    const { componentState, setLoading, setError } = useComponentState()
    const [settings, setSettings] = useState([])
    const [forms, setForms] = useState([])
    const FormApi = useFormApi()

    useEffect(() => {
        getForms()
        getSettings()
    }, [])

    const getForms = () => {
        setLoading(true)
        return FormApi.getForms()
            .then(res => {
                setForms(res)
            })
            .catch(setError)
            .finally(() => {
                setLoading(false)
            })
    }
    
    const getSettings = () => {
        setLoading(true)
        return DynamicDataApi.getProductionFormsSettings()
            .then(res => {
                //setOriginalSizes(res)
                //let myArray = res.map((str, index) => ({ name: str }));
                setSettings(res)
            })
            .catch(setError)
            .finally(() => {
                setLoading(false)
            })
    }

    const onChangeLevel = (e, i) => {
        let array = [...settings]
        array[i][e.target.name] = e.target.value
        setSettings(array)
    }

    const addSetting = () => {
        let array = [...settings]
        array.push({})
        setSettings(array)
    }

    const saveSetting = (value, i) => {
        if (!value?.uuid) {
            setLoading(true)
            value.key = PRODUCTION_FORMS
            return DynamicDataApi.useApi.create(value)
                .then(res => {
                    console.log(res)
                    let array = [...settings]
                    array[i].uuid = res.uuid
                    setSettings(array)
                })
                .catch(setError)
                .finally(() => {
                    setLoading(false)
                   
                })
        } else {
            setLoading(true)
            let object = {
                name: value.name,
                stringValue: value.stringValue
            }
            return DynamicDataApi.useApi.updateById(value.uuid, object)
                .catch(setError)
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const deleteSetting = (value, i) => {
        setLoading(true)
        return DynamicDataApi.useApi.deleteById(value.uuid)
            .catch(setError)
            .finally(() => {
                let array = [...settings]
                array.splice(i, 1)
                setSettings(array)
                setLoading(false)
            })
    }

    const renderSettings = () => {
        return settings.map((s, i) => {
            return (
                <Grid container direction="row" key={s.uuid} spacing={2} alignItems="center">
                    <Grid item xs={4}>
                        {i == 0 ? <Typography>{t('LEVEL')}</Typography> : null}
                        <TextField
                            fullWidth
                            variant="outlined"
                            name={'name'}
                            id={s.uuid}
                            onChange={(e) => { onChangeLevel(e, i) }}
                            margin="dense"
                            value={s.name}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        {i == 0 ? <Typography>{t('FORM')}</Typography> : null}
                        <Select
                            fullWidth
                            variant="outlined"
                            name={'stringValue'}
                            id={s.uuid}
                            value={s.stringValue}
                            onChange={(e) => { onChangeLevel(e, i) }}
                            margin="dense">
                            {forms.map(f => {
                                return <option value={f.uuid} key={f.uuid}>{f.name}</option> 
                            })}
                        </Select>
                    </Grid>
                    <Grid item xs={4} style={{ display: "flex", padding: "20px" }}>
                        <Button 
                            variant="contained"
                            color="primary"
                            
                            onClick={() => { saveSetting(s, i) }}>
                            {t('save_setting')}
                        </Button>
                        <Button 
                            variant="contained"
                            color="secondary"
                            
                            onClick={() => { deleteSetting(s, i) }}>
                            {t('delete_setting')}
                        </Button>
                    </Grid>
                </Grid>
            )
        })
    }

    useMemo(() => {
        renderSettings()
        console.log(settings)
    }, [settings])

    let result = null
    if (componentState.loading) {
        result = <Spinner enabled={componentState.loading} />
    } else if (componentState.error) {
        result = <ApiError error={componentState.error} />
    } else {
        result = (
            <>
                {renderSettings()}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={addSetting}
                    fullWidth
                    style={{ marginTop: "40px" }}
                >{t('add_new_setting')}</Button>
            </>
        )
    }
    return result
}
ProductionFormSettings.defaultProps = {}


export default ProductionFormSettings