import React, { useState, useEffect } from 'react'
import { Grid, InputLabel, TextField, Button, Container } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

let items = []
const CustomAddPartsToProductionField = (props) => {
    const { input, label, initialValues } = props;
    const { t } = useTranslation();
    const [values, setValues] = useState([])

    const addItems = () => {
        let array = [...values]
        array.push("")
        setValues(array)
    }

    const removeItems = (i) => {
        let array = [...values]
        array.splice(i, 1)
        setValues(array)
        input.onChange(array)
    }

    const handleChange = (evt) => {
        let array = [...values]
        array[evt.target.id] = evt.target.value
        setValues(array);
        input.onChange(array)
    }

    items = values.map((v, i) => {
        return (
            <>
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={v}
                        type="text"
                        onChange={handleChange}
                        name={`part`}
                        id={i}
                    />
                </Grid>
                <Grid item xs={2}>
                    {i == (values.length - 1) &&
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" size="small" onClick={() => {
                                addItems()
                            }}>
                                <AddIcon />
                            </Button>
                        </Grid>}
                    <Grid item xs={12}>
                        <Button variant="contained" color="secondary" size="small" onClick={() => {
                            removeItems(i)
                        }}>
                            <RemoveIcon />
                        </Button>
                    </Grid>
                </Grid>
            </>
        )
    })



    return (<Container style={{ padding: 0 }}>
        <Grid container direction="row">
            {items?.length > 0 && <InputLabel>{label}</InputLabel>}
        </Grid>
        <Grid container direction="row" spacing={1}>
            {items?.length > 0 ? items : 
                <Button 
                    fullWidth 
                    variant='contained' 
                    color="primary"
                    style={{ marginTop: "15px" }}
                    onClick={() => setValues([""])}
                >
                    {t('split production to parts')}
                </Button>}
        </Grid>
    </Container>)
}

CustomAddPartsToProductionField.defaultProps = {

}

export default CustomAddPartsToProductionField