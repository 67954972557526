import React, { useState, useContext, useEffect } from 'react'
import { DataContext } from '../../context/DataContext'
import { observer } from "mobx-react-lite"
import { Checkbox, Grid, InputLabel, Typography, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CollapickForm } from '@collapick/form';
import Button from '@material-ui/core/Button';
import useEditWeekSchema from '../../FormSchemas/useEditWeekSchema';
import CloseIcon from '@material-ui/icons/Close';
import ContentDialog from './ContentDialog'

const PickWeekDialog = (props) => {
    const dataStore = useContext(DataContext)
    const { t } = useTranslation()
    const { editWeekSchema } = useEditWeekSchema();

    
    return (<>
        <ContentDialog
            actions={
                <IconButton color="primary" onClick={(() => { dataStore.mobXStore.openChangeWeek = (false) })} >
                    <CloseIcon />{('close')}
                </IconButton>}
            title={t('change_week')}
            open={dataStore.mobXStore.openChangeWeek}
            setOpen={dataStore.mobXStore.setOpenChangeWeek}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <CollapickForm
                        initialValues={dataStore.mobXStore.initialValues}
                        formSchema={editWeekSchema}
                        onFormSubmit={dataStore.mobXStore.onSaveChangeWeek}
                        resetFormAfterSubmit={true}
                        renderSubmitButton={() =>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                fullWidth>
                                {t('save')}
                            </Button>}
                    />
                </Grid>
            </Grid>
        </ContentDialog>
    </>)
}

export default observer(PickWeekDialog)