import React from "react"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { Container, Chip } from "@material-ui/core"
import MaterialTableIcons from '../MaterialTable/MaterialTabelIcons'
import useMaterialTableOptions from "../MaterialTable/useMaterialTableOptions"
import { theme, COLOR } from "../../hooks/theme/useAppTheme"
import { DataTable } from '../MaterialTable/DataTable';
import ScheduleProjectsTableDetailPanelLastLevel from './ScheduleProjectsTableDetailPanelLastLevel'
import useMoment from '../../hooks/useMoment'
import { URGENT_DAYS } from '../../Constants'

const ScheduleProjectsTableDetailPanel = (props) => {
    const { data, editSchedule } = props
    const { t } = useTranslation()
    const MomentApi = useMoment()

    const baseOptions = useMaterialTableOptions()
    const options = {
        ...baseOptions,
        filtering: false,
        sorting: false,
        search: false,
        pageSize: 25,
        paging: false,
        toolbar: false,
        header: false
    }

    const defaultColumns = [
        { field: "name" },
        {
            title: t("dueToDate"), field: "dueToDate", render: rowData => {
                let mainColor = theme.palette.info.main
                let textColor = theme.palette.info.contrastText
                let date = ""
                let oneDay = (24 * 60 * 60 * 1000)

                if (!rowData?.dueToDate) {
                    mainColor = COLOR.LIGHT_2
                    textColor = theme.palette.getContrastText(COLOR.LIGHT_2)
                }
                if (rowData?.dueToDate && /*!MomentApi.isDateAfterNow(rowData.dueToDate))*/
                    moment(rowData?.dueToDate).valueOf() < (moment() + (URGENT_DAYS * oneDay))) {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (rowData?.dueToDate && !MomentApi.isDateAfterNow(rowData.dueToDate)) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }
                if (rowData?.done && rowData?.dueToDate) {
                    mainColor = theme.palette.success.dark
                    textColor = theme.palette.info.contrastText
                }

                if (rowData?.dueToDate) {
                    date = moment(rowData?.dueToDate).format("DD.MM.YYYY")
                } else {
                    date = "-"
                }
                return <Chip style={{ backgroundColor: mainColor, color: textColor }} label={date} />
            }
        },
        {
            title: t('week_number'), field: 'week', render: (rowData) => {
                let week = null
                if (rowData?.dueToDate) {
                    week = moment(rowData.dueToDate).format('W') + " " + t('vko')
                } else {
                    week = "-"
                }
                let oneDay = (24 * 60 * 60 * 1000)
                let mainColor = theme.palette.info.main
                let textColor = theme.palette.info.contrastText

                if (!rowData?.dueToDate) {
                    mainColor = COLOR.LIGHT_2
                    textColor = theme.palette.getContrastText(COLOR.LIGHT_2)
                }
                if (rowData?.dueToDate &&
                    moment(rowData?.dueToDate).valueOf() < (moment() + (URGENT_DAYS * oneDay))) {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (rowData?.dueToDate && !MomentApi.isDateAfterNow(rowData.dueToDate)) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }
                return (
                    <Chip style={{ backgroundColor: mainColor, color: textColor }} label={week} />
                )
            }
        }
    ]

    let columns = defaultColumns

    let actions = [
        /*  {
              icon: () => {
                  return (
                      <EditIcon className={classes.editIcon} />
                  )
              },
              tooltip: t('edit_schedule'),
              isFreeAction: false,
              onClick: editSchedule
          }*/
    ]

    let detailPanel = [
        {
            tooltip: t('show_details'),
            render: (rowData) => {
                console.log(rowData)
                let result = null
                if (rowData.childTasks[0].childTasks) {
                    result = (
                        < ScheduleProjectsTableDetailPanel data={rowData.childTasks} editSchedule={editSchedule} />
                    )
                } else {
                    result = (
                        < ScheduleProjectsTableDetailPanelLastLevel data={rowData.childTasks} editSchedule={editSchedule} />
                    )
                }

                return result
            }
        }
    ]


    return (
        <Container maxWidth="xl" style={{ marginTop: 10 }}>
            <DataTable
                options={options}
                title={t("schedule")}
                columns={columns}
                data={data}
                icons={MaterialTableIcons}
                actions={actions}
                detailPanel={detailPanel}
            />
        </Container>
    )
}
export default ScheduleProjectsTableDetailPanel