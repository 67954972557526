import React, { useEffect, useState } from "react"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { Container, Chip } from "@material-ui/core"
import MaterialTableIcons from '../MaterialTable/MaterialTabelIcons'
import useMaterialTableOptions from "../MaterialTable/useMaterialTableOptions"
import { theme, COLOR } from "../../hooks/theme/useAppTheme"
import { DataTable } from '../MaterialTable/DataTable';
import useMoment from '../../hooks/useMoment'
import useTaskApi from '../../hooks/Apis/useTaskApi'
import { useComponentState, ApiError, Spinner } from '@collapick/utils';
import { URGENT_DAYS } from '../../Constants'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { TaskTypes } from '@collapick/ponniste-types';
import EmailIcon from '@material-ui/icons/Email';

const UrgentsTable = (props) => {
    const { title, taskId, moveToLevel, sendEmail } = props
    const { t } = useTranslation()
    const MomentApi = useMoment()
    const { componentState, setLoading, setError } = useComponentState()
    const TaskApi = useTaskApi()
    const [data, setData] = useState([])

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        setLoading(true)
        return TaskApi.getUrgentTasksForProject(taskId)
            .then(res => {
                setData(res)
            })
            .catch(setError)
            .finally(setLoading(false))

    }

    const baseOptions = useMaterialTableOptions()
    const options = {
        ...baseOptions,
        filtering: false,
        sorting: false,
        search: false,
        columnsButton: false,
        pageSize: 25
    }

    const defaultColumns = [
        {
            title: t("dueToDate"), field: "dueToDate", render: rowData => {
                let mainColor = theme.palette.info.main
                let textColor = theme.palette.info.contrastText
                let date = ""
                let oneDay = (24 * 60 * 60 * 1000)

                if (!rowData?.dueToDate) {
                    mainColor = COLOR.LIGHT_2
                    textColor = theme.palette.getContrastText(COLOR.LIGHT_2)
                }
                if (rowData?.dueToDate && /*!MomentApi.isDateAfterNow(rowData.dueToDate))*/
                    moment(rowData?.dueToDate).valueOf() < (moment() + (URGENT_DAYS * oneDay))) {
                    mainColor = theme.palette.warning.main
                    textColor = theme.palette.warning.contrastText
                }
                if (rowData?.dueToDate && !MomentApi.isDateAfterNow(rowData.dueToDate)) {
                    mainColor = theme.palette.error.main
                    textColor = theme.palette.error.contrastText
                }

                if (rowData?.dueToDate != null) {
                    date = moment(rowData?.dueToDate).format("DD.MM.YYYY")
                } else {
                    date = t('no_dueToDate_set')
                }
                return <Chip style={{ backgroundColor: mainColor, color: textColor }} label={date} />
            }
        },
        {
            title: t("type"), field: "helperBaseName", render: rowData => {
                return t(rowData.helperBaseName)
            }
        },
        {
            title: t("level"), field: "name", render: (rowData) => {
                let result = null
                if (rowData.taskType == TaskTypes.commonToDo()) {
                    result = rowData.name
                } else if (rowData.taskType == TaskTypes.todo()) {
                    result = rowData.parentTask.name + " - " + rowData.name
                }
                return result
            }
        },
    ]

    let columns = defaultColumns

    let actions = [
        {
            icon: () => {
                return < ArrowUpwardIcon style={{ color: COLOR.GREEN }} />
            },
            tooltip: t('move_to_level'),
            onClick: moveToLevel
        },
        {
            icon: () => {
                return < EmailIcon style={{ color: COLOR.GREEN }} />
            },
            tooltip: t('send_email'),
            onClick: sendEmail
        },
    ]


    let result = null
    if (componentState.loading) {
        result = <Spinner enabled={componentState.loading} />
    } else if (componentState.error) {
        result = <ApiError error={componentState.error} />
    } else {
        result = (
            <Container maxWidth="xl" style={{ marginTop: 10 }}>
                <DataTable
                    options={options}
                    title={title}
                    columns={columns}
                    data={data}
                    icons={MaterialTableIcons}
                    actions={actions}
                />
            </Container>
        )
    }
    return result
}
export default UrgentsTable
