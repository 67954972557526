import useApi from '../useApi';

const useItemApi = () => {
    const ItemApi = useApi().ItemApi

    return {
        useApi: ItemApi
    }

}

export default useItemApi