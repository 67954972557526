import React, { useEffect, useState } from "react"
import { useComponentState, ApiError, Spinner } from '@collapick/utils';
import { Chip } from "@material-ui/core"
import { theme } from "../../hooks/theme/useAppTheme"
import useTaskApi from '../../hooks/Apis/useTaskApi';

const CountUsedDuration = (props) => {
    const { task } = props
    const { componentState, setLoading, setError } = useComponentState()
    const TaskApi = useTaskApi()
    const [count, setCount] = useState(null)

    useEffect(() => {
        getTasksUsedDuration()
    }, [task])

    const getTasksUsedDuration = () => {
        setLoading(true)
        return TaskApi.getTasksUsedDuration(task)
            .then(res => {
                let backgroundColor = null
                let overTime = false
                if (task.estimatedDuration < res) {
                    backgroundColor = theme.palette.warning.light
                    overTime = true
                }
                setCount({
                    backgroundColor: backgroundColor,
                    duration: res,
                    overTime: overTime
                })
            })
            .catch(setError)
            .finally(setLoading(false))
    }

    let result = null
    if (componentState.loading) {
        result = <Spinner enabled={componentState.loading} />
    } else if (componentState.error) {
        result = <ApiError error={componentState.error} />
    } else if (count?.overTime) {
        result = (
            <>
                {count && <Chip style={{ backgroundColor: count.backgroundColor }} label={count.duration} />}
            </>
        )
    } else {
        result = (
            <>
                {count && count?.duration}
            </>
        )
    }
    return result
}

CountUsedDuration.defaultProps = {
    taskId: null
}
export default CountUsedDuration
