import { useTranslation } from 'react-i18next';

const useCustomersSchema = (props) => {
    const { t } = useTranslation();

    let customersSchema = {
        id: "storagesSchema",
        elements: [
            {
                type: "grid",
                direction: 'row',
                spacing: 2,
                elements: [
                    {

                        type: "text",
                        name: 'name',
                        label: t('name'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('name_is_required')
                                }
                            },
                        ]
                    },
                    {

                        type: "text",
                        name: 'description',
                        label: t('phone'),
                        item: {
                            xs: 12
                        },
                        validators: [
                            {
                                name: 'required',
                                config: {
                                    msg: t('phone_is_required')
                                }
                            },
                        ]
                    },
                    {

                        type: "text",
                        name: 'address',
                        label: t('address'),
                        item: {
                            xs: 12
                        },
                    },
                    {

                        type: "text",
                        name: 'postcode',
                        label: t('postcode'),
                        item: {
                            xs: 12
                        },
                    },
                    {

                        type: "text",
                        name: 'city',
                        label: t('city'),
                        item: {
                            xs: 12
                        },
                    },
                    {

                        type: "text",
                        name: 'companyId',
                        label: t('company_id'),
                        item: {
                            xs: 12
                        },
                    },
                    {

                        type: "text",
                        name: 'email',
                        label: t('email'),
                        item: {
                            xs: 12
                        },

                    },
                    {
                        type: 'grid'
                    }
                ]
            }

        ]
    }

    const customValidators = {
        "email_validator": {
            defaultMessage: "Not accepted",
            validator: (options, value, allValues) => {
                let isValid = false
                if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(value)) {
                    isValid = true
                }
                return isValid
            }
        }
    }


    return {
        customersSchema,
        customValidators
    }

}

export default useCustomersSchema;
