import React from 'react'
import { Container, Grid, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import { DataTable } from '../MaterialTable/DataTable';
import EditIcon from '@material-ui/icons/Edit';
import { COLOR } from '../../hooks/theme/useAppTheme'
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import useTaskApi from '../../hooks/Apis/useTaskApi';
import ShowThumbnails from './ShowThumbnails';

const DefaultAttachmentsTable = (props) => {
    const { title, defaultAttachments, editCallback, addCallback, deleteCallback } = props
    const { t } = useTranslation()
    const TaskApi = useTaskApi()

    const defaultColumns = [
        { title: t('name'), field: 'name' },
        {
            title: t('files'), field: 'files', render: (rowData) => {
                if (rowData?.files) {
                    return <ShowThumbnails files={rowData.files} />
                }
            }
        }
    ]

    const options = {
        pageSize: 25,
        pageSizeOptions: [10, 25, 50, 100],
        paginationType: 'normal',
        removable: false,
        search: false,
        sorting: false,
        actionsColumnIndex: defaultColumns.length + 1,
        showTitle: true,
        exportButton: false,
        filtering: false,
        paging: false,
        columnsButton: false
    }

    const actions = [
        {
            icon: () => {
                return (
                    <Button variant="contained">
                        < AddIcon style={{ color: COLOR.GREEN }} />
                        {t('add_new_default_attachment')}
                    </Button>
                )
            },
            tooltip: t('add_new_default_attachment'),
            isFreeAction: true,
            onClick: addCallback
        },
        {
            icon: () => {
                return < DeleteIcon style={{ color: COLOR.GREEN }} />
            },
            tooltip: t('delete_default_attachments'),
            onClick: deleteCallback
        },
        {
            icon: () => {
                return < EditIcon style={{ color: COLOR.GREEN }} />
            },
            tooltip: t('edit_default_attachment'),
            onClick: editCallback
        },
    ]

    let result = (
        <Container maxWidth="xl" style={{ marginTop: 10 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DataTable
                        columns={defaultColumns}
                        data={defaultAttachments}
                        actions={actions}
                        options={options}
                        title={title} />
                </Grid>
            </Grid>
        </Container>
    )

    return result
}

export default DefaultAttachmentsTable