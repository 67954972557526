import React from 'react'
import { useTranslation } from 'react-i18next';
import CustomInfoField from '../components/Form/CustomInfoField';
import { FormElements } from '@collapick/form';
import { useUserContext } from '../context/UserContext'

const useAnswerFormSchema = (props) => {
    const { initialValues } = props
    const { t } = useTranslation();
    const { token } = useUserContext()

    let answerSchema = {
        id: "answerSchema",
        elements: [
            {
                type: "grid",
                direction: 'row',
                spacing: 2,
                elements: [
                    {

                        type: "upload",
                        name: 'files',
                        label: t('files'),
                        item: {
                            xs: 12
                        },
                        config: {
                            url: window.BASEURL + "api/storage/forms/upload-file",
                            token
                        }
                    },
                    {
                        type: 'customInfoField',
                        name: 'info',
                        label: t('info'),
                        item: {
                            xs: 12
                        },
                    },
                    {

                        type: "date",
                        name: 'date',
                        label: t('date'),
                        item: {
                            xs: 12,
                        },
                        elementProps: {
                            inputVariant: "outlined"
                        },
                    },
                    {

                        type: "number",
                        name: 'time',
                        label: t('hours'),
                        item: {
                            xs: 12
                        },
                    },
                    {
                        type: 'grid'
                    }
                ]
            }

        ]
    }

    const customElements = Object.assign(
        FormElements,
        {
            "customInfoField": {
                component: (props) => {
                    return (
                        <>
                            { initialValues?.taskObject != null &&
                                Object.getOwnPropertyNames(initialValues.taskObject).length !== 0 &&
                                <CustomInfoField dates={initialValues.taskObject} {...props} />}
                        </>
                    )
                }
            }
        })


    return {
        answerSchema,
        customElements
    }

}

export default useAnswerFormSchema;
