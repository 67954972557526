import React, { useEffect } from 'react'
import { Typography, Container, Grid, Paper, Box, Button, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import useRoutes from '../../hooks/useRoutes';
import { CollapickForm } from '@collapick/form';
import { useComponentState, ApiError, Spinner } from '@collapick/utils';
import useAppUserApi from '../../hooks/Apis/useAppUserApi';
import useEditUserSchema from '../../FormSchemas/useEditUserSchema';
import useRoleMappingApi from '../../hooks/Apis/useRoleMappingApi';

const EditUserDialog = (props) => {
    const { t } = useTranslation();
    const { setLoading, setError, componentState } = useComponentState()
    const { user, callback, initialValues } = props
    const { editUserSchema, customValidators } = useEditUserSchema();

    const AppUserApi = useAppUserApi()
    const RoleMappingApi = useRoleMappingApi()

    const onSave = (values) => {
        let error = false
        let object = {
            loginName: values.username,
            fullname: values.username,
            username: values.username,
            email: values.email,
            password: values.password,
        }

        setLoading(true)
        return AppUserApi.updateById(initialValues.id, object)
            .then(res => {
                let filter = {
                    where: {
                        principalId: initialValues.id
                    }
                }
                return RoleMappingApi.findOne(filter)
            })
            .then(res => {
                let filter = {
                    roleId: values.role,
                    principalType: "USER"
                }
                return RoleMappingApi.updateById(res?.id, filter)
            })
            .catch((err) => {
                error = true
                setError(err)
            })
            .finally(() => {
                setLoading(false)
                if (!error) {
                    callback()
                }
            })
    }

    let result = null
    if (componentState.loading) {
        result = <Spinner enabled={componentState.loading} />
    } if (componentState.error) {
        result = <ApiError error={componentState.error} />
    } else {
        result = (
            <>
                <Container >
                    <Box my={2}>
                        <Typography variant="h3">{t("edit_user")}</Typography>
                    </Box>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <CollapickForm
                                initialValues={initialValues}
                                formSchema={editUserSchema}
                                onFormSubmit={onSave}
                                resetFormAfterSubmit={true}
                                customValidators={customValidators}
                                renderSubmitButton={() =>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        fullWidth>
                                        {t('submit')}
                                    </Button>}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </>
        )
    }
    return result
}

EditUserDialog.defaultProps = {
    user: [],
    callback: () => { },
}

export default EditUserDialog
