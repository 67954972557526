import React, { useState, useEffect } from 'react'
import useTaskApi from '../../../../hooks/Apis/useTaskApi'
import { useComponentState, ApiError, Spinner } from '@collapick/utils';
import { TaskTypes } from '@collapick/ponniste-types'
import { useHistory, useLocation } from 'react-router-dom';
import MobileProgress from '../../../../components/Progress/MobileProgress';
import ScheduleBar from '../../../../components/Schedule/ScheduleBar'
import LevelNavigation from '../../../../components/LevelNavigation/LevelNavigation'
import UrgentsTableForProject from '../../../../components/Table/UrgentsTableForProject'
import { useTranslation } from 'react-i18next';
import FilesAndManuals from '../../../../components/FilesAndManuals/FilesAndManuals';
import { useUserContext } from '../../../../context/UserContext'
import Axios from 'axios'
import SendEmailForm from '../../../../components/Form/SendEmailForm'
import ContentDialog from '../../../../components/Dialogs/ContentDialog';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, IconButton } from '@material-ui/core'
import useAppUserApi from '../../../../hooks/Apis/useAppUserApi';
import { SERVICE, SALE, PRODUCTION } from '../../../../Constants';
import useDynamicDataApi from '../../../../hooks/Apis/useDynamicDataApi';
import CustomerInfo from '../../../../components/CustomerInfo/CustomerInfo';
import CustomerInfoDialog from '../../../../components/Dialogs/CustomerInfoDialog';

const ProjectsCommonView = (props) => {
    const { taskId } = props
    const TaskApi = useTaskApi()
    const { componentState, setLoading, setError } = useComponentState()
    const history = useHistory()
    const [task, setTask] = useState(null)
    const [count, setCount] = useState({})
    const location = useLocation()
    const { t } = useTranslation()
    const { token, user } = useUserContext()
    const [message, setMessage] = useState(null)
    const [receiver, setReceiver] = useState(null)
    const DynamicDataApi = useDynamicDataApi()
    const [type, setType] = useState(null)
    const [openSendEmail, setOpenSendEmail] = useState(false)
    const [projectInfo, setProjectInfo] = useState(null)
    const AppUserApi = useAppUserApi()
    const [users, setUsers] = useState(null)
    const [openCustomerInfo, setOpenCustomerInfo] = useState(null)

    useEffect(() => {
        getTask()
    }, [location])

    const getTask = () => {
        setLoading(true)
        return TaskApi.getTask(taskId)
            .then(res => {
                setTask(res[0])
                getDoneTasks(res[0])
            })
            .catch(setError)
            .finally(() => {
                setLoading(false)

            })
    }

    const getDoneTasks = (task) => {
        setLoading(true)
        return TaskApi.getDoneTasks(task)
            .then(res => {
                setCount(res)
            })
            .catch(setError)
            .finally(setLoading(false))
    }

    const openSchedule = () => {
        if (task.taskType == TaskTypes.task()) {
            history.push('/mobile/schedule/' + [task.uuid])
        }
        else if (task.taskType == TaskTypes.common()) {
            history.push('/mobile/schedule/' + [task.parentTaskId])
        } else if (task.taskType == TaskTypes.commonToDo()) {
            setLoading(true)
            return TaskApi.getParentTask(task.parentTaskId)
                .then(res => {
                    history.push('/mobile/schedule/' + [res[0].parentTaskId])
                })
                .catch(setError)
                .finally(setLoading(false))
        }
    }

    const openInfo = () => {
        setOpenCustomerInfo(true)
    }

    const moveToLevel = (taskId) => {
        if (task.taskType == TaskTypes.progress()) {
            history.push('/mobile/projects/todoview/' + [taskId])
        }
        else if (task.taskType != TaskTypes.common() || 
        (task.taskType == TaskTypes.common() && 
        task?.childTasks.some(o => o?.taskType == TaskTypes.progress()))) {
            history.push('/mobile/projects/view/' + [taskId])
        } else {
            history.push('/mobile/projects/todoview/' + [taskId])
        }
    }

    const openFile = (file) => {
        let fileName = file.relativeUrl.split("/download/").pop()
        let url = window.BASEURL + `api/storage/${file.container}/download/${fileName}`
        window.open(url + `?accessToken=${token}`)
    }

    const moveToUrgentLevel = (event, rowData) => {
        if (rowData.taskType == TaskTypes.todo()) {
            history.push('/mobile/projects/todoview/' + [rowData.parentTaskId])
        } else {
            history.push('/mobile/projects/todoview/' + [rowData.uuid])
        }
    }

    const sendEmail = (values) => {
        let url = window.BASEURL + "kytkin-api/sendEmail/"
        setLoading(true)
        return Axios.post(url, { values: values, user: user, projectInfo: projectInfo })
            .then(() => {
                if (values.message != message || values.receiver != receiver) {
                    return DynamicDataApi.setMessage(values.message, values.receiver, type)
                }
            })
            .catch(setError)
            .finally(() => {
                setOpenSendEmail(false)
                setLoading(false)
            })
    }

    const sendEmailCallback = (event, rowData) => {
        setLoading(true)
        setProjectInfo(rowData)
        return AppUserApi.getUsersForEmail()
            .then(res => {
                setUsers(res)
                let type = null
                if (rowData.helperBaseName == SALE) {
                    type = SALE
                } else if (rowData.helperBaseName == SERVICE) {
                    type = SERVICE
                } else if (rowData.helperBaseName == PRODUCTION) {
                    type = PRODUCTION
                }
                setType(type)
                return DynamicDataApi.getMessage(type)
            })
            .then(res => {
                setMessage(res?.message)
                setReceiver(res?.receiver)
            })
            .catch(setError)
            .finally(() => {
                setOpenSendEmail(true)
                setLoading(false)
            })
    }

    const confirmSafety = (task, uuid) => {
        console.log("OOKS TÄSSÄ")
        setLoading(true)
        let description = task?.description
        try {
            description = JSON.parse(description)
        } catch (err) {
            console.log(err)
        }
        if (Array.isArray(description)) {
            description.push(uuid)
        } else {
            description = [uuid]
        }
        return TaskApi.useApi.updateById(task.uuid, { description: JSON.stringify(description) })
            .catch(setError)
            .finally(() => {
                setLoading(false)
                getTask()
            })
    }

    let result = null
    if (componentState.loading) {
        result = <Spinner enabled={componentState.loading} />
    } else if (componentState.error) {
        result = <ApiError error={componentState.error} />
    } else {
        result = (
            <>
                {task && count && <MobileProgress count={count} task={task} />}
                {task && task.taskType == TaskTypes.task() && <CustomerInfo openInfo={openInfo} />}
                <ScheduleBar openSchedule={openSchedule} />
                {task && <LevelNavigation task={task} moveToLevel={moveToLevel} confirmSafety={confirmSafety}/>}

                {task && task.taskType == TaskTypes.task() &&
                    < UrgentsTableForProject
                        title={t('urgents')}
                        taskId={task.uuid}
                        moveToLevel={moveToUrgentLevel}
                        sendEmail={sendEmailCallback} />}
                {task && task.taskType == TaskTypes.common() &&
                    <FilesAndManuals task={task} openFile={openFile} />}
                <ContentDialog
                    actions={
                        <IconButton color="primary" onClick={(() => { setOpenSendEmail(false) })} >
                            <CloseIcon />{t('close')}
                        </IconButton>}
                    title={t('add_customer')}
                    open={openSendEmail}
                    setOpen={setOpenSendEmail}>
                    {openSendEmail && users &&
                        <SendEmailForm
                            users={users}
                            message={message}
                            receiver={receiver}
                            onSave={sendEmail}
                            onCancel={() => setOpenSendEmail(false)}
                            title={<Typography variant="h2">{t('send_email')}</Typography>}
                        />}
                </ContentDialog>
                <ContentDialog
                    actions={
                        <IconButton color="primary" onClick={(() => { setOpenCustomerInfo(false) })} >
                            <CloseIcon />{t('close')}
                        </IconButton>}
                    title={t('customer_info')}
                    open={openCustomerInfo}
                    setOpen={setOpenCustomerInfo}>
                    {openCustomerInfo &&
                        <CustomerInfoDialog
                            task={task.taskType == TaskTypes.task() ? task : task.parentTask}
                        />}
                </ContentDialog>
            </>
        )
    }
    return result
}

ProjectsCommonView.defaultProps = {
    taskId: "e3350940-5b05-11eb-837a-83948b54c92f"
}

export default ProjectsCommonView
