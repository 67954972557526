import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import CustomersTable from '../../../../components/Table/CustomersTable'
import useCustomerApi from '../../../../hooks/Apis/useCustomerApi'
import { useComponentState, ApiError, Spinner } from '@collapick/utils';
import { Typography, IconButton } from '@material-ui/core'
import ConfirmDialog from '../../../../components/Dialogs/ConfirmDialog'
import ContentDialog from '../../../../components/Dialogs/ContentDialog'
import CloseIcon from '@material-ui/icons/Close';
import CustomersForm from '../../../../components/Form/CustomersForm';
import useDynamicDataApi from '../../../../hooks/Apis/useDynamicDataApi';

const CustomersView = (props) => {
    const { t } = useTranslation()
    const [data, setData] = useState([])
    const CustomerApi = useCustomerApi()
    const { componentState, setLoading, setError } = useComponentState()
    const title = t('customers')
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [initialValues, setInitialValues] = useState(null)
    const [openEditCustomer, setOpenEditCustomer] = useState(false)
    const [openAddCustomer, setOpenAddCustomer] = useState(false)
    const DynamicDataApi = useDynamicDataApi()

    useEffect(() => {
        getCustomers()
    }, [])

    const getCustomers = () => {
        setLoading(true)
        return CustomerApi.getCustomers()
            .then(res => setData(res))
            .catch(setError)
            .finally(setLoading(false))
    }

    const editCallback = (e, values) => {
        setInitialValues(values)
        setOpenEditCustomer(true)
    }

    const addCallback = () => {
        setOpenAddCustomer(true)
    }

    const deleteCallback = (e, values) => {
        setInitialValues(values)
        setConfirmOpen(true)
    }

    const deleteCustomer = (values) => {
        setLoading(true)
        CustomerApi.useApi.deleteById(values.uuid)
            .then(res => {
                getCustomers()
                return DynamicDataApi.deleteCustomersCosts(values.uuid)
            })
            .catch(setError)
            .finally(() => {
                setLoading(false)
            })
    }

    const onSaveAdd = (values) => {
        setLoading(true)
        CustomerApi.createCustomer(values)
            .then(res => {
                setOpenAddCustomer(false)
                getCustomers()
            })
            .catch(setError)
            .finally(() => {
                setLoading(false)
            })
    }

    const onSaveEdit = (values) => {
        setLoading(true)
        CustomerApi.editCustomer(values)
            .then(res => {
                setOpenEditCustomer(false)
                getCustomers()
            })
            .catch(setError)
            .finally(() => {
                setLoading(false)
            })
    }


    let result = null
    if (componentState.loading) {
        result = <Spinner enabled={componentState.loading} />
    } else if (componentState.error) {
        result = <ApiError error={componentState.error} />
    } else {
        result = (
            <>
                <CustomersTable
                    title={title}
                    data={data}
                    editCallback={editCallback}
                    addCallback={addCallback}
                    deleteCallback={deleteCallback} />
                <ConfirmDialog
                    open={confirmOpen}
                    setOpen={setConfirmOpen}
                    title={t('remove_customer')}
                    content={initialValues?.name}
                    agreeCallback={() => {
                        setConfirmOpen(false);
                        deleteCustomer(initialValues);
                        setInitialValues(false)
                    }}
                    disagreeCallback={() => {
                        setConfirmOpen(false);
                        setInitialValues(false)
                    }} />
                <ContentDialog
                    actions={
                        <IconButton color="primary" onClick={(() => { setOpenAddCustomer(false) })} >
                            <CloseIcon />{t('close')}
                        </IconButton>}
                    title={t('add_customer')}
                    open={openAddCustomer}
                    setOpen={setOpenAddCustomer}>
                    {openAddCustomer &&
                        <CustomersForm
                            customers={data}
                            onSave={onSaveAdd}
                            onCancel={() => setOpenAddCustomer(false)}
                            title={<Typography variant="h2">{t('add_new_customer')}</Typography>}

                        />}
                </ContentDialog>
                <ContentDialog
                    actions={
                        <IconButton color="primary" onClick={(() => { setOpenEditCustomer(false) })} >
                            <CloseIcon />{t('close')}
                        </IconButton>}
                    title={t('edit_customer')}
                    open={openEditCustomer}
                    setOpen={setOpenEditCustomer}>
                    {openEditCustomer &&
                        <CustomersForm
                            customers={data}
                            onSave={onSaveEdit}
                            onCancel={() => setOpenEditCustomer(false)}
                            initialValues={initialValues}
                            title={<Typography variant="h2">{t('edit_customer')}</Typography>}

                        />}
                </ContentDialog>
            </>
        )
    }
    return result
}

export default CustomersView