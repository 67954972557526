import React from 'react'
import { Grid, Button, Paper, Container } from '@material-ui/core';
import { theme, COLOR } from '../../hooks/theme/useAppTheme'
import { makeStyles, fade } from "@material-ui/core/styles"
import { useTranslation } from 'react-i18next';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const useStyles = makeStyles((t) => (
    {
        root: {
            backgroundColor: theme.palette.primary.dark,
            display: "flex",
            textAlign: "center",
            alignSelf: "center",
            padding: "0.5vh",
            justifyContent: "space-around"
        },
        typography: {
            color: COLOR.scheduleBar
        },
        icon: {
            color: COLOR.scheduleBar,
            fontSize: "5vh"
        },
        button: {
            backgroundColor: COLOR.scheduleBar,
            '&:hover': {
                backgroundColor: fade(COLOR.scheduleBar, 0.5),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
                '&$disabled': {
                    backgroundColor: 'transparent',
                },
            },
            '&$disabled': {
                color: theme.palette.action.disabled,
            }
        },
        row: {
            display: "flex",
            textAlign: "center",
            alignSelf: "center",
            //    padding: "2.5vh",
            justifyContent: "space-around"
        },
    }))

const ScheduleBar = (props) => {
    const { openSchedule } = props
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Container className={classes.root} maxWidth="false">
            <Grid container direction="row" alignItems="center">
                <Grid item xs={12} className={classes.row}>
                    <Grid item xs={2} style={{ alignSelf: "center" }}>
                        <AccessTimeIcon className={classes.icon} />
                    </Grid>
                    <Grid item xs={6} style={{ alignSelf: "center" }}>
                        <Paper className={classes.button}>
                            <Button
                                className={classes.button}
                                variant="outlined"
                                fullWidth
                                disableElevation
                                onClick={openSchedule}
                            >
                                {t('schedule')}
                            </Button>
                        </Paper>
                    </Grid >
                </Grid>
            </Grid >
        </Container>
    )
}

ScheduleBar.defaultProps = {
    openSchedule: console.log
}

export default ScheduleBar