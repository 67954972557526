import React, { useState, useEffect } from 'react'
import useTaskApi from '../../../../hooks/Apis/useTaskApi'
import { useComponentState, ApiError, Spinner } from '@collapick/utils';
import ScheduleProjectsTable from '../../../../components/Table/ScheduleProjectsTable'

const ScheduleView = (props) => {
    const { taskId } = props
    const TaskApi = useTaskApi()
    const { componentState, setLoading, setError } = useComponentState()
    const [task, setTask] = useState(null)

    useEffect(() => {
        getTask()
    }, [])

    const getTask = () => {
        setLoading(true)
        return TaskApi.getTask(taskId)
            .then(res => {
                setTask(res)
            })
            .catch(setError)
            .finally(setLoading(false))
    }

    const editSchedule = (e, task) => {
        console.log(task)
    }

    let result = null
    if (componentState.loading) {
        result = <Spinner enabled={componentState.loading} />
    } else if (componentState.error) {
        result = <ApiError error={componentState.error} />
    } else {
        result = (
            <>
                {task && <ScheduleProjectsTable data={task} editSchedule={editSchedule} />}
            </>
        )
    }
    return result
}

ScheduleView.defaultProps = {
    taskId: "e3324a20-5b05-11eb-837a-83948b54c92f"
}

export default ScheduleView