import useApi from '../useApi';

const useFormAnswerApi = () => {
    const FormAnswerApi = useApi().FormAnswerApi

    const getFormAnswers = (containerId) => {
        let query = {
            where: {
                formAnswerContainerId: containerId
            }
        }
        return FormAnswerApi.find({ filter: JSON.stringify(query) })
    }

    return {
        getFormAnswers
    }

}

export default useFormAnswerApi