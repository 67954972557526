import React, { useState, useEffect, useContext } from "react"
import { useTranslation } from "react-i18next"
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import useRoutes from "../../hooks/useRoutes";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, makeStyles, MenuItem, Menu } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { theme } from '../../hooks/theme/useAppTheme'
import useTaskApi from '../../hooks/Apis/useTaskApi';
import HomeIcon from '@material-ui/icons/Home';
import MobileProjectsTable from '../Table/MobileProjectsTable';
import { DataContext } from '../../context/DataContext'
import { TaskTypes } from '@collapick/ponniste-types'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        //marginBottom: "8px"
    },
    bar: {
        backgroundColor: theme.palette.primary.dark
    },
    title: {
        whiteSpace: "nowrap",
        maxWidth: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: '1rem'
    },
    text: {
        whiteSpace: "nowrap",
        maxWidth: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: '1rem'
    },
    titleContainer: {
        maxWidth: "100%",
        overflow: "auto"
    }
}))

const MobileHeader = (props) => {
    const { taskId } = props
    const classes = useStyles()
    const { t } = useTranslation();
    const routes = useRoutes();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const [level, setLevel] = useState("")
    const TaskApi = useTaskApi()
    const dataStore = useContext(DataContext)

    useEffect(() => {
        getLevel()
    }, [taskId])

    const getLevel = () => {
        if (taskId) {
            return TaskApi.getLevel(taskId)
                .then(res => {
                    setLevel(res)
                })
        }
    }

    const onLogout = () => {
        history.push(routes.PATH.logout)
    }

    const goBack = () => {
        if (dataStore.mobXStore.fromUrgents) {
            return TaskApi.getParentTaskWithChildUuid(taskId)
                .then(res => {
                    let task = res[0]
                    let parentTask = res[0]?.parentTask
                    if (task?.taskType !== TaskTypes.task()) {
                        history.push('/mobile/projects/view/' + [parentTask.uuid])
                    } else {
                        dataStore.mobXStore.fromUrgents = false
                        history.push(routes.PATH.mobile.root)
                    }
                })
        } else {
            history.goBack()
        }
    }

    const goHome = () => {
        history.push(routes.PATH.mobile.root)
    }


    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const menuItems = () => {
        return (
            <div>
                <MenuItem
                    onClick={() => {
                        history.push(routes.PATH.mobile.feedbackform)
                        handleCloseMenu()
                    }}>{t("feedback_form")}</MenuItem>
                <MenuItem
                    onClick={() => {
                        onLogout()
                        handleCloseMenu()
                    }}>{t("logout")}</MenuItem>

            </div>
        )
    }

    return (
        <AppBar className={classes.bar} position="static">
            <Toolbar>
                <Grid item xs={2}>
                    <Grid justify="flex-start" container wrap={"nowrap"} alignItems="center" spacing={2}>
                        {history.location.pathname == ('/mobile/projects') ?
                            <Typography variant="h6" >
                                {t('Joentaiturit')}
                            </Typography>
                            :
                            <>
                                <IconButton onClick={goBack}>
                                    <ArrowBackIcon style={{ color: theme.palette.primary.contrastText }}
                                    /></IconButton>
                                <IconButton onClick={goHome}>
                                    <HomeIcon style={{ color: theme.palette.primary.contrastText }}
                                    /></IconButton>
                            </>
                        }

                    </Grid>
                </Grid>
                <Grid item xs={8}>
                    <Grid container justify="center" className={classes.titleContainer} direction="column" alignItems="center">
                        {level?.title && <Typography variant="h6" className={classes.title} >
                            {level.title}
                        </Typography>}

                        {level?.text && <Typography variant="h6" className={classes.text} >
                            {level.text}
                        </Typography>}
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Grid container justify="flex-end" >
                        <Button onClick={handleClickMenu}>
                            <MenuIcon style={{ color: theme.palette.primary.contrastText }} />
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            transformOrigin={{ vertical: "top", horizontal: "center" }}

                        >
                            {menuItems()}
                        </Menu>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

MobileHeader.defaultProps = {}
export default MobileHeader
