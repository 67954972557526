import React from 'react'
import { PathParamSetter } from "@collapick/utils"
// Pages
import LoginView from "../app/views/Login/LoginView"
import { Redirect } from 'react-router-dom'
import LogoutView from '../app/views/Login/LogoutView'
import Footer from '../components/Footer/Footer'

//mobile
import ProjectsCommonView from '../app/views/Mobile/ProjectsCommonView/ProjectsCommonView'
import ProjectsMainView from '../app/views/Mobile/ProjectsMainView/ProjectsMainView';
import ScheduleView from '../app/views/Mobile/ScheduleView/ScheduleView';
import MobileHeader from '../components/Header/MobileHeader'
import ProjectsTodoView from '../app/views/Mobile/ProjectsTodoView/ProjectsTodoView'
import ProjectsAnswerFormView from '../app/views/Mobile/ProjectsAnswerFormView/ProjectsAnswerFormView'
import ProjectsUploadView from '../app/views/Mobile/ProjectsFileUploadView/ProjectsFileUploadView';
import FeedbackFormView from '../app/views/Mobile/FeedbackFormView/FeedbackFormView';
import WorksTodoView from '../app/views/Mobile/WorksTodoView/WorksTodoView';

//dashboard
import ProjectsView from '../app/views/Dashboard/ProjectsView/ProjectsView';
import NewProjectView from '../app/views/Dashboard/NewProjectView/NewProjectView';
import EditProjectView from '../app/views/Dashboard/EditProjectView/EditProjectView';
import DashboardHeader from '../components/Header/DashboardHeader';
import CustomersView from '../app/views/Dashboard/Customers/CustomersView';
import ProjectSizes from '../app/views/Dashboard/ProjectSizes/ProjectSizes';
import WorkHours from '../app/views/Dashboard/WorkHours/WorkHours';
import DashboardSubHeader from '../components/Header/DashboardSubHeader';
import InfoVideoView from '../app/views/Dashboard/InfoVideoView/InfoVideoView';
import UsersView from '../app/views/Dashboard/Users/UsersView';
import NewCustomProjectView from '../app/views/Dashboard/NewProjectView/NewCustomProjectView';
import FeedbackSummaryView from '../app/views/Dashboard/FeedbackSummaryView/FeedbackFormView';
import WorksView from '../app/views/Dashboard/WorksView/WorksView';
import NewWorkView from '../app/views/Dashboard/NewWorkView/NewWorkView';
import TasksInWeeks from '../app/views/Dashboard/TasksInWeeksView/TasksInWeeksView'
import DefaultAttachments from '../app/views/Dashboard/DefaultAttachments/DefaultAttachments'
import ProductionFormsView from '../app/views/Dashboard/ProductionForms/ProductionFormsView'

//device checker
import DeviceChecker from '../components/DeviceChecker/DeviceChecker';





const PATH = {
    root: "/",
    login: "/login",
    logout: "/logout",
    mobile: {
        root: "/mobile/projects",
        view: "/mobile/projects/view/",
        todoview: "/mobile/projects/todoview/",
        schedule: "/mobile/schedule/",
        answerform: "/mobile/answerform/",
        uploadform: "/mobile/uploadform/",
        feedbackform: "/mobile/feedbackform/",
        workstodoview: "/mobile/works/todoview/",
    },
    dashboard: {
        root: "/dashboard",
        projects: "/dashboard/projects",
        newproject: "/dashboard/projects/newproject",
        editproject: "/dashboard/projects/editproject/",
        customers: "/dashboard/customers",
        projectsizes: "/dashboard/projectsizes",
        workhours: "/dashboard/workhours",
        infovideos: "/dashboard/infovideos",
        usersview: "/dashboard/users",
        newcustomproject: "/dashboard/projects/newcustomproject",
        feedbacksummary: "/dashboard/feedbacksummary",
        feedbackform: "/dashboard/feedbackform",
        works: "/dashboard/works",
        newwork: "/dashboard/newwork",
        services: "/dashboard/services",
        defaultattachments: "/dashboard/defaultattachments",
        tasksinweeks: "/dashboard/tasksinweeks",
        productionforms: "/dashboard/productionforms"
    }
}


const createRoutes = () => {
    let routes = []
    try {
        routes = [
            {
                key: "ROOT",
                path: PATH.root,
                private: true,
                exact: true,
                render: (props) => {
                    return (
                        <>
                            <DeviceChecker />
                        </>
                    )

                }
            },
            {
                path: PATH.mobile.root,
                private: true,
                exact: true,
                render: (props) => {
                    return (
                        <>
                            <MobileHeader />
                            <ProjectsMainView />
                            <Footer />
                        </>
                    )
                }
            },
            {
                path: PATH.mobile.view + ":taskId",
                private: true,
                render: (props) => {
                    let taskId = props.match.params.taskId
                    return (
                        <>
                            <MobileHeader taskId={taskId} />
                            <ProjectsCommonView taskId={taskId} />
                            <Footer />
                        </>
                    )
                }
            },
            {
                path: PATH.mobile.todoview + ":taskId",
                private: true,
                render: (props) => {
                    let taskId = props.match.params.taskId
                    return (
                        <>
                            <MobileHeader taskId={taskId} />
                            <ProjectsTodoView taskId={taskId} />
                            <Footer />
                        </>
                    )
                }
            },
            {
                path: PATH.mobile.workstodoview + ":taskId",
                private: true,
                render: (props) => {
                    let taskId = props.match.params.taskId
                    return (
                        <>
                            <MobileHeader taskId={taskId} />
                            <WorksTodoView taskId={taskId} />
                            <Footer />
                        </>
                    )
                }
            },
            {
                path: PATH.mobile.schedule + ":taskId",
                private: true,
                render: (props) => {
                    let taskId = props.match.params.taskId
                    return (
                        <>
                            <MobileHeader taskId={taskId} />
                            <ScheduleView taskId={taskId} />
                            <Footer />
                        </>
                    )
                }
            },
            {
                path: PATH.mobile.answerform + ":taskId",
                private: true,
                render: (props) => {
                    let taskId = props.match.params.taskId
                    return (
                        <>
                            <MobileHeader taskId={taskId} />
                            <ProjectsAnswerFormView taskId={taskId} />
                            <Footer />
                        </>
                    )
                }
            },
            {
                path: PATH.mobile.uploadform + ":taskId",
                private: true,
                render: (props) => {
                    let taskId = props.match.params.taskId
                    return (
                        <>
                            <MobileHeader taskId={taskId} />
                            <ProjectsUploadView taskId={taskId} />
                            <Footer />
                        </>
                    )
                }
            },
            {
                path: PATH.mobile.feedbackform,
                private: true,
                render: (props) => {
                    return (
                        <>
                            <MobileHeader />
                            <FeedbackFormView />
                            <Footer />
                        </>
                    )
                }
            },
            {
                path: PATH.dashboard.root,
                private: true,
                exact: true,
                render: (props) => {
                    return <Redirect to={PATH.dashboard.projects} />
                }
            },
            {
                path: PATH.dashboard.projects,
                private: true,
                exact: true,
                render: (props) => {
                    const mode = "projects"
                    return (
                        <>
                            <DashboardHeader />
                            <DashboardSubHeader />
                            <ProjectsView mode={mode} />
                        </>
                    )
                }
            },
            {
                path: PATH.dashboard.services,
                private: true,
                exact: true,
                render: (props) => {
                    const mode = "services"
                    return (
                        <>
                            <DashboardHeader />
                            <DashboardSubHeader />
                            <ProjectsView mode={mode} />
                        </>
                    )
                }
            },
            {
                path: PATH.dashboard.newproject,
                private: true,
                exact: true,
                render: (props) => {
                    return (
                        <>
                            <DashboardHeader />
                            <DashboardSubHeader />
                            <NewProjectView />
                        </>
                    )
                }
            },
            {
                path: PATH.dashboard.newcustomproject,
                private: true,
                exact: true,
                render: (props) => {
                    return (
                        <>
                            <DashboardHeader />
                            <DashboardSubHeader />
                            <NewCustomProjectView />
                        </>
                    )
                }
            },
            {
                path: PATH.dashboard.editproject + ":taskId",
                private: true,
                render: (props) => {
                    let taskId = props.match.params.taskId
                    return (
                        <>
                            <DashboardHeader />
                            <DashboardSubHeader />
                            <EditProjectView taskId={taskId} />
                        </>
                    )
                }
            },
            {
                path: PATH.dashboard.customers,
                private: true,
                exact: true,
                render: (props) => {
                    return (
                        <>
                            <DashboardHeader />
                            <DashboardSubHeader />
                            <CustomersView />
                        </>
                    )
                }
            },
            {
                path: PATH.dashboard.projectsizes,
                private: true,
                exact: true,
                render: (props) => {
                    return (
                        <>
                            <DashboardHeader />
                            <DashboardSubHeader />
                            <ProjectSizes />
                        </>
                    )
                }
            },
            {
                path: PATH.dashboard.workhours,
                private: true,
                exact: true,
                render: (props) => {
                    return (
                        <>
                            <DashboardHeader />
                            <DashboardSubHeader />
                            <WorkHours />
                        </>
                    )
                }
            },
            {
                path: PATH.dashboard.infovideos,
                private: true,
                exact: true,
                render: (props) => {
                    return (
                        <>
                            <DashboardHeader />
                            <DashboardSubHeader />
                            <InfoVideoView />
                        </>
                    )
                }
            },
            {
                path: PATH.dashboard.usersview,
                private: true,
                exact: true,
                render: (props) => {
                    return (
                        <>
                            <DashboardHeader />
                            <DashboardSubHeader />
                            <UsersView />
                        </>
                    )
                }
            },
            {
                path: PATH.dashboard.feedbackform,
                private: true,
                exact: true,
                render: (props) => {
                    return (
                        <>
                            <DashboardHeader />
                            <DashboardSubHeader />
                            <FeedbackFormView />
                        </>
                    )
                }
            },
            {
                path: PATH.dashboard.feedbacksummary,
                private: true,
                exact: true,
                render: (props) => {
                    return (
                        <>
                            <DashboardHeader />
                            <DashboardSubHeader />
                            <FeedbackSummaryView />
                        </>
                    )
                }
            },
            {
                path: PATH.dashboard.works,
                private: true,
                exact: true,
                render: (props) => {
                    return (
                        <>
                            <DashboardHeader />
                            <DashboardSubHeader />
                            <WorksView />
                        </>
                    )
                }
            },
            {
                path: PATH.dashboard.newwork,
                private: true,
                exact: true,
                render: (props) => {
                    return (
                        <>
                            <DashboardHeader />
                            <DashboardSubHeader />
                            <NewWorkView />
                        </>
                    )
                }
            },
            {
                path: PATH.dashboard.defaultattachments,
                private: true,
                exact: true,
                render: (props) => {
                    return (
                        <>
                            <DashboardHeader />
                            <DashboardSubHeader />
                            <DefaultAttachments />
                        </>
                    )
                }
            },
            {
                path: PATH.dashboard.tasksinweeks,
                private: true,
                exact: true,
                render: (props) => {
                    return (
                        <>
                            <DashboardHeader />
                            <DashboardSubHeader />
                            <TasksInWeeks />
                        </>
                    )
                }
            },
            {
                path: PATH.dashboard.productionforms,
                private: true,
                exact: true,
                render: (props) => {
                    return (
                        <>
                            <DashboardHeader/>
                            <DashboardSubHeader/>
                            <ProductionFormsView/>
                        </>
                    )
                }
            },
            {
                key: "LOGIN",
                path: PATH.login,
                exact: true,
                render: (props) => {
                    return <LoginView {...props}></LoginView>
                }
            },
            {
                key: "LOGOUT",
                path: PATH.logout,
                exact: true,
                render: (props) => {
                    return (
                        <>
                            <LogoutView {...props} />
                        </>
                    )
                }
            }
        ]
    } catch (err) {
        console.warn("Routes array could not be build. Check routes object and any objects used in the routes array.")
        console.error(err)
    }
    return routes;
}




const useRoutes = () => {
    let routes = createRoutes();
    const { setPathParams, findPathAndSetParams, findRouteByKey } = PathParamSetter(routes)
    return {
        PATH,
        routes,
        setPathParams,
        findPathAndSetParams,
        findRouteByKey,
    }
};

export default useRoutes;
