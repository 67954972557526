import React, { useState } from 'react'
import ReactStars from "react-rating-stars-component";
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const ReviewForm = (props) => {
    const { saveStatus } = props
    const { t } = useTranslation()
    const [thanks, setThanks] = useState(false)

    const onSaveStatus = (value) => {
        setThanks(true)
        setTimeout(() => {
            saveStatus(value)
        }, 1500)
    }
    
    return (
        <>
            <Grid container direction="row" justify="center">
                <ReactStars
                    count={10}
                    onChange={onSaveStatus}
                    size={24}
                    activeColor="#ffd700"
                />
            </Grid>
            {thanks && <Typography variant="h5" align='center'>{t('thanks_for_review')}</Typography>}
        </>
    )
}

ReviewForm.defaultProps = {
    saveStatus: console.log
}

export default ReviewForm