import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import useDynamicDataApi from '../../hooks/Apis/useDynamicDataApi';
import { useComponentState, ApiError, Spinner } from '@collapick/utils';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../context/UserContext'

const useStyles = makeStyles((theme) => (
    {
        root: {
            marginTop: "5vh",
        },
        thumbnails: {
            marginTop: "5vh",
            marginLeft: "1.5vh",
            marginRight: "1.5vh",
            textAlign: "center",
            alignSelf: "center",
        },
        icon: {
            fontSize: "50px",
            color: theme.palette.error.main
        },
        iFrameContainer: {
            position: "relative",
            overflow: "hidden",
            width: "100%",
            paddingTop: "56.25%", /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
        },
        iFrame: {
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: "100%",
            height: "100%",
            //    maxWidth: "640px",
            //    maxHeight: "360px"
        }
    }))


const InfoVideo = forwardRef((props, ref) => {
    const { setOpenAddVideo, deleteVideoCallback } = props
    const classes = useStyles()
    const DynamicDataApi = useDynamicDataApi()
    const { componentState, setLoading, setError } = useComponentState()
    const [videoUrls, setVideoUrls] = useState([])
    const { t } = useTranslation()
    const userContext = useUserContext()

    useEffect(() => {
        getVideoUrls()
    }, [])

    const getVideoUrls = () => {
        setLoading(true)
        return DynamicDataApi.getVideoUrls()
            .then(res => {
                setVideoUrls(res)
            })
            .catch(setError)
            .finally(setLoading(false))
    }

    const addVideo = () => {
        setOpenAddVideo(true)
    }

    useImperativeHandle(ref, () => ({
        addVideo(videoUrl) {
            setLoading(true)
            return DynamicDataApi.addVideoUrl(videoUrl)
                .then(res => {
                    getVideoUrls()
                })
                .catch(setError)
                .finally(setLoading(false))
        },
        deleteVideo(videoUrl) {
            setLoading(true)
            return DynamicDataApi.deleteVideoUrl(videoUrl)
                .then(res => {
                    getVideoUrls()
                })
                .catch(setError)
                .finally(setLoading(false))
        },
    }))


    const thumbnails = videoUrls.map((url, i) => {
        let type = ""
        let id = ""
        if (url.includes('youtube')) {
            if (url.includes('feature')) {
                id = url.split(/=/)[1].split(/&/)[0]
                type = "youtube"
            } else {
                type = "youtube"
                id = url.split(/=/).pop()
            }


        } else if (url.includes('youtu.be')) {
            type = "youtu"
            id = url.split('/').pop()
        } else if (url.includes('vimeo')) {
            type = "vimeo"
            id = url.split('/').pop()
        }

        let width = "640"
        let height = "360"

        return (
            <>
                {type == 'youtube' &&
                    <Grid key={i} container direction="row" className={classes.thumbnails} alignItems="center" >
                        <Grid item xs={3} >
                            <Button onClick={() => deleteVideoCallback(url)}>
                                <DeleteForeverIcon className={classes.icon} />
                            </Button>
                        </Grid>
                        <Grid item xs={9} className={classes.iFrameContainer}>
                            <iframe
                                //      width={width}
                                //      height={height}
                                className={classes.iFrame}
                                src={`https://www.youtube.com/embed/${id}`}
                            ></iframe>
                        </Grid>
                    </Grid>
                }
                {type == 'youtu' &&
                    <Grid key={i} container direction="row" className={classes.thumbnails} alignItems="center" >
                        <Grid item xs={3}>
                            <Button onClick={() => deleteVideoCallback(url)}>
                                <DeleteForeverIcon className={classes.icon} />
                            </Button>
                        </Grid>
                        <Grid item xs={9} className={classes.iFrameContainer}>
                            <iframe
                                //      width={width}
                                //      height={height}
                                className={classes.iFrame}
                                src={`https://www.youtube.com/embed/${id}`}
                            >
                            </iframe>
                        </Grid>
                    </Grid>}
                {type == "vimeo" &&
                    <Grid key={i} container direction="row" className={classes.thumbnails} alignItems="center" >
                        <Grid item xs={3}>
                            <Button onClick={() => deleteVideoCallback(url)}>
                                <DeleteForeverIcon className={classes.icon} />
                            </Button>
                        </Grid>
                        <Grid item xs={9} className={classes.iFrameContainer}>
                            <iframe
                                //    width={width}
                                //    height={height}
                                src={`https://player.vimeo.com/video/${id}`}
                                className={classes.iFrame}
                            >
                            </iframe>
                        </Grid>
                    </Grid>}
            </>
        )
    })

    let result = null
    if (componentState.loading) {
        result = <Spinner enabled={componentState.loading} />
    } else if (componentState.error) {
        result = <ApiError error={componentState.error} />
    } else {
        result = (
            <>
                <Grid container className={classes.root} justify="center">
                    <Grid item xs={12} style={{ textAlign: "center", marginBottom: "5vh" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={addVideo}
                        >{t('add_link_to_video')}</Button>
                    </Grid>
                    {videoUrls.length > 0 ? thumbnails :
                        <Typography variant="h2">{t('there_are_no_videos_to_show')}</Typography>}
                </Grid>
            </>
        )
    }
    return result
})

InfoVideo.defaultProps = {
    videoIds: [],
    setOpenAddVideo: console.log
}

export default InfoVideo