import React, { createContext, useContext, useEffect, useState } from "react"
import pkg from "../../package.json"
import i18n from "../locales/i18n"
// Insert default values here if needed.
const AppContext = createContext()

/**
 * Provider for AppUser and loginInfo
 */
const AppProvider = (props) => {
    const { defaultLanguage = "fi" } = props

    // Provide custom initialization methods here.

    const [language, _setLanguage] = useState(null)

    /**
     * same hook changes translations automatically and saves to state
     * @param {*} lang
     */
    const setLanguage = (lang) => {
        if (language != lang) {
            i18n.changeLanguage(lang)
            _setLanguage(lang)
            //moment.locale(lang);
        }
    }

    // Helper for storybook.
    useEffect(() => {
        setLanguage(defaultLanguage)
    }, [])

    let values = {
        name: pkg.name,
        version: pkg.version,
        //environment: process?.env?.NODE_ENV ?? "production",
        language,
        setLanguage
    }
    return <AppContext.Provider value={values}>{props.children}</AppContext.Provider>
}

/**
 * ContextWrapper that warns if used outside Provider
 */
const useAppContext = (value) => {
    const context = useContext(AppContext)
    if (context === undefined) {
        throw new Error("useAppContext must be used within a AppProvider")
    } else {
        return context
    }
}

export { AppProvider, useAppContext }
